<div class="material-orders">
  <div class="loading-spinner" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <div class="container-table" [hidden]="loading">
    <div class="top-bar-table">
      <div class="search-bar">
        <div class="search-wrapper">
          <mat-form-field>
            <div class="custom-icon search">search icon</div>
            <input
              matInput
              id="orders-table-search"
              [(ngModel)]="query"
              (keyup)="triggerSearch()"
              placeholder="Search"
              autocomplete="off"
            />
          </mat-form-field>
          <div class="search-spinner">
            <mat-spinner *ngIf="searchLoading" [diameter]="20"></mat-spinner>
          </div>
          <div class="mobile-buttons">
            <csv-download [csvData]="getOrderCsvData()" [fileName]="csvDownloadFileName"></csv-download>
          </div>
        </div>
      </div>

      <div class="action-buttons">
            <span>Download</span>
        <csv-download [csvData]="getOrderCsvData()" [fileName]="csvDownloadFileName"></csv-download>
      </div>
    </div>

      <div class="filter-display">
        <ul class="filter-active">
          <li *ngFor="let i of filterService">
            <span>Service: {{ i }}</span>
            <mat-icon (click)="removeThis('service', i)">cancel</mat-icon>
          </li>
          <li *ngFor="let i of filterStatus">
            <span>Status: {{ i.name }}</span>
            <mat-icon (click)="removeThis('status', i)">cancel</mat-icon>
          </li>
          <li *ngFor="let i of filterAddress">
            <span>Address: {{ i }}</span>
            <mat-icon (click)="removeThis('address', i)">cancel</mat-icon>
          </li>
        </ul>
      </div>

    <table mat-table multiTemplateDataRows matSort [dataSource]="dataSource" class="mat-elevation-z0">
      <!--- Note that these columns can be defined in any order.
						The actual rendered columns are set as a property on the row definition" -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef># ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="deal">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Deal</th>
        <td mat-cell *matCellDef="let element">{{ element.deal }}</td>
      </ng-container>

      <ng-container matColumnDef="oli">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>OLI</th>
        <td mat-cell *matCellDef="let element">{{ element.oli }}</td>
      </ng-container>

      <ng-container matColumnDef="line_type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Type</th>
        <td mat-cell *matCellDef="let element">{{ element.line_type }}</td>
      </ng-container>

      <ng-container matColumnDef="nit">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>NIT</th>
        <td mat-cell *matCellDef="let element">{{ element.nit }}</td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Site</th>
        <td mat-cell *matCellDef="let element">{{ element.site }}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element">{{ element.address }}</td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>City</th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>State</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
        <td mat-cell *matCellDef="let element">{{ element.service }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="foc_date">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>FOC Date</th>
        <td mat-cell *matCellDef="let element">{{ element.foc_date }}</td>
      </ng-container>

      <ng-container matColumnDef="expands">
        <th mat-header-cell *matHeaderCellDef>
          <mat-icon *ngIf="!allExpanded" aria-label="Expand all" (click)="toggleExpandAll()"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="allExpanded" aria-label="Hide all" (click)="toggleExpandAll()">keyboard_arrow_up</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="!element.expanded" aria-label="Expand">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="element.expanded" aria-label="Expand">keyboard_arrow_up</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="orderColumns.length"
          [ngClass]="{ 'open-td': element.expanded }"
        >
          <div class="extended-row-detail" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
            <app-extended-row
              class="extended-component"
              [order]="element.full_order"
              (openOrder)="openOrder($event)"
            ></app-extended-row>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="orderColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: orderColumns"
        class="basic-row"
        (click)="row.expanded = !row.expanded"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="extended-row"></tr>
    </table>

    <mat-paginator
      #paginatorElement
      [pageSizeOptions]="pageSizeOptions"
      (page)="showingPage()"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
