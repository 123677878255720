import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import * as fromReducer from '../store/reducers';
import * as fromNodesReducer from '../store/reducers/nodes.reducer';
import * as fromUsersReducer from '../store/reducers/users.reducer';

import { WidgetDirective } from './widget.directive';
import { SharedModule } from '../shared/shared.module';
import { OrdersParserService } from '../services/orders-parser.service';
import { SummaryParserService } from './summary-parser.service';

import { MatGridListModule } from '@angular/material/grid-list';

import { SummaryComponent } from './summary-page/summary-page.component';
import { BulletStatusComponent } from './bullet-status/bullet-status.component';
import { ServiceOverviewComponent } from './service-overview/service-overview.component';
import { CircuitMilestonesComponent } from './circuit-milestones/circuit-milestones.component';
import { PendingFocDatesComponent } from './pending-foc-dates/pending-foc-dates.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    NgChartsModule,
    MatGridListModule,
    StoreModule.forFeature('billing', fromReducer.billingReducers),
    StoreModule.forFeature('nodes', fromNodesReducer.reducer),
    StoreModule.forFeature('user', fromUsersReducer.reducer),
    StoreModule.forFeature('tickets', fromUsersReducer.reducer)
  ],
  declarations: [
    SummaryComponent,
    WidgetDirective,
    BulletStatusComponent,
    ServiceOverviewComponent,
    CircuitMilestonesComponent,
    PendingFocDatesComponent
  ],
  providers: [DecimalPipe, SummaryParserService, OrdersParserService]
})
export class SummaryModule {}
