import { Order, OrderType } from './order';
import OrderInfoDetail from '../order-info.interface';

export class OrderInternet extends Order {

	constructor()	{
		super();
		this.type = OrderType.INTERNET;
	}

	public getRowInfo(): OrderInfoDetail[] {
		return [
			{
				title: 'Vendor/Access Provider',
				value: this.vendor_name,
			},
			{
				title: 'Bandwidth',
				value: this.circuit_bandwith,
			},
			{
				title: 'FOC Date',
				value: this.formatDate(this.foc_date),
			},
			{
				title: 'Equipment Tracking Number',
				value: this.tracking_number,
			},
			{
				title: 'Last Mile Provider Circuit ID',
				value: this.last_mile_circuit_id,
			},
			{
				title: 'Order Date',
				value: this.formatDate(this.order_date),
			},
			{
				title: 'Start of Service Date',
				value: this.formatDate(this.start_service),
			},
		];
	}

}
