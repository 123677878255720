<section>
  <div class="widget-header">
    <div class="user-info">
      <h1>
        Welcome
        <ng-container *ngIf="user$ | async as user"> {{ user.FirstName }} {{ user.LastName }} </ng-container>
      </h1>

      <ng-container *ngIf="user$ | async as user">
        <div class="contact-title" *ngIf="user.Title">{{ user.Title }}</div>
        <div class="contact-info" *ngIf="user.Phone || user.Email">
          <ng-container *ngIf="user.Phone"> {{ user.Phone | phoneNumber: 'US' }} | </ng-container>
          {{ user.Email }}
        </div>
      </ng-container>
    </div>

    <div class="noc-info">
      <div class="noc-tag">For Nitel 24/7 Support</div>
      <a class="open-ticket-link" href="/tickets">Open a ticket</a>
      <span class="noc-tag"> or </span>
      <a class="contact-us-link" href="https://www.nitelusa.com/support/" target="_blank">Contact us</a>
    </div>
  </div>

  <div class="loading-indicator" *ngIf="isLoading$ | async">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div class="summary-content" *ngIf="!(isLoading$ | async) && user$ | async as user">
    <div class="billing-address">
      <div class="company-title">{{ user.Account.Name }}</div>
      <div class="address-label">Billing Address</div>
      <div class="address-wrapper">
        <div class="custom-icon location_dark">location icon</div>
        {{ user.Account.BillingAddress.street }}<br />
        {{ user.Account.BillingAddress.city }}, {{ user.Account.BillingAddress.stateCode }}
        {{ user.Account.BillingAddress.postalCode }}
      </div>
    </div>

    <div class="account-manager" *ngIf="user$ | async as user">
      <ng-container *ngIf="user.Account?.Account_Manager__r">
        <div class="manager-title">Account Manager</div>
        <div class="manager-wrapper">
          <div class="manager-name">{{ user.Account.Account_Manager__r.Name }}</div>
          <div class="manager-email" *ngIf="user.Account.Account_Manager__r.Email">
            <div class="custom-icon email_dark">email icon</div>
            <a href="mailto:{{ user.Account.Account_Manager__r.Email }}">{{ user.Account.Account_Manager__r.Email }}</a>
          </div>
          <div class="manager-phone" *ngIf="user.Account.Account_Manager__r.Phone">
            <div class="custom-icon phone_dark">phone icon</div>
            <a href="tel:{{ user.Account.Account_Manager__r.Phone }}">{{ user.Account.Account_Manager__r.Phone }}</a>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="error-msg" *ngIf="errorMessage && !(user$ | async)">{{ errorMessage }}</div>
  </div>
</section>
