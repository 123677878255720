<div class="order-container" [class.selected]="isSelected">
  <div class="order-title">
    <h5>{{ order.Name || '' }} ({{ order.Status__c === 'Ready' ? 'Pending' : order.Status__c }})</h5>
    <div class="pending-warning" *ngIf="order.Status__c === 'Pending' || order.Status__c === 'Ready'">
      The order is still in initial processing, not all services may be displayed
    </div>
  </div>
  <div class="order-date">
    Order Date: <span>{{ order.Order_Date__c | date: 'MM/dd/yy' || '---' }}</span>
  </div>

  <a routerLink="{{ order.Id }}" tabindex="-1" class="order-link-outer mobile">
    <div class="order-link">View Order</div>
  </a>
</div>
