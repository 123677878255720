import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Order } from 'src/app/models/order.model';

import * as fromStore from 'src/app/store/';

@Component({
  selector: 'app-widget-orders',
  templateUrl: './widget-orders.component.html',
  styleUrls: ['../widgets.scss', './widget-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetOrdersComponent implements OnInit {
  totalOpenOrders$: Observable<Order[]>;
  isLoading$: Observable<boolean>;
  totalActiveLocations$: Observable<number>;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.isLoading$ =  this.store.select(fromStore.getOrdersLoading);
    this.store.dispatch(new fromStore.LoadOrders());
    this.totalOpenOrders$ = this.store.select(fromStore.getActiveOrders);
    this.totalActiveLocations$ = this.store.select(fromStore.getTotalActiveLocations);
  }
}
