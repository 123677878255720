/* eslint-disable no-shadow */
import { createFeatureSelector } from '@ngrx/store';
import { Node } from 'src/app/models/node.model';
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for Nodes
export interface NodeState {
  entities: { [id: string]: Node };
  loaded: boolean;
  loading: boolean;
  error: string;
}

export const initialState: NodeState = {
  entities: {},
  loaded: false,
  loading: false,
  error: null
};

// Extends the app state to include the product feature.
// This is required because products are lazy loaded.
// So the reference to ProductState cannot be added to app.state.ts directly.
export interface State extends fromRoot.State {
  nodes: NodeState;
}

// Selector functions
export const getFeatureNodeState = createFeatureSelector<NodeState>('nodes');

export function reducer(state = initialState, action: fromActions.NodesAction): NodeState {
  switch (action.type) {
    case fromActions.LOAD_DOWN_NODES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_DOWN_NODES_SUCCESS: {
      const nodes = action.payload;
      let entities = {};

      if (nodes !== null) {
        entities = nodes.reduce(
          (entities: { [id: string]: Node }, node) => {
            return {
              ...entities,
              [node.NodeID]: node
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_DOWN_NODES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'Unable to connect to Advanced Monitoring.'
      };
    }
    default: {
      return state;
    }
  }
}

export const getDownNodesEntities = (state: NodeState) => state.entities;
export const getDownNodesLoading = (state: NodeState) => state.loading;
export const getDownNodesLoaded = (state: NodeState) => state.loaded;
export const getDownNodesError = (state: NodeState) => state.error;
