import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { LineItemService } from '../../services/line-item.service';
import * as lineItemActions from '../actions/line-items.action';

@Injectable()
export class LineItemEffects {
  constructor(private actions$: Actions, private lineItemService: LineItemService) {}

  loadDisconnectReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(lineItemActions.LOAD_DISCONNECT_REASONS),
      switchMap(() => {
        return this.lineItemService.getDisconnectReasons().pipe(
          map((reasons) => new lineItemActions.LoadDisconnectReasonsSuccess(reasons)),
          catchError((error) => of(new lineItemActions.LoadDisconnectReasonsFail(error)))
        );
      })
    );
  });
}
