import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'order-list-display',
  templateUrl: './order-list-display.component.html',
  styleUrls: ['./order-list-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderListDisplayComponent {
  @Input() order: Order;
  @Input() isSelected: boolean;
}
