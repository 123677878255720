<section class="ticket-list">
  <div #headerContainer class="header-wrapper">
    <div class="widget-header">
      <div class="icon-text">
        <div class="custom-icon ticket">tickets list</div>
        <h1>Tickets ({{ tickets.length || 0 }})</h1>
      </div>
      <div class="action-items">
        <div class="csv-export">
          <span>Download</span>
          <csv-download [csvData]="csvData" [fileName]="'Tickets.csv'"></csv-download>
        </div>
        <button class="button" *ngxPermissionsOnly="'Ticketing: Write'" (click)="openDialog()">
          <span>Create Ticket</span>
        </button>
      </div>
    </div>
    <div class="widget-header search-wrapper" *ngIf="tickets.length > 0">
        <mat-form-field>
          <div class="custom-icon search">search icon</div>
          <input matInput [formControl]="searchTermControl" placeholder="Search By Case Number" autocomplete="off" />
        </mat-form-field>
    </div>
    <div class="group-wrapper" *ngIf="tickets.length > 0">
      <div class="group-label">
        <div class="custom-icon sort_arrows">sort icon</div>
        <span>Group By:</span>
      </div>
      <div class="group-options">
        <a
        (click)="onClickGroupBy('Location')"
        class="group-option"
        [ngClass]="{ 'active-link': currentGrouping === 2 }"
      >
        <span>
          Location
          <div class="custom-icon" [ngClass]="{ sort_asc: isOrderingAsc, sort_desc: !isOrderingAsc }">sort icon</div>
        </span>
      </a>
        <a (click)="onClickGroupBy('Status')" class="group-option" [ngClass]="{ 'active-link': currentGrouping === 1 }">
          <span>
            Status
            <div class="custom-icon" [ngClass]="{ sort_asc: isOrderingAsc, sort_desc: !isOrderingAsc }">sort icon</div>
          </span>
        </a>
        <a (click)="onClickGroupBy('Type')" class="group-option" [ngClass]="{ 'active-link': currentGrouping === 0 }">
          <span>
            Type
            <div class="custom-icon" [ngClass]="{ sort_asc: isOrderingAsc, sort_desc: !isOrderingAsc }">sort icon</div>
          </span>
        </a>
      </div>
    </div>
  </div>

  <div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <ng-container *ngIf="groupedTickets">
    <div class="tickets-empty" *ngIf="groupedTickets.length === 0 && !isLoading && tickets.length > 0">
      There are no tickets based on your search
    </div>
    <div class="tickets-empty" *ngIf="groupedTickets.length === 0 && !isLoading && tickets.length == 0">
      There are currently no tickets yet.
    </div>
  </ng-container>

  <div class="ticket-viewport">
    <mat-accordion *ngIf="groupedTickets.length > 0">
      <mat-expansion-panel
        (afterExpand)="onExpanded()"
        *ngFor="let ticketGroup of groupedTickets | filter: searchTerm; first as isFirst"
        [expanded]="isFirst"
      >
        <mat-expansion-panel-header (click)="onHeaderClick($event)">
          <mat-panel-title>
            <div class="group-title">
              {{ ticketGroup.key || 'N/A' | uppercase}}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <span class="total-tickets">Total Number Tickets: {{ ticketGroup.tickets.length }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row-scroll">
          <div
            *ngFor="let ticket of ticketGroup.tickets | filter: searchTerm"
            tabindex="0"
            class="ticket-row"
            [class.selected]="ticket === selectedTicket"
          >
            <div class="ticket-inner">
              <div class="ticket-left-section">
                <span class="case-number">{{ ticket.CaseNumber }}</span>
              </div>
              <div class="ticket-mid-section">
                <div class="description">{{ ticket.Subject }}</div>
                <div *ngIf="currentGrouping !== 2" class="location">
                  <div class="custom-icon location_dark">location icon</div>
                  {{ ticket.Service_Location_Address__c | uppercase }}
                </div>
                <div *ngIf="currentGrouping == 2" class="subject">
                  {{ ticket.Subject || ticket?.Portal_Ticket_Type__c || '---' }}
                </div>
              </div>
              <div class="ticket-right-section">
                <div class="ticket-date">
                  <div class="status" [ngClass]="{ open: !ticket.IsClosed, closed: ticket.IsClosed }">
                    {{ ticket.Status | uppercase }}
                  </div>
                  <div class="date" *ngIf="!ticket.IsClosed">
                    opened {{ ticket.CreatedDate | date: 'M.d.yy' || '---' }}
                  </div>
                  <div class="date" *ngIf="ticket.IsClosed">
                    closed {{ ticket.ClosedDate | date: 'M.d.yy' || '---' }}
                  </div>
                </div>
                <div class="ticket-comments">
                  <div class="view-more">
                    <div class="custom-icon arrow_progress_inactive"></div>
                    <div class="custom-icon arrow_progress"></div>
                  </div>
                  <span class="icon-text">
                    <span class="custom-icon comment_read"></span> &nbsp;{{ ticket.Comments__r?.totalSize || 0 }}
                  </span>
                </div>
              </div>
              <a routerLink="{{ ticket.Id }}" tabindex="-1" class="ticket-link-outer"
                ><div class="ticket-link mobile">mobile</div></a
              >
              <a (click)="onSelect(ticket)" tabindex="-1"><div class="ticket-link desktop">desktop</div></a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
