/* eslint-disable no-shadow */
import { createFeatureSelector } from '@ngrx/store';
import { LineItem } from '../../models/line-item.model';
import { excludedDisconnectReasons } from '../../config/excludedDisconnectReasons'
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for this feature (LineItems)
export interface LineItemState {
  entities: { [id: string]: LineItem };
  disconnectReasons: string[];
  loaded: boolean;
  loading: boolean;
  error: string;
}

export const initialState: LineItemState = {
  entities: {},
  disconnectReasons: [],
  loaded: false,
  loading: false,
  error: null
};

// Extends the app state to include the product feature.
// This is required because products are lazy loaded.
// So the reference to ProductState cannot be added to app.state.ts directly.
export interface State extends fromRoot.State {
  lineItems: LineItemState;
}

// Selector functions
export const getFeatureLineItemState = createFeatureSelector<LineItemState>('lineItems');

export function reducer(state = initialState, action: fromActions.LineItemsAction): LineItemState {
  switch (action.type) {
    case fromActions.LOAD_DISCONNECT_REASONS: {
      return {
        ...state
      };
    }

    case fromActions.LOAD_DISCONNECT_REASONS_SUCCESS: {
      const reasons = action.payload;

      return {
        ...state,
        disconnectReasons: reasons
      };
    }

    case fromActions.LOAD_DISCONNECT_REASONS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLineItemsEntities = (state: LineItemState) => state.entities;
export const getLineItemsLoading = (state: LineItemState) => state.loading;
export const getLineItemsLoaded = (state: LineItemState) => state.loaded;
export const getDisconnectReasons = (state: LineItemState) => state.disconnectReasons.filter((item) => !excludedDisconnectReasons.includes(item.toLowerCase()));
