import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NonceService {
  private nonce: string;

  constructor(private http: HttpClient) {}

  async getNonce(): Promise<string> {
    try {
      if (this.nonce) {
        return Promise.resolve(this.nonce);
      }
      const response = await this.http
        .get<{ nonce: string }>(`${environment.apiUrl}/nonce`)
        .toPromise();
      this.nonce = response.nonce;
    } catch (err) {
      console.error(err);
    }

    return this.nonce;
  }

  getCachedNonce(): string {
    return this.nonce;
  }
}
