<ng-container *ngIf="selectedService">
  <section class="service-detail service-page">
    <div class="content">
      <div class="back-heading" *ngIf="browserSize === 'small'">
        <a class="back-link icon-text" [routerLink]="['/services']">
          <div class="custom-icon arrow_back"></div>
          Back to Services
        </a>
      </div>
      <div class="service-detail-content">
        <div class="service-detail-header">
          <div class="service-title">
            <h2>
              <div class="custom-icon services_icon">service icon</div>
              Service Details
            </h2>
          </div>
          <div class="service-status">
            <div class="status-wrapper">
              Status:
              <div class="status-container">
                <span
                  [ngClass]="{
                    open: selectedService.Status__c == 'In Service',
                    pending:
                      selectedService.Status__c == 'New' ||
                      selectedService.Status__c == 'Pending',
                    closed:
                      selectedService.Status__c == 'Canceled' ||
                      selectedService.Status__c == 'Disconnected'
                  }"
                >
                  {{
                    selectedService.Status__c === "New"
                      ? "Pending"
                      : selectedService.Status__c
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="service-detail-header">
          <div class="location-address">
            <div class="custom-icon location_dark">location icon</div>
            <span *ngIf="selectedService.CustomerLocationAlias">{{
              selectedService.CustomerLocationAlias
            }}</span>
            {{ selectedService.Z_Location__r.Name }}
            <a
              (click)="onEditLocationName(selectedService)"
              *ngIf="
                selectedService.Status__c === 'In Service' &&
                selectedService.BanLookupId
              "
              class="edit-location-link"
              ><div class="custom-icon edit">edit</div></a
            >
          </div>
        </div>
      </div>

      <div class="service-details">
        <div class="detail-wrapper">
          <div class="custom-icon circuit_dark">circuit icon</div>
          SERVICE: <span>{{ selectedService.Name | uppercase }}</span>
        </div>
        <div class="type-details">
          <div class="type">
            <span>Type:</span> {{ selectedService.RecordType.Name }}
          </div>
          <div
            class="bandwidth"
            *ngIf="selectedService.Bandwidth_Downstream__c"
          >
            <span>Bandwidth: </span>
            {{ selectedService.Bandwidth_Downstream__c }}
            <ng-container *ngIf="selectedService.Asymmetrical_Bandwidth__c">
              / {{ selectedService.Bandwidth_Upstream__c }}
            </ng-container>
          </div>
          <div class="vendor" *ngIf="selectedService.Vendor__r">
            <span>Vendor:</span>
            {{
              selectedService.Vendor__r.Display_Alias__c
                ? selectedService.Vendor__r.Display_Alias__c
                : selectedService.Vendor__r.Name
            }}
          </div>
          <div
            class="connection-type"
            *ngIf="selectedService.Connector_Type_Z_Location__c"
          >
            <span>Connector Type: </span>
            {{ selectedService.Connector_Type_Z_Location__c }}
          </div>
        </div>
        <div class="date-details">
          <div class="term" *ngIf="selectedService.Term__c">
            <span>Term: </span> {{ selectedService.Term__c }} months
          </div>
          <div class="start">
            <span>Start:</span>
            {{ selectedService.Start_Of_Service__c | date : "M/d/yy" }}
          </div>
          <div class="end">
            <span>End:</span>
            {{ selectedService.Current_Term_End_Date__c | date : "M/d/yy" }}
          </div>
        </div>
      </div>

      <div
        class="billing-component-wrapper"
        *ngIf="selectedService.Components__r"
      >
        <app-service-billing
          [components]="selectedService.Components__r.records"
        ></app-service-billing>
      </div>

      <div class="billing-total">
        Monthly Charges:
        <span>{{
          selectedService.MRR__c
            ? (selectedService.MRR__c | currency)
            : (0.0 | currency)
        }}</span>
      </div>

      <ng-template [ngIf]="selectedService.Status__c === 'In Service'">
        <div class="button-wrapper" *ngxPermissionsOnly="'Ticketing: Write'">
          <button class="button" (click)="onCreateTicket()">
            <span>Create Ticket</span>
          </button>
        </div>
      </ng-template>
    </div>

    <app-footer></app-footer>
  </section>
</ng-container>
