<div class="chart-container">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [responsive]="chartOptions.responsive"
    [title]="chartOptions.title"
    [legend]="chartOptions.legend"
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
