/* eslint-disable no-shadow */
import { createFeatureSelector } from '@ngrx/store';
import { Service } from 'src/app/models/service.model';
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for Service
export interface ServiceState {
  entities: { [id: string]: Service };
  loaded: boolean;
  loading: boolean;
  selectedServiceId: string;
  totalOpenServices: number;
}

export const initialState: ServiceState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedServiceId: null,
  totalOpenServices: 0
};

export interface State extends fromRoot.State {
  services: ServiceState;
}

// Selector functions
export const getServiceFeatureState = createFeatureSelector<ServiceState>('services');

export function reducer(state = initialState, action: fromActions.ServicesAction): ServiceState {
  switch (action.type) {
    case fromActions.LOAD_SERVICES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_SERVICES_SUCCESS: {
      const services = action.payload;

      let entities = {};

      if (services !== null) {
        entities = services.reduce(
          (entities: { [id: string]: Service }, service) => {
            return {
              ...entities,
              [service.Id]: service
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_SERVICES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    // Update Ban Lookup data in Services
    case fromActions.UPDATE_SERVICES_BANLOOKUP: {
      const services = action.payload;
      let entities = {};

      if (services !== null) {
        entities = services.reduce(
          (entities: { [id: string]: Service }, service) => {
            return {
              ...entities,
              [service.Id]: service
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        entities
      };
    }

    case fromActions.SET_SELECTED_SERVICE: {
      const serviceId = action.payload;

      return {
        ...state,
        selectedServiceId: serviceId
      };
    }

    default: {
      return state;
    }
  }
}

export const getServicesEntities = (state: ServiceState) => state.entities;
export const getServicesLoading = (state: ServiceState) => state.loading;
export const getServicesLoaded = (state: ServiceState) => state.loaded;
export const getServiceSelected = (state: ServiceState) => state.selectedServiceId;
