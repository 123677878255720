/* eslint-disable no-shadow */
import { Order } from 'src/app/models/order.model';
import * as fromOrders from '../actions';

// State for this feature (Orders)
export interface OrderState {
  entities: { [id: string]: Order };
  currentOrderId: string;
  currentLineItemId: string;
  detailView: string;
  loaded: boolean;
  loading: boolean;
  error: string;
  totalOpenOrders: number;
}

export const initialState: OrderState = {
  entities: {},
  currentOrderId: null,
  totalOpenOrders: 0,
  currentLineItemId: null,
  detailView: null,
  loaded: false,
  loading: false,
  error: null
};

export function reducer(state = initialState, action: fromOrders.OrdersAction): OrderState {
  switch (action.type) {
    case fromOrders.LOAD_ORDERS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrders.LOAD_ORDERS_SUCCESS: {
      const orders = action.payload;
      let entities = {};
      if (orders !== null) {
        entities = orders.reduce(
          (entities: { [id: string]: Order }, order) => {
            return {
              ...entities,
              [order.Id]: order
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromOrders.LOAD_ORDERS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromOrders.LOAD_ORDERSV2: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrders.LOAD_ORDERSV2_SUCCESS: {
      const ordersV2 = action.payload;
      let entities = {};
      if (ordersV2 !== null) {
        entities = ordersV2.reduce(
          (entities: { [id: string]: any }, order) => {
            return {
              ...entities,
              [order.Id]: order
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }


    case fromOrders.CLEAR_ORDERS: {
      return {
        ...state,
        entities: {}
      };
    }

    case fromOrders.DISPLAY_ORDER_DETAIL: {
      const selectedDetailView = action.payload;

      return {
        ...state,
        detailView: selectedDetailView.detailView,
        currentOrderId: selectedDetailView.orderId,
        currentLineItemId: selectedDetailView.lineItemId
      };
    }

    case fromOrders.SET_CURRENT_ORDER: {
      const selectedOrder = action.payload;

      return {
        ...state,
        currentOrderId: selectedOrder
      };
    }

    case fromOrders.CLEAR_CURRENT_ORDER: {
      return {
        ...state,
        currentOrderId: null
      };
    }

    case fromOrders.SET_CURRENT_LINE_ITEM: {
      const selectedLineItem = action.payload;

      return {
        ...state,
        currentLineItemId: selectedLineItem
      };
    }

    case fromOrders.LOAD_TOTAL_OPEN_ORDERS_SUCCESS: {
      const total = action.payload;

      return {
        ...state,
        totalOpenOrders: total
      };
    }

    case fromOrders.CLEAR_CURRENT_LINE_ITEM: {
      return {
        ...state,
        currentLineItemId: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getOrdersEntities = (state: OrderState) => state.entities;
export const getOrdersLoading = (state: OrderState) => state.loading;
export const getOrdersLoaded = (state: OrderState) => state.loaded;
export const getOrderDetailView = (state: OrderState) => state.detailView;
export const getOrderSelected = (state: OrderState) => state.currentOrderId;
export const getOrderLineItemSelected = (state: OrderState) => state.currentLineItemId;
export const getTotalOpenOrders = (state: OrderState) => state.totalOpenOrders;
