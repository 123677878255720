<div class="radio-container" *ngIf="formRef" [formGroup]="formRef">
  <h4>Preferred method of contact:</h4>
  <mat-radio-group
    class="default-contact-options"
    formControlName="preferredContactMethod"
    aria-label="Select an option"
    (change)="onRadioChange()"
  >
    <mat-radio-button class="contact-radio" value="email" checked *ngIf="reporter.Email">
      <div class="custom-icon email"></div>
      <div class="custom-icon email_dark"></div>
      {{ reporter.Email }}
    </mat-radio-button>
    <mat-radio-button class="contact-radio" value="phone" *ngIf="reporter.Phone">
      <div class="custom-icon phone"></div>
      <div class="custom-icon phone_dark"></div>
      {{ reporter.Phone | phoneNumber: 'US' }}
    </mat-radio-button>
    <mat-radio-button  #alternatePhoneRadioButton class="contact-radio" value="Alternate Phone" *ngIf="reporter.Phone && altContact">
      <div class="custom-icon phone"></div>
      <div class="custom-icon phone_dark"></div>
      <mat-form-field class="country-select">
        <mat-label>Country</mat-label>
        <mat-select formControlName="countryCode" id="countryCode">
          <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.iso2">
            {{ countryCode.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="alt-phone" (click)="selectAlternatePhoneRadioButton()">
        <mat-label>Alternate Phone</mat-label>
        <input
          matInput
          type="tel"
          placeholder="Alt. Phone"
          formControlName="alternatePhone"
          id="alternatePhone"
          (keypress)="altPhoneKeypress($event)"
        />
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>

  <div class="form-error" *ngIf="formRef.errors?.['isValidPhoneNumber']">
    <div class="custom-icon inline_error"></div>
    <div class="error-message">Invalid alternate phone number.</div>
  </div>
</div>
