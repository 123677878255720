import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';

import * as fromStore from 'src/app/store/';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import { Payment } from 'src/app/models/payment.model';
import { AttachmentService, FileAPI } from 'src/app/services/attachment.service';

@Component({
  selector: 'app-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.scss', '../../../../styles/parent-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingPageComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string;

  subscriptions: Subscription[];
  selectedInvoiceSubscription: Subscription;
  fileSubscription: Subscription;

  payments$: Observable<Payment[]> = this.store.select(fromStore.getAllPayments);
  isPaymentsLoading$: Observable<boolean> = this.store.select(fromStore.getPaymentsLoading);
  isPaymentsLoaded$: Observable<boolean> = this.store.select(fromStore.getPaymentsLoaded);

  invoices$: Observable<ContentDocumentLink[]> = this.store.select(fromStore.getAllInvoiceAttachments);
  isInvoicesLoading$: Observable<boolean> = this.store.select(fromStore.getInvoiceAttachmentsLoading);
  isInvoicesLoaded$: Observable<boolean> = this.store.select(fromStore.getInvoiceAttachmentsLoaded);
  selectedInvoice$: Observable<ContentDocumentLink> = this.store.select(fromStore.getSelectedInvoiceAttachment);

  constructor(private store: Store<fromStore.State>, protected attachmentService: AttachmentService) {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });
  }

  ngOnInit() {
    this.invoices$.subscribe((data) => {
      if (data.length > 0) {
        this.onSelectInvoice(data[0]);
      }
    });
  }

  ngOnDestroy() {
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  onSelectInvoice(invoice: ContentDocumentLink) {
    this.store.dispatch(new fromStore.SetSelectedInvoiceAttachment(invoice));
  }

  getFileAPIData(file: ContentDocumentLink): Observable<FileAPI> {
    const fileAPIData$: Observable<FileAPI> = this.attachmentService.getFile(file);
    return fileAPIData$;
  }

  downloadFile(file: ContentDocumentLink) {
    let fileData: FileAPI = null;
    const fileAPIData$ = this.getFileAPIData(file);
    this.fileSubscription = fileAPIData$.subscribe((data) => {
      fileData = data;
      const newBlob = new Blob([fileData.binaryData], { type: `application/${file.ContentDocument.FileExtension}` });
      return FileSaver.saveAs(newBlob, `${file.ContentDocument.Title}.${file.ContentDocument.FileExtension}`);
    });
  }

  changePaymentFilterSelection(size: any) {
    this.store.dispatch(new fromStore.SetPaymentsFilterNumber(size));
  }

  changeInvoiceFilterSelection(size: any) {
    this.store.dispatch(new fromStore.SetInvoiceFilterNumber(size));
  }
}
