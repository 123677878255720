import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from 'src/app/models/api-url.model';
import { ApiService } from 'src/app/services/api.service';
import { Service } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  constructor(private apiService: ApiService) {}

  getServices(): Observable<Service[]> {
    return this.apiService.get(api.servicesURL);
  }

  getServicesInService(): Observable<any> {
    return this.apiService.get(`${api.servicesURL}/status/inservice`);
  }
}
