/* eslint-disable no-shadow */
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import * as fromSOF from '../actions';

// State for Service Order Forms
export interface SOFState {
  entities: { [id: string]: ContentDocumentLink };
  loaded: boolean;
  loading: boolean;
  selectedForm: ContentDocumentLink;
}

export const initialState: SOFState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedForm: null
};

export function reducer(state = initialState, action: fromSOF.ServiceOrderFormsAction): SOFState {
  switch (action.type) {
    case fromSOF.LOAD_SERVICE_ORDER_FORMS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromSOF.LOAD_SERVICE_ORDER_FORMS_SUCCESS: {
      const forms = action.payload;
      let entities = {};

      if (forms !== null) {
        entities = forms.reduce(
          (entities: { [id: string]: ContentDocumentLink }, form) => {
            return {
              ...entities,
              [form.LinkedEntityId]: form
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromSOF.LOAD_SERVICE_ORDER_FORMS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromSOF.SET_SELECTED_SOF: {
      const linkedEntityId = action.payload;
      const selectedFile = { ...state.entities[linkedEntityId] };

      return {
        ...state,
        selectedForm: selectedFile
      };
    }

    default: {
      return state;
    }
  }
}

export const getSOFEntities = (state: SOFState) => state.entities;
export const getSOFLoading = (state: SOFState) => state.loading;
export const getSOFLoaded = (state: SOFState) => state.loaded;
export const getSOFSelected = (state: SOFState) => state.selectedForm;
