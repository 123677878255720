class CountryCode {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: string[];

  constructor(data: any[]) {
    this.name = data[0];
    this.iso2 = data[1];
    this.dialCode = data[2];
    this.priority = data[3] || 0;
    this.areaCodes = data[4] || null;
  }
}

export { CountryCode };
