import { Component, Input, SimpleChanges } from '@angular/core';
import { Ticket } from 'src/app/models/ticket.model';
import {
OnInit, ChangeDetectorRef
} from '@angular/core';
import { api } from 'src/app/models/api-url.model';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'aggregate-ticket-detail',
  templateUrl: './aggregate-ticket-detail.component.html',
  styleUrls: ['./aggregate-ticket-detail.component.scss']
})
export class AggregateTicketDetailComponent implements OnInit {
  @Input() ticket: Ticket;

  isLoading: boolean = false;
  isDataLoaded: boolean = false;
  summary: string | null = null;
  
  private subscription: Subscription;
  constructor(private apiService: ApiService, private cdr: ChangeDetectorRef) { }

  ngOnInit(){
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticket && changes.ticket.currentValue) {
      this.isDataLoaded = false;
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  loadData() {
    this.isLoading = true;

    const params = { "params": {"caseNumber": this.ticket.CaseNumber} }; 
    this.apiService.get(api.aggregatesURL, params).subscribe({
      next: (data) => {
        this.isDataLoaded = true;
        this.isLoading = false;
        if (data.length > 0 && data[0].summary) {
          this.summary = data[0].summary;
        } else {
          this.summary = null;
        }
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.isDataLoaded = true;
        this.isLoading = false;
        this.summary = null;
        console.error('Error fetching data:', error);
        this.cdr.detectChanges();
      }
    });
  }
}
