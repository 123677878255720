import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { Payment } from 'src/app/models/payment.model';

@Component({
  selector: 'app-widget-billing',
  templateUrl: './widget-billing.component.html',
  styleUrls: ['../widgets.scss', './widget-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetBillingComponent implements OnInit {
  invoiceDate$: Observable<Date>;
  payment$: Observable<Payment>;
  isLoadingInvoices$: Observable<boolean>;
  isLoadingPayments$: Observable<boolean>;

  constructor(private store: Store<fromStore.State>, private decimalPipe: DecimalPipe) {
  
  }
  ngOnInit() {
    this.store.dispatch(new fromStore.LoadPayments());
    this.store.dispatch(new fromStore.LoadInvoices());
    this.isLoadingInvoices$ =  this.store.select(fromStore.getInvoicesLoading);
    this.isLoadingPayments$ =  this.store.select(fromStore.getPaymentsLoading);
    this.store.select(fromStore.getAllInvoices);
    this.store.select(fromStore.getAllPayments);
    this.invoiceDate$ = this.store.select(fromStore.getLatestInvoiceDate);
    this.payment$ = this.store.select(fromStore.getLatestPayment);
  }

  getPaymentDollars(payment: Payment): string {
    const amount = this.decimalPipe.transform(payment.Transaction_Amount__c, '1.2-2');
    return `${amount.substring(0, amount.length - 3)}.`;
  }

  getPaymentCents(payment: Payment): string {
    const amount = this.decimalPipe.transform(payment.Transaction_Amount__c, '1.2-2');
    return amount.substring(amount.length - 2);
  }
}
