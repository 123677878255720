import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
	selector: 'app-bullet-status',
	templateUrl: './bullet-status.component.html',
	styleUrls: ['../widgets.scss', './bullet-status.component.scss']
})
export class BulletStatusComponent {
	@Input() title: string;
	@Input() value: number;
	@Input() loading: boolean;
	@Input() bulletClass: string;
	@Output() action: EventEmitter<any> = new EventEmitter();

	public clickBox(): void {
		this.action.emit();
	}
}
