import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';

@Injectable()
export class BrowserService {
  private DEVICE_SMALL = 'small';
  private DEVICE_MEDIUM = 'medium';
  private DEVICE_LARGE = 'large';

  sizeName: string = null;
  width: number;
  height: number;

  constructor(private store: Store<fromStore.State>) {}

  public checkWidth() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.sizeName = this.DEVICE_LARGE;
    if (this.width <= 766) {
      this.sizeName = this.DEVICE_SMALL;
    } else if (this.width <= 1024) {
      this.sizeName = this.DEVICE_MEDIUM;
    }

    this.store.dispatch(new fromStore.UpdateBrowserSizeName(this.sizeName));
    // this.store.dispatch(new fromStore.UpdateBrowserWidth(this.width));
    // this.store.dispatch(new fromStore.UpdateBrowserHeight(this.height));
  }
}
