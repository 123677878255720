import { Action } from '@ngrx/store';
import { Contact } from 'src/app/models/contact.model';

// Load user
export const LOAD_CURRENT_USER = '[User] Load Current User';
export const LOAD_CURRENT_USER_FAIL = '[User] Load Current User Fail';
export const LOAD_CURRENT_USER_SUCCESS = '[User] Load Current User Success';
export const SET_CURRENT_USER = '[User] Set Current User';

export class LoadCurrentUser implements Action {
  readonly type = LOAD_CURRENT_USER;
}

export class LoadCurrentUserFail implements Action {
  readonly type = LOAD_CURRENT_USER_FAIL;
  constructor(public payload: any) {}
}

export class LoadCurrentUserSuccess implements Action {
  readonly type = LOAD_CURRENT_USER_SUCCESS;
  constructor(public payload: Contact) {}
}

export class SetCurrentUser implements Action {
  readonly type = SET_CURRENT_USER;
  constructor(public payload: Contact) {}
}

// action types
export type UsersAction = LoadCurrentUser | LoadCurrentUserFail | LoadCurrentUserSuccess | SetCurrentUser;
