import { Component, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { ContentDocumentLink } from 'src/app/models/content-document-link.model';

@Component({
  selector: 'app-ticket-billing',
  templateUrl: './ticket-billing.component.html',
  styleUrls: ['../../containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketBillingComponent {
  @Input() invoices: ContentDocumentLink[];
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Input() submissionError: boolean;
  @Input() isSubmitting: boolean;
  @Output() submitBillForm = new EventEmitter();
  @Input() errorMessage: string;

  billTicketGroup: UntypedFormGroup;
  formSubmitAttempted = false;
  inlineErrors: any = {};

  constructor(public datepipe: DatePipe) {
    this.billTicketGroup = new UntypedFormGroup({
      invoiceDate: new UntypedFormControl('', Validators.required),
      summary: new UntypedFormControl('', Validators.required)
    });
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.billTicketGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.billTicketGroup.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.billTicketGroup.controls[prop] as UntypedFormControl;
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
    return this.billTicketGroup.valid;
  }

  submitTicket() {
    if (this.billTicketGroup.valid) {
      // submit the ticket
      const formattedDate = this.datepipe.transform(this.billTicketGroup.value.invoiceDate.slice(0, 10), 'yyyy-MM-dd');
      const request = {
        invoice_date: formattedDate,
        description: this.billTicketGroup.value.summary
      };
      this.submitBillForm.emit(request);
    } else {
      this.canSubmit();
      this.formSubmitAttempted = true;
    }
  }
}
