import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

function phoneNumberValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const preferredContactMethod = form.get('preferredContactMethod');

    if (preferredContactMethod?.value === 'Alternate Phone') {
      const countryCode = form.get('countryCode').value;
      const phoneNumber = form.get('alternatePhone').value;

      if (countryCode != '' && phoneNumber != '') {
        const phoneUtil = PhoneNumberUtil.getInstance();
        let parsedPhone = null;

        try {
          parsedPhone = phoneUtil.parse(phoneNumber, countryCode);
        } catch (error) {
          return { isValidPhoneNumber: true };
        }

        if (!phoneUtil.isValidNumberForRegion(parsedPhone, countryCode)) {
          return { isValidPhoneNumber: true };
        }

        //phoneUtil.formatOutOfCountryCallingNumber(parsedPhone, countryCode);
      }
    }
    return null;
  };
}

export { phoneNumberValidator };
