import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LineItem } from 'src/app/models/line-item.model';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'line-item-list-display',
  templateUrl: './line-item-list-display.component.html',
  styleUrls: ['./line-item-list-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemListDisplayComponent {
  @Input() order: Order;
  @Input() lineItem: LineItem;
  @Input() isSelected: boolean;
}
