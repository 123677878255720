import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromStore from 'src/app/store';
import { Ticket } from 'src/app/models/ticket.model';

@Injectable()
export class TicketExistsGuards  {
  constructor(private store: Store<fromStore.State>) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.checkStore().pipe(
      switchMap(() => {
        const id = route.params.ticketId;
        return this.hasTicket(id);
      })
    );
  }

  hasTicket(id: number): Observable<boolean> {
    return this.store.select(fromStore.getTicketsEntities).pipe(
      map((entities: { [key: number]: Ticket }) => !!entities[id]),
      take(1)
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getTicketsLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(new fromStore.LoadTickets());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }
}
