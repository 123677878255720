<section class="parent-container">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <mat-toolbar>
          <h1>Metric Details</h1>
          <span class="spacer"></span>
          <div class="title-right">
            <app-sorting-dropdown
              (selectedOptionChange)="onSortingChange($event)"
            />
            <app-select-dimension-dropdown
              (selectedOptionChange)="onDimensionChange($event)"
              class="header-select"
            />
            <app-analytics-time-selector
              (selectedOptionChange)="onChildSelectionChange($event)"
              label="Activation Time"
              class="header-select"
            />
          </div>
        </mat-toolbar>
      </div>
    </div>

    <div class="chart-filters" *ngIf="selectedDimension === 'connection'">
      <div class="dimension-filter-box">
        <div>
          <mat-button-toggle-group
            name="metricType"
            aria-label="metrics"
            [(ngModel)]="metricType"
            (change)="onMetricChange()"
            class="filter-buttons-box"
          >
            <mat-button-toggle value="data_usage">Usage</mat-button-toggle>
            <mat-button-toggle value="data_utilization"
              >Utilization</mat-button-toggle
            >
            <mat-button-toggle value="jitter">Jitter</mat-button-toggle>
            <mat-button-toggle value="latency">Latency</mat-button-toggle>
            <mat-button-toggle value="packet_loss"
              >Packet Loss</mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <mat-grid-list
      cols="2"
      rowHeight="400px"
      gutterSize="20px"
      *ngIf="!isNoContent"
    >
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-horizontal-bar-graph
            [data]="sortedMetricsData"
            [categories]="metricCategories"
            [chartName]="selectedMetricLabel"
            (changeDevice)="onChangeDevice($event)"
            *ngIf="!isNoContent && !loading"
          />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-trend-histogram
            [data]="historicalMetricData"
            [categories]="historicalCategories"
            [chartName]="selectedDeviceChartLabel"
            *ngIf="!isNoContent && !loading"
          />
        </app-analytics-widget>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <app-metric-details
    [dataSource]="unmTableData"
    [loading]="tableLoading"
    [isNoContent]="isNoTableContent"
    [tableDimension]="selectedDimension"
  />
</section>
