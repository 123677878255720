import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'ticket-detail-contact',
  templateUrl: './ticket-detail-contact.component.html',
  styleUrls: ['./ticket-detail-contact.component.scss']
})
export class TicketDetailContactComponent {
  @Input() ticket: Ticket;
}
