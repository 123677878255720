<section>
    <div class="widget-container">
  
      <div class="loading-spinner" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
  
      <div class="chart-container" *ngIf="!loading">
        <canvas
          baseChart
          [data]="{ labels: pieChartData.labels,
          datasets: [{
            label: 'Orders',
            data: pieChartData.datasets[0].data,
            backgroundColor: backgroundColor,
            hoverOffset: 4
          }]}"
          [labels]="pieChartData.labels"
          [type]="pieChartType"
          [options]="pieChartOptions"
          (chartClick)="chartClicked($event)"
          
          [type]="'pie'"
        >
        </canvas>
      </div>
    </div>
  </section>
  