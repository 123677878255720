import { Action } from '@ngrx/store';
import { Invoice } from 'src/app/models/invoice.model';

export const LOAD_INVOICES = '[Invoices] Load Invoices';
export const LOAD_INVOICES_FAIL = '[Invoices] Load Invoices Fail';
export const LOAD_INVOICES_SUCCESS = '[Invoices] Load Invoices Success';
export const SET_SELECTED_INVOICE = '[Invoices] Set Selected Invoice';

export class LoadInvoices implements Action {
  readonly type = LOAD_INVOICES;
}

export class LoadInvoicesFail implements Action {
  readonly type = LOAD_INVOICES_FAIL;
  constructor(public payload: any) {}
}

export class LoadInvoicesSuccess implements Action {
  readonly type = LOAD_INVOICES_SUCCESS;
  constructor(public payload: Invoice[]) {}
}

export class SetSelectedInvoice implements Action {
  readonly type = SET_SELECTED_INVOICE;
  constructor(public payload: string) {}
}

// action types
export type InvoicesAction = LoadInvoices | LoadInvoicesFail | LoadInvoicesSuccess | SetSelectedInvoice;
