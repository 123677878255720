import { Component, Input, OnInit } from '@angular/core';

import OrderContactInfo from './order-contact-info.interface';

@Component({
  selector: 'app-order-contacts-box',
  templateUrl: './order-contacts-box.component.html',
  styleUrls: ['./order-contacts-box.component.scss']
})
export class OrderContactsBoxComponent implements OnInit {

	@Input() data: OrderContactInfo[];

  constructor() { }

  ngOnInit(): void {
  }

}
