<ng-container *ngIf="lineItem">
  <section class="widget order-detail order-page">
    <div class="content" *ngIf="isLineItemAvailable">
      <detail-header [order]="order" [browserSize]="browserSize"></detail-header>

      <div class="line-item-details">
        <div class="location-address">
          <div class="custom-icon location_dark">location icon</div>
          {{ lineItem.Location__r?.Name }}
        </div>
      </div>

      <div class="line-item-details">
        <div class="service-order">
          <div class="custom-icon circuit_dark">circuit icon</div>
          Service Order: <span>{{ lineItem.Service_Order2__r?.Name || 'N/A' }}</span>
        </div>
      </div>

      <div class="line-item-details" *ngIf="lineItem.Service__c">
        <div class="service-wrapper">
          <div class="service-details">
            <div class="service-title">
              Service: <span>{{ lineItem.Service__r?.Name || 'N/A' }}</span>
            </div>
            <div class="type-details">
              <div class="type">
                <span>Type:</span> {{ lineItem.Service_Type__c || 'N/A' }}
              </div>
              <div class="bandwidth">
                <span>Bandwidth: </span>
                {{ lineItem.Service__r?.Bandwidth_Downstream__c || 'N/A' }}
                <ng-container *ngIf="lineItem.Service__r?.Asymmetrical_Bandwidth__c">
                  / {{ lineItem.Service__r?.Bandwidth_Upstream__c }}
                </ng-container>
              </div>
              <div class="connection-type">
                <span>Connector Type:</span>
                {{
                  lineItem.Service__r.Connector_Type_Z_Location__c
                    ? lineItem.Service__r.Connector_Type_Z_Location__c
                    : 'N/A'
                }}
              </div>
              <div class="term"><span>Term:</span> {{ lineItem?.Term__c || 'N/A' }}</div>
            </div>
          </div>
          <ng-container *ngIf="lineItem.Service_Order2__r">
            <div class="status-details" *ngIf="lineItem.Service_Order2__r.Status__c !== 'Canceled'">
             <!--  <mat-spinner [diameter]="20" *ngIf="isTasksLoading && !isTasksLoaded"></mat-spinner> -->
              <app-line-items-status
                [tasks]="tasks$ | async"
                [orderType]="lineItem.RecordType?.Name"
              ></app-line-items-status>
              <div class="completed-date" *ngIf="lineItem.Service_Order2__r?.Completed_Date__c">
                <span>Completed Date:</span> {{ lineItem.Service_Order2__r?.Completed_Date__c | date: 'M/d/yy' }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="detail-wrapper">
        <div class="contacts-information">
          <div class="sales-rep">
            Sales Representative:
            <a
              (click)="viewContactInformation(order.Sales_Rep__r, 'Sales Representative')"
              *ngIf="order.Sales_Rep__r; else noSalesContact">
              {{ order.Sales_Rep__r.Name }}
            </a>
            <ng-template #noSalesContact>N/A</ng-template>
          </div>
          <div class="account-manager">
            Account Manager:
            <a
              (click)="viewContactInformation(order.Account_Manager__r, 'Account Manager')"
              *ngIf="order.Account_Manager__r; else noAMContact">
              {{ order.Account_Manager__r.Name }}
            </a>
            <ng-template #noAMContact>N/A</ng-template>
          </div>
          <div class="project-manager">
            Client Project Manager:
            <ng-container *ngIf="order.Default_Task_Assignments__r; else noPMContact">
              <a
                (click)="
                  viewContactInformation(
                    order.Default_Task_Assignments__r?.records[0].User__r,
                    'Client Project Manager'
                  )
                "
                >{{ order.Default_Task_Assignments__r?.records[0]?.User__r?.Name }}</a
              >
            </ng-container>
            <ng-template #noPMContact>N/A</ng-template>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isLineItemAvailable && !areOrdersLoading">
      <div class="content">
        <br />
        <p>The line item you are requesting does not exists.</p>
      </div>
    </ng-container>
    <app-footer></app-footer>
  </section>
</ng-container>
