import { Action } from '@ngrx/store';
import { Contact } from 'src/app/models/contact.model';

// Load user
export const LOAD_CONTACTS = '[Contacts] Load Contacts';
export const LOAD_CONTACTS_FAIL = '[Contacts] Load Contacts Fail';
export const LOAD_CONTACTS_SUCCESS = '[Contacts] Load Contacts Success';
export const SET_CONTACTS = '[Contacts] Set Contacts';

export class LoadContacts implements Action {
  readonly type = LOAD_CONTACTS;
}

export class LoadContactsFail implements Action {
  readonly type = LOAD_CONTACTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadContactsSuccess implements Action {
  readonly type = LOAD_CONTACTS_SUCCESS;
  constructor(public payload: Contact[]) {}
}

// action types
export type ContactsAction = LoadContacts | LoadContactsFail | LoadContactsSuccess;
