import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TicketService } from 'src/app/services/ticket.service';
import * as ticketActions from '../actions/tickets.action';

@Injectable()
export class TicketEffects {
  constructor(private actions$: Actions, private ticketService: TicketService) {}

  loadTickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ticketActions.LOAD_TICKETS),
      switchMap(() => {
        return this.ticketService.getTickets().pipe(
          map((tickets) => new ticketActions.LoadTicketsSuccess(tickets)),
          catchError((error) => of(new ticketActions.LoadTicketsFail(error)))
        );
      })
    );
  });

  loadOpenTicketsTotalNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ticketActions.LOAD_TOTAL_OPEN_TICKETS),
      switchMap(() => {
        return this.ticketService.getOpenTicketTotal().pipe(
          map((total) => new ticketActions.LoadTotalOpenTicketsSuccess(total)),
          catchError((error) => of(new ticketActions.LoadTotalOpenTicketsFail(error)))
        );
      })
    );
  });
}
