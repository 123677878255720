import {
  Component,
  OnDestroy,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';

import { AttachmentService } from 'src/app/services/attachment.service';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';

@Component({
  selector: 'app-billing-detail',
  templateUrl: './billing-detail.component.html',
  styleUrls: ['./billing-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingDetailComponent implements OnChanges, OnDestroy {
  @Input() selectedInvoice: ContentDocumentLink;
  @Input() isInvoicesLoading: boolean;
  @Output() downloadInvoice: EventEmitter<any> = new EventEmitter();

  subscriptions: any[];
  fileSubscription: Subscription;

  browserSize: string;
  pdfLoading = false;
  pdfResourceUrl = null;
  pdfUrl: any;
  canEmbedPdf: boolean;
  totalPages: number;
  page = 1;
  isLoaded = false;

  @ViewChild(PdfViewerComponent, { static: true }) pdfComponent: PdfViewerComponent;

  constructor(
    private attachmentService: AttachmentService,
    private domSanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges() {
    if (this.selectedInvoice) {
      this.pdfLoading = true;
    }
    this.viewEmbeddedPDF(this.selectedInvoice);
  }

  ngOnDestroy(): void {
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
    }
  }

  onDownload(invoice: ContentDocumentLink): void {
    this.downloadInvoice.emit(invoice);
  }

  viewEmbeddedPDF(invoice: ContentDocumentLink): void {
    if (invoice) {
      this.page = 1;
      this.pdfResourceUrl = null;
      this.pdfLoading = true;

      this.fileSubscription = this.attachmentService.getFile(invoice).subscribe((pdf) => {
        this.pdfLoading = false;
        this.pdfResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(pdf.url);
        this.pdfUrl = pdf.url;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  afterLoadComplete(pdfData: any): void {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  incrementPage(amount: number): void {
    this.page += amount;
  }

  onError(): void {
    this.pdfLoading = false;
    this.canEmbedPdf = false;
  }
}
