import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

interface UnusualNetworkMetrics {
  edgeName: string;
  interfaceName: string;
  timestamp: string;
  bandwidth: number;
  latency: number;
  jitter: number;
  packetloss: number;
  bucket: string;
  datausage: number;
  anomalies: string;
}

@Component({
  selector: 'app-unm-data-table',
  templateUrl: './unm-data-table.component.html',
  styleUrls: ['./unm-data-table.component.scss'],
})
export class UnmDataTableComponent implements OnInit, OnChanges {
  @Input() dataSource: MatTableDataSource<UnusualNetworkMetrics>; // Input for data source
  @Input() loading: boolean = false; // Loading state
  @Input() isNoContent = false; // Display flag for content availability

  @ViewChild(MatSort, { static: true }) sort: MatSort; // Reference to Material Table Sort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator; // Reference to Material Table Paginator

  // Define the displayed columns
  displayedColumns: string[] = [
    'edgeName',
    'interfaceName',
    'timestamp',
    'bandwidth',
    'latency',
    'jitter',
    'drops',
    'bucket',
    'datausage',
    'anomalies',
  ];

  // Filters and options
  filters = ['edgeNameFilter', 'interfaceNameFilter', 'timestampFilter', 'bandwidthFilter', 'latencyFilter', 'jitterFilter', 'dropsFilter', 'bucketFilter', 'dataUsageFilter', 'anomaliesFilter'];
  filters2 = ['edgeNameFilter2', 'interfaceNameFilter2', 'timestampFilter2', 'bandwidthFilter2', 'latencyFilter2', 'jitterFilter2', 'dropsFilter2', 'bucketFilter2', 'dataUsageFilter2', 'anomaliesFilter2'];
  severityOptions: string[] = ['warning', 'critical', 'other', 'all']; // Picklist for bucket filter
  showFilters = false; // Toggle filter visibility
  pageSizeOptions: number[] = [10, 25, 50, 75, 100]; // Pagination options
  defaultPageSizeOption: number = 10; // Default page size

  // Object to hold filter values
  filterValues: { [key: string]: string | null } = {
    edgeName: '',
    interfaceName: '',
    bandwidth: '',
    latency: '',
    jitter: '',
    drops: '',
    bucket: 'all',
    datausage: '',
    anomalies: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  constructor() { }

  // Lifecycle hook to initialize the table
  ngOnInit() {
    this.initTable();
  }

  // Re-initialize the table when @Input changes
  ngOnChanges() {
    this.initTable();
  }

  // Initialize table properties
  private initTable(): void {
    if (!this.dataSource) {
      return;
    }

    this.dataSource.sort = this.sort; // Enable sorting
    this.dataSource.paginator = this.paginator; // Enable pagination

    // Custom sorting accessor for complex fields
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'timestamp':
          return new Date(item.timestamp).getTime(); // Sort by timestamp
        case 'bandwidth':
        case 'latency':
        case 'jitter':
        case 'datausage':
          return +item[property]; // Numeric fields
        case 'drops':
          return +item.packetloss; // Map 'drops' to packetloss
        default:
          return item[property]; // Default sorting
      }
    };

    // Apply custom filtering logic
    this.dataSource.filterPredicate = this.createFilter();
    this.clearFilter(); // Reset filters on initialization
  }
 
  // Apply a filter to a specific column
  applyFilter(event: Event, column: string): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase(); // Get filter value
    this.filterValues[column] = filterValue; // Update filter value
    this.dataSource.filter = JSON.stringify(this.filterValues); // Apply filter
  }

  // Apply date range filters
  applyDateFilter(): void {
    if (this.filterValues.startDate && !this.filterValues.startTime) {
      this.filterValues.startTime = this.getCurrentTimeIn12HourFormat();
    }
    if (this.filterValues.endDate && !this.filterValues.endTime) {
      this.filterValues.endTime = this.getCurrentTimeIn12HourFormat();
    }

    this.dataSource.filter = JSON.stringify(this.filterValues); // Apply filters
  }

  // Apply a picklist filter (e.g., for severity options)
  applyPicklistFilter(filterValue: string | null, column: string): void {
    this.filterValues[column] = filterValue ? filterValue.trim().toLowerCase() : null; // Update filter value
    this.dataSource.filter = JSON.stringify(this.filterValues); // Apply filter
  }

  // Reset all filters
  clearFilter(): void {
    this.filterValues = {
      edgeName: '',
      interfaceName: '',
      bandwidth: '',
      latency: '',
      jitter: '',
      drops: '',
      bucket: 'all',
      datausage: '',
      anomalies: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    };
    this.dataSource.filter = JSON.stringify(this.filterValues); // Clear filters
  }

  // Custom filter function for multi-column filtering
  private createFilter(): (data: UnusualNetworkMetrics, filter: string) => boolean {
    return (data: UnusualNetworkMetrics, filter: string): boolean => {
      const searchTerms = JSON.parse(filter); // Parse filter object
      const timestamp = new Date(data.timestamp);

      return Object.keys(searchTerms).every((key) => {
        const filterValue = searchTerms[key];

        if (!filterValue) return true; // Skip if no filter value

        // Combine start date and time for filtering
        if (key === 'startDate' || key === 'startTime') {
          const startDateTime = searchTerms.startDate
            ? new Date(`${new Date(searchTerms.startDate).toLocaleDateString("en-US")} ${searchTerms.startTime || '12:00 AM'}`)
            : null;
          if (startDateTime) {
            return timestamp >= startDateTime;
          }
        }

        // Combine end date and time for filtering
        if (key === 'endDate' || key === 'endTime') {
          const endDateTime = searchTerms.endDate
            ? new Date(`${new Date(searchTerms.endDate).toLocaleDateString("en-US")} ${searchTerms.endTime || '11:59 PM'}`)
            : null;
          if (endDateTime) {
            return timestamp <= endDateTime;
          }
        }

        // Handle bucket filter with "other" logic
        if (key === 'bucket') {
          if (filterValue === 'all') {
            return true;
          }
          else if (
            filterValue === 'other' &&
            data.bucket?.toLowerCase().trim() !== 'warning' &&
            data.bucket?.toLowerCase().trim() !== 'critical'
          ) {
            return true;
          }
          return data.bucket?.toLowerCase().includes(filterValue);
        }

        // Handle numeric and string filters
        if (['bandwidth', 'latency', 'jitter', 'drops', 'datausage'].includes(key)) {
          return data[key as keyof UnusualNetworkMetrics]?.toString().includes(filterValue);
        }

        // General string match for other fields
        return data[key as keyof UnusualNetworkMetrics]
          ?.toString()
          .toLowerCase()
          .includes(filterValue);
      });
    };
  }

  // Toggle the visibility of filters
  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  // This method retrieves the current local time, formats it into the 12-hour clock format
  private getCurrentTimeIn12HourFormat(): string {
    const now = new Date();

    // Get hours and minutes
    let hours = now.getHours();
    const minutes = now.getMinutes();

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 -> 12)

    // Format minutes to always have two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into 12-hour format string
    return `${hours}:${formattedMinutes} ${ampm}`;
  }
}
