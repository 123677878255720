import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { MappedDisconnectService } from '../../../models/service.model';
import { ServiceService } from '../../../services/service.service';

@Component({
  selector: 'app-select-multiple-services',
  templateUrl: './select-multiple-services.component.html',
  styleUrls: [
    '../../../tickets/containers/create-ticket/create-ticket.component.scss',
    '../service-location/service-location.component.scss',
    './select-multiple-services.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMultipleServicesComponent implements OnInit {
  @Input() formRef: UntypedFormGroup = new UntypedFormGroup({});
  @Input() inlineErrors: any = {};
  @Input() selectedServiceId: string;
  @Output() servicesLoaded = new EventEmitter(false);

  services$: Observable<MappedDisconnectService[]>;
  serviceSubscription: Subscription;
  isLoadingServices = true;
  isIdentifyingByLocation = true;

  allLocations = [];
  allServices = [];
  options: Observable<any>;
  filteredOptions: Observable<any[]>;
  serviceObject = {};
  servicesAtChosenLocation = [];
  LOCATION = 'location';
  SERVICE = 'service';
  ticketType = 'tob';
  allTempLocations = [];

  identifier = this.LOCATION;
  selectedServicesTotal = 0;
  chosenService = null;

  constructor(
    private serviceService: ServiceService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.serviceService.getServicesInService().subscribe((data) => {
      this.servicesLoaded.emit(true);

      // Get all services with location
      // Some services have bad data & don't come with a location
      this.allServices = data.map((s) => {
        if (s.Z_Location__c) {
          return {
            alias: s?.Vendor__r?.Display_Alias__c,
            id: s.Id,
            nit: s.Name,
            product: s.RecordType.Name,
            locationId: s.Z_Location__c,
            locationName: s.Z_Location__r.Name,
            bandwidth: s?.Bandwidth_Text__c,
            disabled: false
          };
        }
      });

      if (this.allServices.length === 1) {
        this.formRef.get(`services.${0}.locationId`).setValue(`${this.allServices[0].locationId}`);
        this.formRef.get(`services.${0}.serviceId`).setValue(`${this.allServices[0].id}`);
        this.formRef.get(`services.${0}.locationAddress`).setValue(`${this.allServices[0].locationName}`);
      }

      // Filter out duplicate locations for location dropdown
      const m = new Map();
      // eslint-disable-next-line no-restricted-syntax
      for (const service of data) {
        this.serviceObject[service.Id] = service;
        // If service has location, create array.
        if (service.Z_Location__r && !m.has(service.Z_Location__c)) {
          m.set(service.Z_Location__c, true);
          service.disabled = false;
          this.allLocations.push(service);
        }
      }
      this.allLocations.sort((a, b) => {
        return a.Z_Location__r.Name < b.Z_Location__r.Name ? -1 : a.Z_Location__r.Name > b.Z_Location__r.Name ? 1 : 0;
      });

      this.isLoadingServices = false;
      this.canSubmit();

      if(this.selectedServiceId && this.identifier === this.LOCATION) {     
        let selectedService = this.allServices.find(service => service.id === this.selectedServiceId);
        selectedService.serviceIterator = 0;
        selectedService.identifyIssueBy = this.SERVICE;
        this.identifier = this.SERVICE;

        this.formRef.get(`services.${0}.identifyIssueBy`).setValue(this.SERVICE)
        this.formRef.get(`services.${0}.locationAddress`).setValue(`${selectedService.locationName}`);
        this.formRef.get(`services.${0}.serviceId`).setValue(`${selectedService.id}`);
        this.formRef.get(`services.${0}.locationId`).setValue(selectedService.locationId);
        
        const servicesByLocationArray = this.formRef.get(
          `services.${0}.servicesByLocation`
        ) as UntypedFormArray;
          const newServiceGroup: UntypedFormGroup = this.fb.group({
            id: [`${selectedService.id}`],
            nit: [`${selectedService.nit}`],
            product: [`${selectedService.product}`],
            locationId: [`${selectedService.locationId}`],
            locationName: [`${selectedService.locationName}`],
            disabled: [`${selectedService.disabled}`]
          });
          servicesByLocationArray[0] = newServiceGroup;

        this.updateServiceDisabledAttribute();
        this.changeDetectorRef.detectChanges();
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  onRadioChange(index: number) {
    // Reset or empty values in the selected service's dropdowns or inputs
    this.formRef.get(`services.${index}.locationId`).setValue(null);
    this.formRef.get(`services.${index}.serviceId`).setValue(null);
    this.formRef.get(`services.${index}.locationAddress`).setValue(null);
    this.identifier = this.formRef.get(`services.${index}.identifyIssueBy`).value;
    this.selectedServiceId = null;

    // Empty the services array if location dropdown has been re-selected
    (<UntypedFormArray>this.formRef.get(`services.${index}.servicesByLocation`)).clear();
    this.updateServiceDisabledAttribute();
    
    this.formRef.controls.locationOfService.updateValueAndValidity();
    this.formRef.controls.services.updateValueAndValidity();
    this.canSubmit();
  }

  locationSelected(location) {
    const selectedService = this.allServices.find((service) => service.locationId === location.Id);

    // Set location's full address to services formcontrol
    // Set service id in order to update the disabled attribute upon selection
    this.formRef
      .get(`services.${location.locationIterator}.locationAddress`)
      .setValue(`${selectedService.locationName}`);
    this.formRef.get(`services.${location.locationIterator}.locationId`).setValue(location.Id);

    // Empty the array if dropdown has been re-selected
    (<UntypedFormArray>this.formRef.get(`services.${location.locationIterator}.servicesByLocation`)).clear();

    // Get services associated with location selected
    let servicesAtLocation = [];
    servicesAtLocation = this.allServices.filter((service) =>
      service.locationId ? service.locationId === location.Id : false
    );

    // Insert filtered services array in the service dropdown based on selected location
    const servicesByLocationArray = this.formRef.get(
      `services.${location.locationIterator}.servicesByLocation`
    ) as UntypedFormArray;
    servicesAtLocation.forEach((element, i) => {
      const newServiceGroup: UntypedFormGroup = this.fb.group({
        id: [`${element.id}`],
        nit: [`${element.nit}`],
        product: [`${element.product}`],
        locationId: [`${element.locationId}`],
        locationName: [`${element.locationName}`],
        disabled: [`${element.disabled}`]
      });
      servicesByLocationArray.insert(i, newServiceGroup);
    });

    // if location has only one service, the service will automatically appear as static in UI
    // sets the service id to the form for submission and sets the disabled attribute to true.
    if (servicesByLocationArray.length === 1) {
      // Set service id in order to update the disabled attribute upon selection
      this.formRef.get(`services.${location.locationIterator}.serviceId`).setValue(`${selectedService.id}`);
      this.formRef.get(`services.${location.locationIterator}.servicesByLocation.0.disabled`).setValue(true);
    }

    this.updateServiceDisabledAttribute();
    this.changeDetectorRef.detectChanges();
  }

  onLocationSelected(locationId: string, index: number) {
    const selectedService = this.allServices.find((service) => service.locationId === locationId);

    // Set location's full address to services formcontrol
    // Set service id in order to update the disabled attribute upon selection
    this.formRef.get(`services.${index}.locationAddress`).setValue(`${selectedService.locationName}`);
    this.formRef.get(`services.${index}.locationId`).setValue(locationId);

    // Empty the array if dropdown has been re-selected
    (<UntypedFormArray>this.formRef.get(`services.${index}.servicesByLocation`)).clear();

    // Get services associated with location selected
    let servicesAtLocation = [];
    servicesAtLocation = this.allServices.filter((service) =>
      service.locationId ? service.locationId === locationId : false
    );

    // Insert filtered services array in the service dropdown based on selected location
    const servicesByLocationArray = this.formRef.get(`services.${index}.servicesByLocation`) as UntypedFormArray;
    servicesAtLocation.forEach((element, i) => {
      const newServiceGroup: UntypedFormGroup = this.fb.group({
        id: [`${element.id}`],
        nit: [`${element.nit}`],
        product: [`${element.product}`],
        locationId: [`${element.locationId}`],
        locationName: [`${element.locationName}`],
        disabled: [`${element.disabled}`]
      });
      servicesByLocationArray.insert(i, newServiceGroup);
    });

    // if location has only one service, the service will automatically appear as static in UI
    // sets the service id to the form for submission and sets the disabled attribute to true.
    if (servicesByLocationArray.length === 1) {
      // Set service id in order to update the disabled attribute upon selection
      this.formRef.get(`services.${index}.serviceId`).setValue(`${selectedService.id}`);
      this.formRef.get(`services.${index}.servicesByLocation.0.disabled`).setValue(true);
    }

    this.updateServiceDisabledAttribute();
    this.changeDetectorRef.detectChanges();
  }

  onServiceSelected(serviceId: string, index: number) {
    if (serviceId !== null) {
      // Get location's full address
      const locationAddress = this.allServices.find((service) => {
        return service.id === serviceId ? `${service}` : '';
      });

      // Set location's full address to services formcontrol
      this.formRef.get(`services.${index}.locationAddress`).setValue(`${locationAddress.locationName}`);
      // Set service id in order to update the disabled attribute upon selection
      this.formRef.get(`services.${index}.serviceId`).setValue(serviceId);
      this.formRef.get(`services.${index}.locationId`).setValue(locationAddress.locationId);

      this.updateServiceDisabledAttribute();
      this.changeDetectorRef.detectChanges();
    }
  }

  serviceSelected(service) {
    this.formRef.get(`services.${service.serviceIterator}.locationAddress`).setValue(`${service.locationAddress}`);
    this.formRef.get(`services.${service.serviceIterator}.serviceId`).setValue(`${service.serviceId}`);
    this.formRef.get(`services.${service.serviceIterator}.locationId`).setValue(service.locationId);
    this.updateServiceDisabledAttribute();
    this.changeDetectorRef.detectChanges();
  }

  updateServiceDisabledAttribute() {
    const servicesSelected = [];
    const servicesForm = this.formRef.get(`services`).value as any;
    servicesForm.forEach((element: any) => {
      if (element.serviceId) {
        servicesSelected.push(element.serviceId);
      }
    });
    this.selectedServicesTotal = servicesSelected.length;

    this.allServices.forEach((service) => {
      service.disabled = false;
      servicesSelected.forEach((selected) => {
        if (service.id === selected) {
          service.disabled = true;
        }
      });
    });
    this.updateLocationDisabledAttribute();
  }

  updateLocationDisabledAttribute() {
    this.allLocations.forEach((location) => {
      location.disabled = false;
      // Get all services for the location
      const locationServices = this.allServices.filter((service) => service.locationId === location.Z_Location__c);
      // If all services are disabled, disable location attribute
      const allDisabled = locationServices.every((service) => {
        return service.disabled;
      });

      if (allDisabled) {
        location.disabled = true;
      }
    });
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.formRef.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.formRef.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.formRef.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
    return this.formRef.valid;
  }

  removeServicesFormControl(i) {
    // Remove a service dropdown to disconnect
    const servicesArray = this.formRef.controls.services as UntypedFormArray;
    servicesArray.removeAt(i);
    this.updateServiceDisabledAttribute();
  }

  addServicesFormControl() {
    // Add another service dropdown to disconnect
    const servicesArray = this.formRef.controls.services as UntypedFormArray;
    const arrayLength = servicesArray.length;

    const newServiceGroup: UntypedFormGroup = this.fb.group({
      identifyIssueBy: ['location'],
      serviceId: [null, Validators.required],
      locationId: [null, Validators.required],
      locationAddress: [null],
      servicesByLocation: this.fb.array([
        this.fb.group({
          id: this.fb.control(''),
          disabled: this.fb.control(''),
          nit: this.fb.control(''),
          product: this.fb.control(''),
          locationId: this.fb.control(''),
          locationName: this.fb.control('')
        })
      ])
    });
    servicesArray.insert(arrayLength, newServiceGroup);
    this.updateServiceDisabledAttribute();
  }
}
