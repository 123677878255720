import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import { ApiService } from './api.service';
import { api } from '../models/api-url.model';

export interface FileAPI {
  binaryData: ArrayBuffer;
  blob?: Blob;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  constructor(private apiService: ApiService) {}

  getServiceOrderForms(): Observable<ContentDocumentLink[]> {
    return this.apiService.get(api.sofAttachmentURL);
  }

  getInvoices(): Observable<ContentDocumentLink[]> {
    return this.apiService.get(api.invoiceAttachmentURL);
  }

  addAttachment(formData): Observable<any> {
    return this.apiService.postFormData(api.addAttachmentURL, formData);
  }

  getFile(file: ContentDocumentLink): Observable<FileAPI> {
    return this.apiService
      .get(`${api.attachmentURL}/${file.ContentDocument.LatestPublishedVersionId}`, { responseType: 'arraybuffer' })
      .pipe(
        map((data) => {
          const newBlob = new Blob([data], { type: `${file.ContentDocument.FileExtension}` });
          const url = window.URL.createObjectURL(newBlob);
          return { binaryData: data, url };
        })
      );
  }

  getFolderContents(recordId: string): Observable<any> {
    return this.apiService.get(`${api.getAttachmentsUrl}/${recordId}`);
  }
}
