<div
  class="banner-message"
  [ngClass]="{ hide: hideMessage, warning: status == 'warning', success: status == 'success' }"
>
  <div class="details">
    <div class="custom-icon {{ statusIcon }}">{{ statusIcon }}</div>
    {{ message }}
  </div>
  <i class="material-icons dismiss" *ngIf="dismiss" (click)="dismissMessage()">close</i>
</div>
