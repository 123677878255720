import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  copyrightYear: number;

  // constructor() {}

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
  }
}
