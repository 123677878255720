import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { TaskService } from 'src/app/services/task.service';
import { Task } from 'src/app/models/task.model';
import { LineItem } from 'src/app/models/line-item.model';
import { Order } from 'src/app/models/order.model';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'line-item-detail',
  templateUrl: './line-item-detail.component.html',
  styleUrls: ['./line-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemDetailComponent {
  @Input() order: Order;
  @Input() lineItem: LineItem;
  @Input() areOrdersLoading: boolean;
  @Input() browserSize: string;
  @Output() popupContactInformation: EventEmitter<any> = new EventEmitter();

  tasks$: Observable<Task[]>;
  isLineItemAvailable = false;
  isTasksLoading = true;
  isTasksLoaded = false;
  hasTasksError = false;

  constructor(private taskService: TaskService, private router: Router) {}

  viewContactInformation(user: User, title: string) {
    this.popupContactInformation.emit({ user, title });
  }

  ngOnChanges() {
    if (this.browserSize !== 'small') {
      this.router.navigateByUrl('/orders');
    }

    if (!this.lineItem || !this.order) {
      this.isLineItemAvailable = false;
    } else {
      this.isLineItemAvailable = true;
      if (this.lineItem.Service_Order2__c) {
        this.getLineItemTasks(this.lineItem.Service_Order2__c);
      }
    }
  }

  getLineItemTasks(serviceOrderId: string) {
    this.tasks$ = this.taskService.getTasks(serviceOrderId);
    this.tasks$.subscribe(
      () => {
        this.isTasksLoading = false;
        this.isTasksLoaded = true;
      },
      () => {
        this.isTasksLoading = false;
        this.isTasksLoaded = false;
        this.hasTasksError = true;
      }
    );
  }
}
