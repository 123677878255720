import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BillingService } from 'src/app/services/billing.service';
import * as invoiceActions from '../actions/invoices.action';

@Injectable()
export class InvoiceEffects {
  constructor(private actions$: Actions, private billingService: BillingService) {}

  loadInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invoiceActions.LOAD_INVOICES),
      switchMap(() => {
        return this.billingService.getInvoices().pipe(
          map((invoices) => new invoiceActions.LoadInvoicesSuccess(invoices)),
          catchError((error) => of(new invoiceActions.LoadInvoicesFail(error)))
        );
      })
    );
  });
}
