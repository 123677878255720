import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'ticket-detail-service',
  templateUrl: './ticket-detail-service.component.html',
  styleUrls: ['./ticket-detail-service.component.scss']
})
export class TicketDetailServiceComponent {
  @Input() ticket: Ticket;
  @Input() browserSize: string;
}
