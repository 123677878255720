import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { OrderService } from 'src/app/services/order.service';
import * as orderActions from '../actions/orders.action';

@Injectable()
export class OrderEffects {
  constructor(private actions$: Actions, private orderService: OrderService) {}

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.LOAD_ORDERS),
      switchMap(() => {
        return this.orderService.getOrders().pipe(
          map((orders) => new orderActions.LoadOrdersSuccess(orders)),
          catchError((error) => of(new orderActions.LoadOrdersFail(error)))
        );
      })
    );
  });

  loadOrdersV2$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.LOAD_ORDERSV2),
      switchMap(() => {
        return this.orderService.getOrders().pipe(
          map((orders) => new orderActions.LoadOrdersV2Success(orders)),
          catchError((error) => of(new orderActions.LoadOrdersFail(error)))
        );
      })
    );
  });

  loadOpenTicketsTotalNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActions.LOAD_TOTAL_OPEN_ORDERS),
      switchMap(() => {
        return this.orderService.getOpenOrdersTotal().pipe(
          map((total) => new orderActions.LoadTotalOpenOrdersSuccess(total)),
          catchError((error) => of(new orderActions.LoadTotalOpenOrdersFail(error)))
        );
      })
    );
  });
}
