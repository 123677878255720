/* eslint-disable no-shadow */
import { createFeatureSelector } from '@ngrx/store';
import { Ticket } from 'src/app/models/ticket.model';
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for this feature (Tickets)
export interface TicketState {
  entities: { [id: string]: Ticket };
  selectedTicketId: string;
  totalOpenTickets: number;
  loaded: boolean;
  loading: boolean;
  error: string;
}

export const initialState: TicketState = {
  entities: {},
  selectedTicketId: null,
  totalOpenTickets: 0,
  loaded: false,
  loading: false,
  error: null
};

export interface State extends fromRoot.State {
  tickets: TicketState;
}

// Selector functions
export const getTicketFeatureState = createFeatureSelector<TicketState>('tickets');

export function reducer(state = initialState, action: fromActions.TicketsAction): TicketState {
  switch (action.type) {
    case fromActions.LOAD_TICKETS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_TICKETS_SUCCESS: {
      const tickets = action.payload;
      let entities = {};

      if (tickets !== null) {
        entities = tickets.reduce(
          (entities: { [id: string]: Ticket }, ticket) => {
            return {
              ...entities,
              [ticket.Id]: ticket
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_TICKETS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromActions.LOAD_TOTAL_OPEN_TICKETS_SUCCESS: {
      const total = action.payload;

      return {
        ...state,
        totalOpenTickets: total
      };
    }

    case fromActions.SET_SELECTED_TICKET: {
      const selectedTicket = action.payload;

      return {
        ...state,
        selectedTicketId: selectedTicket
      };
    }

    case fromActions.CLEAR_TICKETS: {
      return {
        ...state,
        entities: {}
      };
    }

    case fromActions.UPDATE_TICKET: {
      const ticket = action.payload;
      const entities = {
        ...state.entities,
        [ticket.Id]: ticket
      };

      return {
        ...state,
        entities
      };
    }

    default: {
      return state;
    }
  }
}

export const getTicketsEntities = (state: TicketState) => state.entities;
export const getTicketsLoading = (state: TicketState) => state.loading;
export const getTicketsLoaded = (state: TicketState) => state.loaded;
export const getTicketSelected = (state: TicketState) => state.selectedTicketId;
export const getTotalOpenTickets = (state: TicketState) => state.totalOpenTickets;
