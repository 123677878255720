import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ContactService } from 'src/app/services/contact.service';
import * as userActions from '../actions/users.action';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private contactService: ContactService) {}

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.LOAD_CURRENT_USER),
      switchMap(() => {
        return this.contactService.getLoggedInContact().pipe(
          map((user) => new userActions.LoadCurrentUserSuccess(user)),
          catchError((error) => of(new userActions.LoadCurrentUserFail(error)))
        );
      })
    );
  });
}
