import {
  ChangeDetectionStrategy,
  Output,
  ViewChild,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import * as FileSaver from 'file-saver';

import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import { FileAPI } from 'src/app/services/attachment.service';
import { Order } from 'src/app/models/order.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailComponent implements OnChanges {
  @Input() order: Order;
  @Input() serviceOrderForm: ContentDocumentLink;
  @Input() formAPIData: FileAPI;
  @Input() areOrdersLoading: boolean;
  @Input() isFileLoading: boolean;
  @Input() browserSize: string;

  @Output() popupContactInformation: EventEmitter<any> = new EventEmitter();
  @ViewChild(PdfViewerComponent, { static: true }) fileComponent: PdfViewerComponent;

  isFileLoaded = false;
  isPdf = false;
  isOrderAvailable = false;
  hasErrors = false;
  canEmbedFile: boolean;

  fileUrl: any = '';
  currentFilePage = 1;
  totalFilePages: number;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges() {
    if (this.order) {
      this.isOrderAvailable = true;
    } else {
      this.isOrderAvailable = false;
    }

    if (this.formAPIData) {
      this.fileUrl = this.formAPIData.url;
      if (this.serviceOrderForm.ContentDocument && this.serviceOrderForm.ContentDocument.FileExtension === 'pdf') {
        this.isPdf = true;
      }
      if (this.fileUrl !== '') {
        this.isFileLoaded = true;
        this.currentFilePage = 1;
        this.canEmbedFile = true;
      } else {
        this.onFileError();
      }
    } else {
      this.onFileError();
    }
    this.changeDetectorRef.detectChanges();
  }

  downloadFile() {
    const newBlob = new Blob([this.formAPIData.binaryData], {
      type: `application/${this.serviceOrderForm.ContentDocument.FileExtension}`
    });
    return FileSaver.saveAs(
      newBlob,
      `${this.serviceOrderForm.ContentDocument.Title}.${this.serviceOrderForm.ContentDocument.FileExtension}`
    );
  }

  onFileError() {
    this.isFileLoading = false;
    this.canEmbedFile = false;
    this.isFileLoaded = true;
  }

  afterFileLoadComplete(fileData: any) {
    this.totalFilePages = fileData.numPages;
    this.isFileLoaded = true;
  }

  viewContactInformation(user: User, title: string) {
    this.popupContactInformation.emit({ user, title });
  }
}
