import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

import { Task } from 'src/app/models/task.model';

@Component({
  selector: 'app-line-items-status',
  templateUrl: './line-items-status.component.html',
  styleUrls: ['./line-items-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineItemsStatusComponent implements OnChanges {
  @Input() orderType: string;
  @Input() tasks: Task[];

  isLoading = true;
  isLoaded = false;
  tasksError = false;

  completeStatuses: string[] = ['Complete', 'Skipped', 'Ready'];
  disconnectOrderStatus = {
    requestReceived: {
      title: 'Request Received',
      status: false,
      tasks: ['Send disconnect request']
    },
    disconnectOrder: {
      title: 'Disconnect Order',
      status: false,
      tasks: ['Disconnect service', 'Send Disconnect Survey', '	End billing', 'Reclaim facilities']
    },
    equipmentReturn: {
      title: 'Equipment Return',
      status: false,
      tasks: [
        'Request equipment return',
        'Equipment return notice',
        'Confirm equipment return',
        'Invoice customer for missing equipment'
      ]
    },
    complete: {
      title: 'Complete',
      status: false,
      tasks: []
    }
  };

  orderStatus = {
    preparingOrder: {
      title: 'Preparing Order',
      status: false,
      tasks: ['Pricing Validation', 'Tech Spec']
    },
    surveyDelivery: {
      title: 'Survey & Delivery',
      status: false,
      tasks: [
        'Submit Vendor Order',
        'Vendor FOC',
        'Site Survey',
        'Nitel FOC',
        'Carrier Firm Order Commitment Received Notice',
        'Customer Site Requirements',
        'Vendor Construction',
        'Special Construction',
        'Confirm S-Tag',
        'Populate/Confirm IP Info',
        'Ready to Configure',
        'Ready to Configure',
        'Nitel Overall Firm Order Commitment Notice',
        'Your IP Addresses Have Been Assigned Notice'
      ]
    },
    serviceProvisioning: {
      title: 'Service Provisioning',
      status: false,
      tasks: [
        'Vendor Service Installation',
        'Assign Equipment Asset',
        'Configure Equipment',
        'Ship Equipment',
        'Equipment Shipped Notice'
      ]
    },
    complete: {
      title: 'Complete',
      status: false,
      tasks: [
        'Nitel Service Completion',
        'Start Of Service Notice',
        'Schedule Activation',
        'Activate Service',
        'Schedule Activation Notice',
        'Start Billing',
        'Service Activated Notice',
        'Send Activation Survey'
      ]
    }
  };

  ngOnChanges() {
    if (this.tasks) {
      this.updateOrderStatus(this.tasks);
    }
  }

  updateOrderStatus(tasks: Task[]) {
    if (this.orderType === 'Disconnect') {
      const requestReceived = tasks.filter((t) => t.Phase_Name__c === 'Vendor Disconnect').map((t) => t.Status__c);

      const disconnectOrder = tasks.filter((t) => t.Phase_Name__c === 'Nitel Disconnect').map((t) => t.Status__c);

      const equipmentReturn = tasks.filter((t) => t.Phase_Name__c === 'Equipment Return').map((t) => t.Status__c);

      this.disconnectOrderStatus.requestReceived.status =
        requestReceived.length > 0 ? requestReceived.every((status) => this.completeStatuses.includes(status)) : false;
      this.disconnectOrderStatus.disconnectOrder.status =
        disconnectOrder.length > 0 ? disconnectOrder.every((status) => this.completeStatuses.includes(status)) : false;
      this.disconnectOrderStatus.equipmentReturn.status =
        equipmentReturn.length > 0 ? equipmentReturn.every((status) => this.completeStatuses.includes(status)) : false;
      this.disconnectOrderStatus.complete.status = this.disconnectOrderStatus.equipmentReturn.status;
    } else {
      // TODO: get all phases through selectors
      const preparingOrderTasksStatus = tasks
        .filter((t) => t.Phase_Name__c === 'Preparing Order')
        .map((t) => t.Status__c);

      const surveyDeliveryTasksStatus = tasks
        .filter((t) => t.Phase_Name__c === 'Survey and Delivery')
        .map((t) => t.Status__c);

      const serviceProvisioningTasksStatus = tasks
        .filter((t) => t.Phase_Name__c === 'Service Provisioning')
        .map((t) => t.Status__c);

      const completeTasksStatus = tasks.filter((t) => t.Phase_Name__c === 'Complete').map((t) => t.Status__c);

      this.orderStatus.preparingOrder.status =
        preparingOrderTasksStatus.length > 0
          ? preparingOrderTasksStatus.every((status) => this.completeStatuses.includes(status))
          : false;
      this.orderStatus.surveyDelivery.status =
        surveyDeliveryTasksStatus.length > 0
          ? surveyDeliveryTasksStatus.every((status) => this.completeStatuses.includes(status))
          : false;
      this.orderStatus.serviceProvisioning.status =
        serviceProvisioningTasksStatus.length > 0
          ? serviceProvisioningTasksStatus.every((status) => this.completeStatuses.includes(status))
          : false;
      this.orderStatus.complete.status =
        completeTasksStatus.length > 0
          ? completeTasksStatus.every((status) => this.completeStatuses.includes(status))
          : false;
    }
  }
}
