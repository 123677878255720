import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'src/app/services/api.service';
import { Payment } from '../models/payment.model';
import { Invoice } from '../models/invoice.model';
import { api } from '../models/api-url.model';

@Injectable()
export class BillingService {
  constructor(private apiService: ApiService) {}

  getPayments(): Observable<Payment[]> {
    return this.apiService.get(api.paymentsURL);
  }

  getInvoices(): Observable<Invoice[]> {
    return this.apiService.get(api.invoicesURL);
  }
}
