<section>
  <h1>Create <span class="emphasis">NOC</span> Ticket</h1>
  <form
    [formGroup]="nocFormGroup"
    *ngIf="nocFormGroup"
    [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
    autocomplete="off"
  >
    <h2>General Information</h2>
    <app-primary-reporter [formRef]="nocFormGroup" [reporters]="primaryReporters" (usersLoaded)="usersLoaded()">
    </app-primary-reporter>

    <app-service-location
      [formRef]="nocFormGroup"
      [services]="services"
      [selectedServiceId]="selectedServiceId"
      [inlineErrors]="inlineErrors"
      [validateForNoc]="true"
      (servicesLoaded)="servicesLoaded()"
    >
    </app-service-location>

    <h2>Issue Summary</h2>
    <div class="question">What service area do you need support with?</div>
    <mat-form-field floatLabel="never">
      <mat-label>Please select</mat-label>
      <mat-select formControlName="serviceArea" (selectionChange)="changeServiceArea()">
        <mat-option value="My Service Is Down">My Service Is Down</mat-option>
        <mat-option value="My Service is Degraded or Intermittent">My Service is Degraded or Intermittent</mat-option>
        <mat-option value="I would like to make a change to my existing service"
          >I would like to make a change to my existing service</mat-option
        >
        <mat-option value="I have a question or request for my service"
          >I have a question or request for my service</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div class="form-error" *ngIf="inlineErrors.serviceArea">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.serviceArea }}</div>
    </div>

    <div
      *ngIf="
        nocFormGroup.value.serviceArea === 'My Service Is Down' ||
        nocFormGroup.value.serviceArea === 'My Service is Degraded or Intermittent'
      "
    >
      <div class="question">Has power to the equipment been verified?</div>
      <mat-form-field floatLabel="never">
        <mat-label>Please select</mat-label>
        <mat-select formControlName="isPowerOn" (selectionChange)="canSubmit()">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.isPowerOn">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.isPowerOn }}</div>
      </div>

      <div class="question">Can Nitel perform intrusive tests?</div>
      <mat-form-field floatLabel="never">
        <mat-label>Please select</mat-label>
        <mat-select formControlName="canPerformIntrusiveTests" (selectionChange)="changeintrusiveTesting()">
          <mat-option value="No">No</mat-option>
          <mat-option value="Yes (Any Time)">Yes (Any Time)</mat-option>
          <mat-option value="Yes (Custom)">Yes (Custom)</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.canPerformIntrusiveTests">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.canPerformIntrusiveTests }}</div>
      </div>
      <div *ngIf="nocFormGroup.value.canPerformIntrusiveTests === 'Yes (Custom)'">
        <div class="question">From:</div>
        <mat-form-field floatLabel="never">
          <input matInput type="time" formControlName="testHoursStart" (selectionChange)="canSubmit()" />
        </mat-form-field>
        <div class="question">
          <div class="form-error" *ngIf="inlineErrors.testHoursStart">
            <div class="custom-icon inline_error"></div>
            <div class="error-message">{{ inlineErrors.testHoursStart }}</div>
          </div>
          To:
        </div>
        <mat-form-field floatLabel="never">
          <input matInput type="time" formControlName="testHoursEnd" (selectionChange)="canSubmit()" />
        </mat-form-field>
        <div class="form-error" *ngIf="inlineErrors.testHoursEnd">
          <div class="custom-icon inline_error"></div>
          <div class="error-message">{{ inlineErrors.testHoursEnd }}</div>
        </div>

        <mat-form-field floatLabel="never">
          <mat-label>Select Days</mat-label>
          <mat-select formControlName="testDays" multiple (selectionChange)="canSubmit()">
            <mat-option value="Sunday">Sunday</mat-option>
            <mat-option value="Monday">Monday</mat-option>
            <mat-option value="Tuesday">Tuesday</mat-option>
            <mat-option value="Wednesday">Wednesday</mat-option>
            <mat-option value="Thursday">Thursday</mat-option>
            <mat-option value="Friday">Friday</mat-option>
            <mat-option value="Saturday">Saturday</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="form-error" *ngIf="inlineErrors.canPerformIntrusiveTests">
          <div class="custom-icon inline_error"></div>
          <div class="error-message">{{ inlineErrors.canPerformIntrusiveTests }}</div>
        </div>
      </div>

      <div class="question">Site access hours</div>
      <mat-form-field floatLabel="never">
        <input matInput type="text" formControlName="accessHours" (selectionChange)="canSubmit()" />
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.accessHours">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.accessHours }}</div>
      </div>
      <div class="question">Site Contact First Name</div>
      <mat-form-field floatLabel="never">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="siteLocalFirst" (selectionChange)="canSubmit()" />
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.siteLocalFirst">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.siteLocalFirst }}</div>
      </div>
      <div class="question">Site Contact Last Name</div>
      <mat-form-field floatLabel="never">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="siteLocalLast" (selectionChange)="canSubmit()" />
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.siteLocalLast">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.siteLocalLast }}</div>
      </div>
      <div class="question">Site Contact Email</div>
      <mat-form-field floatLabel="never">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="siteLocalEmail" (selectionChange)="canSubmit()" />
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.siteLocalEmail">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.siteLocalEmail }}</div>
      </div>
      <div class="question">Site Contact Phone</div>
      <mat-form-field floatLabel="never">
        <mat-label>Phone</mat-label>
        <input matInput type="phone" formControlName="siteLocalPhone" (selectionChange)="canSubmit()" />
      </mat-form-field>
      <div class="form-error" *ngIf="inlineErrors.siteLocalPhone">
        <div class="custom-icon inline_error"></div>
        <div class="error-message">{{ inlineErrors.siteLocalPhone }}</div>
      </div>
    </div>
    <div class="question">Please describe the issue(s) you're experiencing.</div>
    <mat-form-field class="custom-textarea" floatLabel="never">
      <mat-label>Tell us about the issue...</mat-label>
      <textarea matInput formControlName="description" rows="5"></textarea>
    </mat-form-field>
    <div class="question">Customer Ticket Number</div>
    <mat-form-field floatLabel="never">
      <input matInput type="text" formControlName="customerTicketNumber" />
    </mat-form-field>
    <div class="form-error" *ngIf="inlineErrors.description">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ inlineErrors.description }}</div>
    </div>

    <div class="form-error" *ngIf="submissionError && !errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message"><b>Error: </b> Unable to submit the ticket. Please try again!</div>
    </div>
    <div class="server-error" *ngIf="submissionError && errorMessage">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ errorMessage }}</div>
    </div>

    <div class="loading-indicator" *ngIf="isSubmitting">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <button
      class="button submit-button"
      (click)="submitTicket()"
      [disabled]="loadingServices || loadingUsers || isSubmitting"
    >
      <span>Submit ticket</span>
    </button>
  </form>
</section>
