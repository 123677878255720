import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import GraphDataItem from '../graph-data.interface';

@Component({
  selector: 'app-circuit-milestones',
  templateUrl: './circuit-milestones.component.html',
  styleUrls: ['../widgets.scss', './circuit-milestones.component.scss']
})
export class CircuitMilestonesComponent implements OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input() data: GraphDataItem[] = [];
  @Input() loading: boolean = true;
  @Output() navigate: EventEmitter<string> = new EventEmitter();

  public barChartType: ChartType = 'bar' as ChartType;
  public barChartPlugins = [DataLabelsPlugin];
  public barChartLinks = [];
  public total: number = 0;

  constructor() {}

  public barChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 40,
        bottom: 20
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
          weight: 'bolder',
          family: '"open-sans", sans-serif'
        }
      }
    }
  };

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        barPercentage: 0.6,
        borderWidth: 1,
        hoverBorderWidth: 1
      }
    ]
  };

  ngOnChanges(change): void {
    if (this.data.length > 0) {
      this.setData();
    }
  }

  // public chartClicked({ event, active }): void {
  //   if (!active?.length && !active[0]?.index) return;

  //   let link: string = this.barChartLinks[active[0]?.index];
  //   if (!link) return;

  //   this.loading = true;
  //   this.navigate.emit(link);
  // }

  private setData(): void {
    this.total = 0;
    const colorData = [];
    this.barChartData.labels = [];
    this.barChartData.datasets[0].data = [];
    this.barChartData.datasets[0].backgroundColor = [];

    this.data.map((data) => {
      this.total += data.value;

      this.barChartData.labels.push(data.label);
      this.barChartData.datasets[0].data.push(data.value);
      colorData.push(data.color);
      this.barChartLinks.push(data.link);
    });
    this.barChartData.datasets[0].backgroundColor = colorData;
    this.barChartData.datasets[0].borderColor = colorData;
    this.barChartData.datasets[0].hoverBackgroundColor = colorData;
    this.barChartData.datasets[0].hoverBorderColor = colorData;
    this.chart?.update();
  }
}
