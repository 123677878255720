<div class="container-top">
  <div class="flex-item">
    <div class="container-title">
      <h1>Orders List</h1>
    </div>
  </div>
  <div class="flex-item filters">
    <div class="mobile-actions" [hidden]="!showFilterBt">
      <mat-icon (click)="showFilterBt = false">filter_alt</mat-icon>
    </div>
    <div class="container-selects" [hidden]="showFilterBt">
      <mat-form-field *ngIf="enumTypes.length > 1">
        <mat-select
          multiple
          placeholder="Service"
          panelClass="no-borders"
          (selectionChange)="filterData()"
          [(ngModel)]="selectedType"
        >
          <mat-select-trigger>Service ({{ selectedType.length }} applied)</mat-select-trigger>
          <mat-option class="no-box" (click)="clearServices()">Show All Services</mat-option>
          <mat-option *ngFor="let i of enumTypes" [value]="i">{{ i }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="enumStatus.length > 0">
        <mat-select
          multiple
          placeholder="Status"
          panelClass="big-panel"
          (selectionChange)="onStatusChange($event)"
          [(ngModel)]="selectedStatus"
        >
          <mat-select-trigger>Status ({{ selectedStatusCount() }} applied)</mat-select-trigger>
          <div *ngFor="let i of enumStatus">
            <mat-option *ngIf="i.action" [value]="i" [ngClass]="i.style" (click)="i.action()">{{ i.label }}</mat-option>
            <mat-option *ngIf="!i.action" [value]="i" [ngClass]="i.style">{{ i.label }}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="enumAddress.length > 0">
        <mat-select
          multiple
          placeholder="Address"
          panelClass="no-borders"
          (selectionChange)="filterData()"
          [(ngModel)]="selectedAddress"
        >
          <mat-select-trigger>Address ({{ selectedAddress.length }} applied)</mat-select-trigger>
          <mat-option class="no-box" (click)="clearAddress()">Clear all</mat-option>
          <mat-option *ngFor="let i of enumAddress" [value]="i">{{ i }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="container-table">
  <app-table-orders
    #orderstable
    [data]="data"
    [loading]="loading"
    [filterService]="selectedType"
    [filterStatus]="selectedStatus"
    [filterAddress]="selectedAddress"
    (filterRemove)="removeFromFilter($event)"
    (selectOrder)="sendOrder($event)"
  ></app-table-orders>
</div>
