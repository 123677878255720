import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from 'src/app/models/api-url.model';
import { ApiService } from './api.service';

@Injectable()
export class CommentService {
  constructor(private apiService: ApiService) {}

  createComment(payload): Observable<any> {
    const url = `${api.ticketsURL}/${payload.Case__c}${api.commentsURL}`;
    return this.apiService.post(url, payload);
  }

  updateCommentsByTicket(payload): Observable<any> {
    const url = `${api.ticketsURL}/${payload.Case__c}${api.commentsURL}`;
    return this.apiService.put(url);
  }
}
