<section class = "Service-section">
  <div class="icon-text header">
    <div><i class="material-icons">directions_run</i></div>
    <h1>Services</h1>
  </div>
  <div class="service_content">
    <div class="loading-indicator" *ngIf="isLoading$ | async">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div *ngIf="!(isLoading$ | async)">
      <div class="service_count">
          <div class="number">{{ (totalOpenServices$ | async).length }}</div>
          <div class="description">
            <div class="service_status upper">Active</div>
            <div class="service_label">Service{{ (totalOpenServices$ | async).length > 1 ? 's' : '' }}</div>
          </div>
      </div>
      <div class="service_icon">
        <div class="total-locations">Spanning {{ totalLocations$ | async }} locations</div>
    </div>
  </div>
  </div>
  <div class="widget_cta"><a routerLink="/services">View Services</a></div>
  <a routerLink="/services" tabindex="-1" class="dashboard-link desktop"><div class="cta-link">desktop</div></a>
</section>
