import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  templateUrl: './impersonation.component.html',
  styleUrls: ['../login/login.component.scss', '../reset-password/reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonationComponent implements OnInit, OnDestroy {
  title = 'MyNitel: Impersonate';
  token: string;
  errorMessage: string = null;
  hasError = false;
  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authService.clearCache();
    this.token = this.route.snapshot.paramMap.get('token');
    this.impersonate(this.token);
  }

  impersonate(token: string) {
    if (token) {
      this.subscription = this.authService.impersonate(token).subscribe(
        () => {
          this.router.navigate(['/dashboard']);
        },
        (error: HttpErrorResponse) => this.errorHandler(error)
      );
    } else {
      this.hasError = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  errorHandler(error: HttpErrorResponse) {
    this.errorMessage = error.message;
    this.hasError = true;
    this.changeDetectorRef.detectChanges();
  }
}
