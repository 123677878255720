import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from 'src/app/store/';

@Component({
  selector: 'app-widget-tickets',
  templateUrl: './widget-tickets.component.html',
  styleUrls: ['../widgets.scss', './widget-tickets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTicketsComponent implements OnInit {
  totalOpenTickets$: Observable<number>;
  isLoading$: Observable<boolean>;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.isLoading$ =  this.store.select(fromStore.getTicketsLoading);
    this.store.dispatch(new fromStore.LoadTotalOpenTickets());
    this.totalOpenTickets$ = this.store.select(fromStore.getTotalOpenTickets);
  }
}
