import { Component, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'app-ticket-success',
  templateUrl: './ticket-success.component.html',
  styleUrls: ['./ticket-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketSuccessComponent {
  @Input() order: Order = null;
  @Output() closed = new EventEmitter<boolean>();

  onClosed() {
    this.closed.emit(true);
  }
}
