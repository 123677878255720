import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import moment from 'moment';

import * as fromStore from 'src/app/store/';
import { DisplayOrder, Order, OrderDetailViews as views } from 'src/app/models/order.model';
import { UntypedFormControl } from '@angular/forms';
import { CSVFormatterService } from 'src/app/services/csv-formatter.service';

@Component({
  selector: 'orders-shell',
  styleUrls: ['orders-shell.component.scss'],
  templateUrl: 'orders-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersShellComponent implements OnInit, OnDestroy, AfterViewInit {
  orders$: Observable<Order[]>;
  isLoading$: Observable<boolean> = this.store.select(fromStore.getOrdersLoading);
  isLoaded$: Observable<boolean> = this.store.select(fromStore.getOrdersLoaded);

  detailView$: Observable<string> = this.store.select(fromStore.getOrderDetailView);
  selectedOrderId$: Observable<string> = this.store.select(fromStore.getSelectedOrderId);
  selectedLineItemId$: Observable<string> = this.store.select(fromStore.getSelectedLineItemId);

  subscription: Subscription;
  ordersSubscription: Subscription;
  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);

  browserSize: string;
  viewsObject = views;
  formattedCSVData: any = [];
  csvFileName = 'Orders-Status';

  searchTerm: any;
  searchTermControl = new UntypedFormControl();
  formCtrlSub: Subscription;

  page: number = 1;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private csvFormatter: CSVFormatterService
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadOrders());
    this.store.dispatch(new fromStore.LoadServiceOrderForms());
    this.init();

    // RXJS for search input
    this.formCtrlSub = this.searchTermControl.valueChanges
      .pipe(debounceTime(300))
      // eslint-disable-next-line no-return-assign
      .subscribe((newValue) => {
        this.searchTerm = newValue;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.formatCSVData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
    if (this.formCtrlSub) {
      this.formCtrlSub.unsubscribe();
    }
  }

  onWindowResize() {
    this.init();
  }

  init() {
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.orders$ = this.store.select(fromStore.getAllOrders).pipe(
      tap((orders: Order[]) => {
        if (orders.length > 0) {
          const initialOrderDetail: DisplayOrder = {
            detailView: views.order,
            orderId: orders ? orders[0].Id : null
          };
          this.store.dispatch(new fromStore.DisplayOrderDetail(initialOrderDetail));
          this.store.dispatch(new fromStore.SetSelectedSOF(initialOrderDetail.orderId));
        }
      })
    );
  }

  onSelect(event: DisplayOrder) {
    this.store.dispatch(new fromStore.DisplayOrderDetail(event));
    this.store.dispatch(new fromStore.SetSelectedSOF(event.orderId));
    this.changeDetectorRef.detectChanges();
  }

  formatCSVData() {
    this.ordersSubscription = this.orders$.subscribe((orders) => {
      const newCSVData = [];

      orders.forEach((order) => {
        const dealOwner = order.Owner_Name__c;

        if (order.Customer_Order_OLI__r) {
          // eslint-disable-next-line prettier/prettier
          (order.Customer_Order_OLI__r.records).forEach(lineItem => {
            let orderStatus = 'N/A';
            let completedDate = 'N/A';
            let accountName = '';
            let elementInfo: any = {};
            let lastComment = '';
            let lastCommentDate = null;
            let sordOwner = '';

            if (lineItem.Account__c) {
              accountName = lineItem?.Account__r?.Name;
            } else if (order.Account__c) {
              accountName = order.Account__r?.Name;
            }

            if (lineItem.Service_Order2__r) {
              orderStatus =
                lineItem.Service_Order2__r.Status__c === 'Ready' ? 'Pending' : lineItem.Service_Order2__r.Status__c;
              completedDate = lineItem.Service_Order2__r.Completed_Date__c
                ? moment(lineItem.Service_Order2__r.Completed_Date__c).format('L')
                : '';

              sordOwner = lineItem.Service_Order2__r.Owner_Name__c || '';

              lastComment = (
                lineItem.Service_Order2__r &&
                lineItem.Service_Order2__r.Order_Comments__r &&
                lineItem.Service_Order2__r.Order_Comments__r.length > 0 &&
                lineItem.Service_Order2__r.Order_Comments__r[0]
                  ? lineItem.Service_Order2__r.Order_Comments__r[0].Body__c
                  : ''
              ).replace(/<[^>]*>?/gm, '');

              lastCommentDate =
                lineItem.Service_Order2__r &&
                lineItem.Service_Order2__r.Order_Comments__r &&
                lineItem.Service_Order2__r.Order_Comments__r.length > 0 &&
                lineItem.Service_Order2__r.Order_Comments__r[0]
                  ? moment(lineItem.Service_Order2__r.Order_Comments__r[0].CreatedDate).format('L')
                  : null;

              if (lineItem.Service__r) {
                elementInfo = this.csvFormatter.getElementInfo(lineItem.Service__r);
              }
            }
            const locationInfo = {
              streetAddress: '',
              city: '',
              state: '',
              zip: ''
            };
            if (lineItem.Location__r !== null) {
              locationInfo.streetAddress = `${
                lineItem.Location__r.Street_1__c === null ? '' : lineItem.Location__r.Street_1__c
              } ${lineItem.Location__r.Street_2__c === null ? '' : lineItem.Location__r.Street_2__c}`;
              locationInfo.city = lineItem.Location__r.City__c;
              locationInfo.state = lineItem.Location__r.State__c;
              locationInfo.zip = lineItem.Location__r.Zip_Code__c;
            }

            const object = {
              NIT: lineItem.Service__r ? lineItem.Service__r.Name : '',
              'Service Status': lineItem.Service__r ? lineItem.Service__r?.Status__c : '',
              'Customer Site ID': lineItem.Service__r ? lineItem.Service__r.Z_Location_Customer_Side_Id__c : '',
              'Customer PON':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Customer_PON__c
                  ? lineItem.Service_Order2__r.Customer_PON__c
                  : '',
              'Deal Number': order.Name ? order.Name : '',
              'SORD Number':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Name ? lineItem.Service_Order2__r.Name : '',
              'Deal Status': order?.Status__c ? order?.Status__c : '',
              Customer: accountName,
              'End User Company': lineItem?.Service__r?.Z_Location_Company_Name__c,
              'Street Address': locationInfo.streetAddress,
              City: locationInfo.city,
              State: locationInfo.state,
              ZIP: locationInfo.zip,
              Provider: lineItem?.Service__r?.Vendor__r?.Display_Alias__c
                ? lineItem?.Service__r?.Vendor__r?.Display_Alias__c
                : ( lineItem?.Service__r?.Vendor__r?.Name
                    ? lineItem?.Service__r?.Vendor__r?.Name
                    : ''
                  ),
              'Local Contact': lineItem.LocalContact,
              'Order Type': lineItem.RecordType.Name ? lineItem.RecordType.Name : '',
              'Order Status': orderStatus,
              MRC: lineItem?.Service__r?.MRR__c ? `$${lineItem.Service__r.MRR__c}` : '$0.00',
              'Sales Representative': order.Sales_Rep__r
                ? `${order.Sales_Rep__r.LastName}, ${order.Sales_Rep__r.FirstName}`
                : '',
              'Account Manager': order.Account_Manager__r
                ? `${order.Account_Manager__r.LastName}, ${order.Account_Manager__r.FirstName}`
                : '',
              'Client Project Manager': order.Default_Task_Assignments__r
                ? `${order.Default_Task_Assignments__r.records[0].User__r.LastName}, ${order.Default_Task_Assignments__r.records[0].User__r.FirstName}`
                : '',
              'Deal Owner': dealOwner,
              'Order Owner': sordOwner,
              'Bundle Type': lineItem.Service_Type__c ? lineItem.Service_Type__c : '',
              Term: lineItem.Term__c,
              'Loop CIR': lineItem?.Service__r?.Asymmetrical_Bandwidth__c
                ? `${lineItem?.Service__r?.Bandwidth_Downstream__c} / ${lineItem.Service__r.Bandwidth_Upstream__c}`
                : lineItem.Service__r?.Bandwidth_Downstream__c,
              'Circuit Hand-Off': lineItem?.Service__r?.Connector_Type_Z_Location__c,
              'Provider Circuit ID': elementInfo.vendorCircuitId ? elementInfo.vendorCircuitId : '',
              'WAN IP Block': elementInfo.wanIpBlock ? elementInfo.wanIpBlock : '',
              'WAN Mask': elementInfo.wanMask ? elementInfo.wanMask : '',
              'LAN IP Block': elementInfo.lanIpBlock ? elementInfo.lanIpBlock : '',
              'LAN Mask': elementInfo.lanMask ? elementInfo.lanMask : '',
              Dmarc: elementInfo.dmarcZLoc ? elementInfo.dmarcZLoc : '',
              'Nitel Equipment': elementInfo.equipmentName ? elementInfo.equipmentName : '',
              'Tracking Number':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Equipment_Shipment_Tracking_Number__c
                  ? lineItem.Service_Order2__r.Equipment_Shipment_Tracking_Number__c
                  : '',
              'Shipping Carrier':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Shipping_Carrier__c
                  ? lineItem.Service_Order2__r.Shipping_Carrier__c
                  : '',
              'Order Date': order.Order_Date__c ? order.Order_Date__c : '',
              'Provider Firm Order Confirmation Date':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Vendor_FOC_Date__c
                  ? lineItem.Service_Order2__r.Vendor_FOC_Date__c
                  : '',
              'Nitel Firm Order Confirmation Date':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.Nitel_FOC_Date__c
                  ? lineItem.Service_Order2__r.Nitel_FOC_Date__c
                  : '',
              'Order Completed Date': completedDate,
              'Start Of Service Date': lineItem?.Service__r?.Start_Of_Service__c
                ? moment(lineItem.Service__r.Start_Of_Service__c).format('L')
                : '',
              'Scheduled Activation Date': lineItem.ScheduledActivationDate
                ? moment(lineItem.ScheduledActivationDate).format('L')
                : '',
              'Activation Completed Date': lineItem?.Service__r?.Activation_Date__c
                ? moment(lineItem.Service__r.Activation_Date__c).format('L')
                : '',
              'Billing Initiated Date': lineItem?.Service__r?.First_Invoice__c
                ? moment(lineItem.Service__r.First_Invoice__c).format('L')
                : '',
              'End Of Service Date': lineItem?.Service__r?.End_Of_Service__c
                ? moment(lineItem.Service__r.End_Of_Service__c).format('L')
                : '',
              'Jeopardy Code':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.activeJeopardy
                  ? lineItem.Service_Order2__r.activeJeopardy.Jeopardy_Code__c
                  : '',
              'Jeopardy Reason':
                lineItem.Service_Order2__r && lineItem.Service_Order2__r.activeJeopardy
                  ? lineItem.Service_Order2__r.activeJeopardy.Jeopardy_Reason__c
                  : '',
              'Is Staggered': lineItem.Service_Order2__r
                ? lineItem.Service_Order2__r.In_Staggered_Order_Jeopardy__c
                : false,
              'Most Recent Comment': lastComment,
              'Most Recent Comment Date': lastCommentDate
            };
            newCSVData.push(object);
          });

          this.changeDetectorRef.detectChanges();
        }
      });

      this.formattedCSVData = newCSVData;
    });
  }
}
