import { Action } from '@ngrx/store';
import { DisplayOrder, Order } from 'src/app/models/order.model';

// load orders
export const LOAD_ORDERS = '[Orders] Load Orders';
export const LOAD_ORDERS_FAIL = '[Orders] Load Orders Fail';
export const LOAD_ORDERS_SUCCESS = '[Orders] Load Orders Success';
export const LOAD_ORDERSV2 = '[Orders] Load Orders V2';
export const LOAD_ORDERSV2_FAIL = '[Orders] Load Orders V2 Fail';
export const LOAD_ORDERSV2_SUCCESS = '[Orders] Load Orders V2 Success';
export const CLEAR_ORDERS = '[Orders] Clear Orders';
export const DISPLAY_ORDER_DETAIL = '[Orders] Display Order Detail';

export class LoadOrders implements Action {
  readonly type = LOAD_ORDERS;
}

export class LoadOrdersFail implements Action {
  readonly type = LOAD_ORDERS_FAIL;
  constructor(public payload: any) {}
}
export const LOAD_TOTAL_OPEN_ORDERS = '[Orders] Load Total Open Orders';
export const LOAD_TOTAL_OPEN_ORDERS_FAIL = '[Orders] Load Total Open Orders Fail';
export const LOAD_TOTAL_OPEN_ORDERS_SUCCESS = '[Orders] Load Total Open Orders Success';

export class LoadTotalOpenOrders implements Action {
  readonly type = LOAD_TOTAL_OPEN_ORDERS;
}

export class LoadTotalOpenOrdersSuccess implements Action {
  readonly type = LOAD_TOTAL_OPEN_ORDERS_SUCCESS;
  constructor(public payload: number) {}
}

export class LoadTotalOpenOrdersFail implements Action {
  readonly type = LOAD_TOTAL_OPEN_ORDERS_FAIL;
  constructor(public payload: any) {}
}

export class LoadOrdersSuccess implements Action {
  readonly type = LOAD_ORDERS_SUCCESS;
  constructor(public payload: Order[]) {}
}

export class LoadOrdersV2 implements Action {
  readonly type = LOAD_ORDERSV2;
}

export class LoadOrdersV2Fail implements Action {
  readonly type = LOAD_ORDERSV2_FAIL;
  constructor(public payload: any) {}
}

export class LoadOrdersV2Success implements Action {
  readonly type = LOAD_ORDERSV2_SUCCESS;
  constructor(public payload: any[]) {}
}

export class ClearOrders implements Action {
  readonly type = CLEAR_ORDERS;
}

export class DisplayOrderDetail implements Action {
  readonly type = DISPLAY_ORDER_DETAIL;
  constructor(public payload: DisplayOrder) {}
}

// set current order
export const SET_CURRENT_ORDER = '[Orders] Set Current Order';
export const CLEAR_CURRENT_ORDER = '[Orders] Clear Current Order';

export class SetCurrentOrder implements Action {
  readonly type = SET_CURRENT_ORDER;
  constructor(public payload: string) {}
}

export class ClearCurrentOrder implements Action {
  readonly type = CLEAR_CURRENT_ORDER;
}

// set current line item
export const SET_CURRENT_LINE_ITEM = '[Orders] Set Current Line Item';
export const CLEAR_CURRENT_LINE_ITEM = '[Orders] Clear Current Line Item';

export class SetCurrentLineItem implements Action {
  readonly type = SET_CURRENT_LINE_ITEM;
  constructor(public payload: string) {}
}

export class ClearCurrentLineItem implements Action {
  readonly type = CLEAR_CURRENT_LINE_ITEM;
}

// action types
export type OrdersAction =
  | LoadOrders
  | LoadOrdersFail
  | LoadOrdersSuccess
  | LoadOrdersV2
  | LoadOrdersV2Success
  | LoadOrdersV2Fail
  | ClearOrders
  | DisplayOrderDetail
  | SetCurrentOrder
  | ClearCurrentOrder
  | SetCurrentLineItem
  | LoadTotalOpenOrdersSuccess
  | LoadTotalOpenOrders
  | LoadTotalOpenOrdersFail
  | ClearCurrentLineItem;
