/* eslint-disable guard-for-in */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';

import * as fromStore from 'src/app/store/';
import { Service } from 'src/app/models/service.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-inventory-page',
  templateUrl: './inventory-page.component.html',
  styleUrls: ['./inventory-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryPageComponent implements OnInit {
  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  isLoading$: Observable<boolean> = this.store.select(fromStore.getServicesLoading);
  isLoaded$: Observable<boolean> = this.store.select(fromStore.getServicesLoading);
  services$: Observable<Service[]> = this.store.select(fromStore.getAllServices);
  activeServices$: Observable<Service[]> = this.store.select(fromStore.getActiveServices);

  // Filters - Locations/Services/Status
  locationsFilterOptions$: Observable<string[]> = this.store.select(fromStore.getLocationsFilterOptions);
  locationsFilterFormControl: UntypedFormControl = new UntypedFormControl();
  private locationFilterValues$ = this.locationsFilterFormControl.valueChanges.pipe(
    startWith(''),
    map((l) => l)
  );

  serviceFilterOptions$: Observable<string[]> = this.store.select(fromStore.getServiceFilterOptions);
  serviceFilterFormControl: UntypedFormControl = new UntypedFormControl();
  private serviceFilterValues$ = this.serviceFilterFormControl.valueChanges.pipe(
    startWith(''),
    map((s) => s)
  );

  statusFilterOptions$: Observable<string[]> = this.store.select(fromStore.getStatusFilterOptions);
  statusFilterFormControl: UntypedFormControl = new UntypedFormControl();
  private statusFilterValues$ = this.statusFilterFormControl.valueChanges.pipe(
    startWith(''),
    map((s) => s)
  );

  // Selected Service
  private selectedServiceSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedServiceAction$ = this.selectedServiceSubject.asObservable();

  // Group By Selection
  groupByFormControl: UntypedFormControl = new UntypedFormControl('Z_Location__r.Name');
  private groupByValue$ = this.groupByFormControl.valueChanges.pipe(
    startWith('Z_Location__r.Name'),
    map((l) => {
      // console.log("groupByValue$: " + l)
      return l;
    })
  );

  // Is ASC Selection
  isAscFormControl: UntypedFormControl = new UntypedFormControl(true);
  private isAscValue$ = this.isAscFormControl.valueChanges.pipe(
    startWith(true),
    map((l) => l)
  );

  // Observable Loading when services are being grouped
  isServicesInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isServicesInProgress$ = this.isServicesInProgressSubject.asObservable();

  // Total # of filters selected
  totalSelectedFilters$: Observable<number> = combineLatest([
    this.locationFilterValues$,
    this.serviceFilterValues$,
    this.statusFilterValues$
  ]).pipe(
    map(([locations, services, status]) => {
      const totalFiltersSelected = Number(locations.length) + Number(services.length) + Number(status.length);
      return totalFiltersSelected;
    })
  );

  // Filters services based on user's selections
  servicesFiltered$: Observable<Service[]> = combineLatest([
    this.services$,
    this.locationFilterValues$,
    this.serviceFilterValues$,
    this.statusFilterValues$
  ]).pipe(
    distinctUntilChanged(),
    tap(() => this.isServicesInProgressSubject.next(true)),
    debounceTime(1000), // only emit when the current value is different than the last
    map(([services, locationsFilter, servicesFilter, statusFilter]) => {
      // this.isServicesInProgressSubject.next(true);
      let filteredServices = services;

      // Filter by locations
      if (locationsFilter.length > 0) {
        const locationsFilterHash = {};
        locationsFilter.forEach((element: string) => {
          if (!locationsFilterHash[element]) {
            locationsFilterHash[element] = element;
          }
        });

        filteredServices = filteredServices.filter((s) => locationsFilterHash[s.Z_Location__r.Name]);
      }

      // Filter by services product
      if (servicesFilter.length > 0) {
        const servicesFilterHash = {};
        servicesFilter.forEach((element: string) => {
          if (!servicesFilterHash[element]) {
            servicesFilterHash[element] = element;
          }
        });

        filteredServices = filteredServices.filter((s) => servicesFilterHash[s.RecordType.Name]);
      }

      // Filter by status
      if (statusFilter.length > 0) {
        const statusFilterHash = {};
        statusFilter.forEach((element: string) => {
          if (!statusFilterHash[element]) {
            statusFilterHash[element] = element === 'Pending' ? 'New' : element;
          }
        });

        filteredServices = filteredServices.filter((s) => statusFilterHash[s.Status__c]);
      }

      return filteredServices;
    })
  );

  // Groups services based on 'group by' selection & sorted
  groupFilteredServices$: Observable<any> = combineLatest([
    this.servicesFiltered$,
    this.groupByValue$,
    this.isAscValue$
  ]).pipe(
    distinctUntilChanged(),
    tap(() => this.isServicesInProgressSubject.next(true)),
    map(([services, groupByKey, isAsc]) => {
      // this.isServicesInProgressSubject.next(true);
      const servicesMap = {};

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < services.length; i++) {
        if (groupByKey.includes('.')) {
          const splitKey = groupByKey.split('.');
          if (servicesMap[services[i][splitKey[0]][splitKey[1]]]) {
            servicesMap[services[i][splitKey[0]][splitKey[1]]].totalMrc += services[i].MRR__c;
            servicesMap[services[i][splitKey[0]][splitKey[1]]].services.push(services[i]);
          } else {
            servicesMap[services[i][splitKey[0]][splitKey[1]]] = {
              groupTitle: services[i][splitKey[0]][splitKey[1]],
              totalMrc: 0 + services[i].MRR__c,
              services: [services[i]]
            };
          }
        } else {
          const groupTitle = services[i][groupByKey] === 'New' ? 'Pending' : services[i][groupByKey];

          if (servicesMap[groupTitle]) {
            servicesMap[groupTitle].totalMrc += services[i].MRR__c;
            servicesMap[groupTitle].services.push(services[i]);
          } else {
            servicesMap[groupTitle] = {
              groupTitle: services[i][groupByKey],
              totalMrc: 0 + services[i].MRR__c,
              services: [services[i]]
            };
          }
        }
      }
      // Sort services object in ASC
      const sortedServicesMap = {};
      const serviceKeys = Object.keys(servicesMap);
      serviceKeys
        .sort((a, b) => {
          return servicesMap[a].groupTitle < servicesMap[b].groupTitle
            ? -1
            : servicesMap[a].groupTitle > servicesMap[b].groupTitle
            ? 1
            : 0;
        })
        .forEach((k) => {
          sortedServicesMap[k] = servicesMap[k];
        });

      // Convert services object into array
      const servicesArray = [];
      Object.keys(sortedServicesMap).forEach((o) => {
        servicesArray.push(sortedServicesMap[o]);
      });

      if (!isAsc) {
        servicesArray.reverse();
      }

      return servicesArray;
    }),
    tap(() => this.isServicesInProgressSubject.next(false))
  );

  sortGroupedArray$: Observable<any> = combineLatest([this.groupFilteredServices$, this.isAscValue$]).pipe(
    distinctUntilChanged(),
    tap(() => this.isServicesInProgressSubject.next(true)),
    map(([services, isAsc]) => {
      if (!isAsc) {
        services.reverse();
      }
      return services;
    }),
    tap(() => this.isServicesInProgressSubject.next(false))
  );

  // Current/selected service
  selectedService$ = combineLatest([this.services$, this.selectedServiceAction$]).pipe(
    map(([services, selectedServiceId]) => {
      if (selectedServiceId !== null) {
        return services.find((s) => s.Id === selectedServiceId);
      }
    }),
    shareReplay(1)
  );

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadServices());
  }

  onSetSelectedService(service: string): void {
    this.selectedServiceSubject.next(service);
  }

  onLoadingIndicator(): void {
    this.isServicesInProgressSubject.next(true);
  }
}
