<section class="card">
  <div class="widget-container">
    <h3>Pending FOC Date ({{ total || '...' }})</h3>

    <div class="loading-spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="chart" *ngIf="!loading">
      <canvas
        baseChart
        id="pending-foc-chart"
        height="220px"
        [data]="barChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="barChartType">
      </canvas>
    </div>
  </div>
</section>
