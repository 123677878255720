import { Component, Input, OnInit } from '@angular/core';
import OrderInfoDetail from '../order-info.interface';

@Component({
  selector: 'app-order-details-box',
  templateUrl: './order-details-box.component.html',
  styleUrls: ['./order-details-box.component.scss']
})
export class OrderDetailsBoxComponent implements OnInit {

	@Input() title: string;
	@Input() data: OrderInfoDetail[];

	constructor() { }

	ngOnInit(): void {
	}

}
