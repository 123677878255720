import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform = (phoneValue: number | string, countryCode = 'US') => {
    try {
      const phoneNumber = parsePhoneNumber(`${phoneValue}`, countryCode as CountryCode);
      return phoneNumber.formatNational();
    } catch (error) {
      return phoneValue;
    }
  };
}
