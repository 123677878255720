import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

import OrderInfoDetail from '../order-info.interface';

@Component({
	selector: 'app-order-dates-box',
	templateUrl: './order-dates-box.component.html',
	styleUrls: ['./order-dates-box.component.scss']
})
export class OrderDatesBoxComponent implements OnInit {

	@Input() title: string;
	@Input() dates: OrderInfoDetail[];

	@Input() upcoming: OrderInfoDetail[];
	@Input() past: OrderInfoDetail[];

	public iconUpcoming = 'calendar_today';
	public iconPast = 'check';

	constructor() { }

	ngOnInit(): void {
		this.splitDates();
	}

	private splitDates() {
		this.upcoming = [];
		this.past = [];
		let today = moment();

		this.dates.map(d => {
			if(!d.value || d.value == '-') {
				this.upcoming.push(d);
				return;
			}
			if(today.isBefore(d.value)) {
				this.upcoming.push(d);
			} else {
				this.past.push(d);
			}
		});
	}

}
