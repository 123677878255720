import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import Holidays from 'date-holidays';
import moment from 'moment';

import { MappedDisconnectService } from 'src/app/models/service.model';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { ServiceService } from '../../../services/service.service';

@Component({
  selector: 'app-multiple-service-location',
  templateUrl: './multiple-service-location.component.html',
  styleUrls: [
    '../../../tickets/containers/create-ticket/create-ticket.component.scss',
    '../service-location/service-location.component.scss',
    './multiple-service-location.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleServiceLocationComponent implements OnInit {
  @Input() formRef: UntypedFormGroup = new UntypedFormGroup({});
  @Input() selectedServiceId: string;
  @Input() inlineErrors: any = {};
  @Output() servicesLoaded = new EventEmitter(false);

  services$: Observable<MappedDisconnectService[]>;
  serviceSubscription: Subscription;
  isLoadingServices = true;
  isIdentifyingByLocation = true;

  options: Observable<any>;
  filteredOptions: Observable<any[]>;
  allLocations = [];
  allServices = [];
  allTempLocations = [];
  servicesAtLocation: Observable<any[]>;
  servicesAtLocationArray = [];
  serviceObject = {};
  servicesAtChosenLocation = [];
  LOCATION = 'location';
  SERVICE = 'service';
  ticketType = 'disconnect';

  identifier = this.LOCATION;
  selectedServicesTotal = 0;
  chosenService = null;
  startCalendarAt = new Date();
  customCalHeader = CalendarHeaderComponent;
  holidays = [`New Year's Day`, `Memorial Day`, `Independence Day`, `Labor Day`, `Thanksgiving Day`, `Christmas Day`];
  myControl = new FormControl('');

  constructor(
    private serviceService: ServiceService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.startCalendarAt = this.getNextValidDate();
  }

  ngOnInit() {
    this.serviceService.getServicesInService().subscribe((data) => {
      if(data){
      this.servicesLoaded.emit(true);
      // Get all services with location
      // Some services have bad data & don't come with a location
      this.allServices = data.map((s) => {
        if (s.Z_Location__c) {
          return {
            bandwidth: s?.Bandwidth_Text__c,
            alias: s?.Vendor__r?.Display_Alias__c,
            id: s.Id,
            nit: s.Name,
            product: s.RecordType.Name,
            locationId: s.Z_Location__c,
            locationName: s.Z_Location__r.Name,
            disabled: false
          };
        }
      });

      if (this.allServices.length === 1) {
        this.formRef.get(`services.${0}.locationId`).setValue(`${this.allServices[0].locationId}`);
        this.formRef.get(`services.${0}.serviceId`).setValue(`${this.allServices[0].id}`);
        this.formRef.get(`services.${0}.locationAddress`).setValue(`${this.allServices[0].locationName}`);
      }


      // Filter out duplicate locations for location dropdown
      const m = new Map();
      // eslint-disable-next-line no-restricted-syntax
      for (const service of data) {
        this.serviceObject[service.Id] = service;
        // If service has location, create array.
        if (service.Z_Location__r && !m.has(service.Z_Location__c)) {
          m.set(service.Z_Location__c, true);
          service.disabled = false;
          this.allLocations.push(service);
        }
      }
      this.allLocations.sort((service1, service2) =>
        service1.Z_Location__r.Name < service2.Z_Location__r.Name
          ? -1
          : service1.Z_Location__r.Name > service2.Z_Location__r.Name
          ? 1
          : 0
      );

      if (this.selectedServiceId?.length) {
        const selectedService = this.allServices.find(service => service.id === this.selectedServiceId);
        this.formRef.get(`services.${0}.identifyIssueBy`).setValue(this.SERVICE);
        this.formRef.get(`services.${0}.locationId`).setValue(`${selectedService.locationId}`);
        this.formRef.get(`services.${0}.serviceId`).setValue(`${selectedService.id}`);
        this.formRef.get(`services.${0}.locationAddress`).setValue(`${selectedService.locationName}`);
      }
      this.isLoadingServices = false;
      this.canSubmit();

      this.changeDetectorRef.detectChanges();
    }
  else{
    this.servicesLoaded.emit(true);
    this.isLoadingServices = false
  }});
  }

  serviceSelected(service) {
    this.formRef.get(`services.${service.serviceIterator}.locationAddress`).setValue(`${service.locationAddress}`);
    this.formRef.get(`services.${service.serviceIterator}.serviceId`).setValue(`${service.serviceId}`);
    this.formRef.get(`services.${service.serviceIterator}.locationId`).setValue(service.locationId);
    this.updateServiceDisabledAttribute();
    this.changeDetectorRef.detectChanges();
  }

  onRadioChange(index: number) {
    // Reset or empty values in the selected service's dropdowns or inputs
    this.formRef.get(`services.${index}.locationId`).setValue(null);
    this.formRef.get(`services.${index}.serviceId`).setValue(null);
    this.formRef.get(`services.${index}.locationAddress`).setValue(null);
    this.selectedServiceId = null;

    // Empty the services array if location dropdown has been re-selected
    (<UntypedFormArray>this.formRef.get(`services.${index}.servicesByLocation`)).clear();
    this.updateServiceDisabledAttribute();

    this.formRef.controls.locationOfService.updateValueAndValidity();
    this.formRef.controls.services.updateValueAndValidity();
    this.canSubmit();
  }

  locationSelected(location: any) {
    // Get location's full address
    const locationAddress = this.allServices.filter((service) => {
      return service.locationId === location.Id ? `${service}` : '';
    });

    // Set location's full address to services formcontrol
    this.formRef
      .get(`services.${location.locationIterator}.locationAddress`)
      .setValue(`${locationAddress[0]?.locationName}`);
    this.formRef.get(`services.${location.locationIterator}.locationId`).setValue(location.Id);

    // Empty the array if dropdown has been re-selected
    (<UntypedFormArray>this.formRef.get(`services.${location.locationIterator}.servicesByLocation`)).clear();

    // Get services associated with location selected

    this.servicesAtLocationArray = this.allServices.filter((service) =>
      service.locationId ? service.locationId === location.Id : false
    );
    this.servicesAtLocationArray = this.servicesAtLocationArray;

    // Insert filtered services array in the service dropdown based on selected location
    const servicesByLocationArray = this.formRef.get(
      `services.${location.locationIterator}.servicesByLocation`
    ) as UntypedFormArray;
    this.servicesAtLocationArray.forEach((element, i) => {
      const newServiceGroup: UntypedFormGroup = this.fb.group({
        id: [`${element.id}`],
        nit: [`${element.nit}`],
        product: [`${element.product}`],
        locationId: [`${element.locationId}`],
        locationName: [`${element.locationName}`],
        disabled: [`${element.disabled}`]
      });
      servicesByLocationArray.insert(i, newServiceGroup);
    });

    // if location has only one service, the service will automatically appear as static in UI
    // sets the service id to the form for submission and sets the disabled attribute to true.
    if (servicesByLocationArray.length === 1) {
      // Set service id in order to update the disabled attribute upon selection
      this.formRef.get(`services.${location.locationIterator}.serviceId`).setValue(`${locationAddress[0].id}`);
      this.formRef.get(`services.${location.locationIterator}.servicesByLocation.0.disabled`).setValue(true);
    }

    this.updateServiceDisabledAttribute();

    this.changeDetectorRef.detectChanges();
  }

  onServiceSelected(serviceId: any, index: number) {
    if (serviceId !== null) {
      // Get location's full address
      const locationAddress = this.allServices.filter((service) => {
        return service.id === serviceId ? `${service}` : '';
      });

      // Set location's full address to services formcontrol
      this.formRef.get(`services.${index}.locationAddress`).setValue(`${locationAddress[0].locationName}`);
      // Set service id in order to update the disabled attribute upon selection
      this.formRef.get(`services.${index}.serviceId`).setValue(serviceId);
      this.formRef.get(`services.${index}.locationId`).setValue(locationAddress[0].locationId);

      this.updateServiceDisabledAttribute();
      this.changeDetectorRef.detectChanges();
    }
  }

  updateServiceDisabledAttribute() {
    const servicesSelected = [];

    const servicesForm = this.formRef.get(`services`).value as any;
    servicesForm.forEach((element: any) => {
      if (element.serviceId) {
        servicesSelected.push(element.serviceId);
      }
    });

    this.selectedServicesTotal = servicesSelected.length;

    this.allServices.forEach((service) => {
      service.disabled = false;
      servicesSelected.forEach((selected) => {
        if (service.id === selected) {
          service.disabled = true;
        }
      });
    });

    this.updateLocationDisabledAttribute();
  }

  updateLocationDisabledAttribute() {
    this.allLocations.forEach((location) => {
      location.disabled = false;

      // Get all services for the location
      const locationServices = this.allServices.filter((service) => service.locationId === location.Z_Location__c);

      // If all services are disabled, disable location attribute
      const allDisabled = locationServices.every((service) => {
        return service.disabled;
      });

      if (allDisabled) {
        location.disabled = true;
      }
    });
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.formRef.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.formRef.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.formRef.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        // this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
        if (!control.valid) {
          this.inlineErrors[prop] = 'This is a required field';
        } else {
          delete this.inlineErrors[prop];
        }
      }
    }
    return this.formRef.valid;
  }

  removeServicesFormControl(i) {
    // Remove a service dropdown to disconnect
    const servicesArray = this.formRef.controls.services as UntypedFormArray;
    servicesArray.removeAt(i);
    this.updateServiceDisabledAttribute();
  }

  addServicesFormControl() {
    // Add another service dropdown to disconnect
    const servicesArray = this.formRef.controls.services as UntypedFormArray;
    const arrayLength = servicesArray.length;

    const newServiceGroup: UntypedFormGroup = this.fb.group({
      identifyIssueBy: ['location'],
      serviceId: new UntypedFormControl(null, Validators.required),
      locationId: [null, Validators.required],
      locationAddress: [null],
      desiredDate: this.fb.control(null, [Validators.required, this.validateDisconnectDate.bind(this)]),
      servicesByLocation: this.fb.array([
        this.fb.group({
          id: this.fb.control(''),
          disabled: this.fb.control(''),
          nit: this.fb.control(''),
          product: this.fb.control(''),
          locationId: this.fb.control(''),
          locationName: this.fb.control('')
        })
      ])
    });
    servicesArray.insert(arrayLength, newServiceGroup);

    this.updateServiceDisabledAttribute();
  }

  validateDisconnectDate(c: UntypedFormControl) {
    const formDate = c.value ? new Date(c.value) : null;
    const isValid = formDate ? this.filterDates(formDate) : false;
    const errorOutput = {
      validateDate: {
        valid: false
      }
    };
    return isValid ? null : errorOutput;
  }

  filterDates = (d: Date): boolean => {
    if (d) {
      const day = d.getDay();
      // Prevent Saturday and Sunday from being selected.
      const weekday = day !== 0 && day !== 6;
      // A user cannot disconnect on holidays
      const holiday = this.isHoliday(d);
      // A user cannot disconnect until 30 days from current date.
      const thirtyDays = moment(new Date()).add(30, 'days');
      const afterThirtyDays = d > thirtyDays.toDate();

      return weekday && !holiday && afterThirtyDays;
    }
    return false;
  };

  isHoliday(calendarDate) {
    const allHolidays = new Holidays('US');
    const holiday = allHolidays.isHoliday(calendarDate);
    return holiday && this.holidays.indexOf(holiday[0].name) >= 0;
  }

  // initialize the calendar to start at the first valid date
  getNextValidDate(): Date {
    const dateToCheck = moment(new Date()).add(30, 'days');
    while (!this.filterDates(dateToCheck.toDate())) {
      dateToCheck.add(1, 'days');
    }
    return dateToCheck.toDate();
  }
}
