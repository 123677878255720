<div class="ticket-comment">
  <a *ngIf="browserSize === 'small'" class="back-link icon-text" [routerLink]="['/tickets', ticket.Id]">
    <div class="custom-icon arrow_back"></div>
    Back to Ticket Detail
  </a>
  <ng-container *ngIf="!ticket.IsClosed && !allowUserInput">
    <div
      class="post-container"
      *ngxPermissionsOnly="'Ticketing: Write'"
      [ngClass]="{ 'sticky-comment-header': browserSize !== 'small' }"
    >
      <form
        class="post-comment"
        (ngSubmit)="!isCommentSubmitting ? saveFormComment(commentForm) : ''"
        [formGroup]="commentForm"
      >
        <div class="comment-form" (mouseleave)="onCommentChanges(commentText.value)">
          <mat-form-field hideRequiredMarker="true" floatLabel="never">
            <textarea
              matInput
              formControlName="Body__c"
              placeholder="POST A COMMENT"
              required
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="7"
              spellcheck="true"
              #commentText
            ></textarea>
          </mat-form-field>
        </div>
        <button type="submit" [disabled]="!commentForm.valid && isCommentSubmitting">
          <span>Send</span>
        </button>
      </form>
      <mat-progress-bar mode="indeterminate" *ngIf="isCommentSubmitting"></mat-progress-bar>
      <p class="comment-warning" *ngIf="!isCommentEmpty && !hasError && !isCommentSubmitting">
        You have not submitted your comment! Click 'Send' to submit.
      </p>
      <p class="comment-warning error" *ngIf="!isCommentEmpty && hasError && !isCommentSubmitting">
        Your comment could not be submitted. Please try again.
      </p>
    </div>
  </ng-container>

  <div
    class="list-comments"
    [ngClass]="{ expand: viewMoreComments, collapse: !viewMoreComments }"
    *ngIf="ticket && ticket.Comments__r?.records"
    #commentsContainer
  >
    <div class="comment-container-outer">
      <div class="comment-container" *ngFor="let comment of ticket.Comments__r.records">
        <div class="details">
          <div class="createdby-photo">
            <span>
              <img
                *ngIf="comment.Contact__r && comment.Contact__r.Name !== 'System User'; else defaultImage"
                alt="logged in user icon"
                src="assets/images/img_user_default@2x.png"
              />
              <ng-template #defaultImage
                ><img alt="nitel user icon" src="assets/images/img_user_nitel@2x.png"
              /></ng-template>
            </span>
          </div>
          <div class="details-section">
            <div class="divider" [ngClass]="{ 'comment-new': !comment.Is_Read__c }">
              <span *ngIf="!comment.Is_Read__c">New</span>
            </div>
            <div class="creation">
              <!-- If comment creator is created by the user/contact, show user's fullname
            If comment is not created by contact or user, show Nite's employee's first name only -->
              <div class="createdby">
                <ng-container *ngIf="comment.Contact__r && !comment.User__r">
                  {{ comment.Contact__r.Name === 'System User' ? 'Nitel' : (comment.Contact__r.Name | titlecase) }}
                </ng-container>
                <ng-container *ngIf="!comment.User__r && !comment.Contact__r">
                  {{
                    comment.CreatedBy.FirstName === 'Api' ||
                    comment.CreatedBy.FirstName === 'API' ||
                    comment.CreatedBy.FirstName === 'My' ||
                    comment.CreatedBy.FirstName === 'Salesforce'
                      ? 'Nitel'
                      : (comment.CreatedBy.FirstName | titlecase)
                  }}
                </ng-container>
                <ng-container *ngIf="!comment.Contact__r && comment.User__r">
                  {{ comment.User__r.FirstName === 'System' ? 'Nitel' : (comment.User__r.FirstName | titlecase) }}
                </ng-container>
              </div>
              <div class="createddate">
                {{ comment.CreatedDate | date: 'h:mma, MMM d, y' }}
              </div>
            </div>
            <div class="comment-body" [innerHtml]="comment.Body__c"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="post-comment-divider" *ngIf="browserSize === 'small'"></div>
</div>
