<div class="success-container">
  <div class="success-visual">
    <div class="custom-icon success_lrg"></div>
  </div>
  <div class="success-text" *ngIf="!order; else orderSubmitted">
    <h1>Ticket Submitted!</h1>
    <h2>
      Your ticket has been created and sent. We will keep you updated on the status of your ticket as we proceed. If you
      created a disconnect ticket, check the orders page for updates.
    </h2>
    <button class="button" (click)="onClosed()"><span>OK</span></button>
  </div>
  <ng-template #orderSubmitted>
    <div class="success-text">
      <h1>Order Submitted!</h1>
      <h2 *ngIf="order.Id">
        Your order <a routerLink="/orders/{{ order.Id }}" (click)="onClosed()"> {{ order.Name }}</a> has been created
        and sent. We will keep you updated on the status of your order as we proceed.
      </h2>
      <h2 *ngIf="!order.Id">
        Your <a routerLink="/orders" (click)="onClosed()">order</a> has been created and sent. We will keep you updated
        on the status of your order as we proceed.
      </h2>
      <button class="button" (click)="onClosed()"><span>OK</span></button>
    </div>
  </ng-template>
</div>
