import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
	private subject = new Subject<any>();

	constructor() { }

	public sendState(state: string) {
		this.subject.next(state);
	}

	public clearState() {
		this.subject.next(null);
	}

	public getState(): Observable<any> {
		return this.subject.asObservable();
	}

}
