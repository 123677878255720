import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';

import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-contact-information',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {
  dialogWidth: string;
  dialogHeight: string;

  title: string;
  errorMessage: string;
  browserSize: string;
  contact: User;
  contactSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<ContactInformationComponent>, @Inject(MAT_DIALOG_DATA) private data) {}

  ngOnInit() {
    this.title = this.data.title;
    this.contact = this.data.contact;
    this.browserSize = this.data.browserSize;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
