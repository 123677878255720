import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Ticket } from 'src/app/models/ticket.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CommentService } from 'src/app/services/comment.service';
import { BoxFileModel, BoxFolderModel } from 'src/app/models/boxFolderModel';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnChanges, OnInit {
  @Input() ticket: Ticket;
  @Input() allowUserInput: boolean;
  @Output() filesUploaded: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileUploads', { static: false }) private fileUploads: ElementRef;
  fileName: string = '';
  existingFiles: BoxFolderModel = { id: 0, name: '', files: [] };
  ticketId: string;

  isLoading$ = new BehaviorSubject<boolean>(false);
  hasError$ = new BehaviorSubject<Boolean>(false);
  fileUploadError$ = new BehaviorSubject<string>('');

  constructor(private attachmentService: AttachmentService, private commentService: CommentService) {}

  ngOnInit(): void {
    this.ticketId = this.ticket.Id;
  }

  ngOnChanges() {
    if (this.ticketId !== this.ticket.Id) {
      this.ticketId = this.ticket.Id;
      this.getExistingAttachments(this.ticketId);
    }
    this.fileName = 'Select a file to upload';
    this.hasError$.next(false);
    this.fileUploadError$.next('');
  }

  fileUploadClick() {
    const uploadElement = this.fileUploads.nativeElement;

    uploadElement.onchange = () => {
      const file: File = this.fileUploads.nativeElement.files[0];
      if (this.validateFile(file)) {
        this.sendFile(file);
      }
    };

    uploadElement.click();
  }

  getExistingAttachments(ticketId: string) {
    this.attachmentService.getFolderContents(ticketId).subscribe(
      (success: any) => {
        if (typeof success === 'object') {
          this.isLoading$.next(false);
          this.hasError$.next(false);
          this.existingFiles = success;
        }
      },
      (error) => {
        this.isLoading$.next(false);
        this.hasError$.next(true);
        this.fileUploadError$.next('Unable to retrieve uploaded files');
      }
    );
  }

  sendFile(file: File) {
    this.isLoading$.next(true);
    this.hasError$.next(false);
    const uploadFile = this.ensureUniqueFileName(file);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('recordId', this.ticket.Id);
    this.fileName = file.name;

    this.attachmentService.addAttachment(formData).subscribe(
      (success: any) => {
        if (typeof success === 'object') {
        }
        if (success?.message === 'File uploaded successfully.') {
          this.isLoading$.next(false);
          this.hasError$.next(false);
          this.filesUploaded.emit({ files: [{ fileName: uploadFile.name, success: true }] });
        }
      },
      (error) => {
        this.isLoading$.next(false);
        this.hasError$.next(true);
        this.fileUploadError$.next(error);
      }
    );
  }

  ensureUniqueFileName(uploadFile: File) {
    this.existingFiles.files.forEach((file) => {
      if (uploadFile.name === file.name) {
        const lastDot = uploadFile.name.lastIndexOf('.');
        let fileName = uploadFile.name;
        let extension = '';

        if (lastDot > -1) {
          extension = uploadFile.name.substring(lastDot, file.name.length);
          fileName = uploadFile.name.substring(0, lastDot);
        }

        const now = new Date();
        uploadFile = new File([uploadFile], fileName.concat(' - ', now.toISOString(), extension), {
          type: uploadFile.type,
          lastModified: uploadFile.lastModified
        });
      }
    });

    return uploadFile;
  }

  validateFile(file: File): boolean {
    let isValid = true;

    if (file.size === 0) {
      isValid = false;
      this.hasError$.next(!isValid);
      this.fileUploadError$.next(`File ${file.name} is empty`);
    }

    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/bmp' &&
      file.type !== 'image/gif' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      isValid = false;
      this.hasError$.next(!isValid);
      this.fileUploadError$.next(`File type ${file.type} is not supported.`);
    }

    return isValid;
  }
}
