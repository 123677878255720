<section>
  <div class="success-container">
    <div class="success-text">
      <h1>Session Expired Warning</h1>
      <h2>
        Your session is about to expire due to inactivity! For security reasons, you will be logged off in <b>{{ counter$ | async }}</b> seconds.
      </h2>
    </div>
  </div>
  <!-- <button class="button" (click)="closeDialog(false)"><span>Log Out</span></button> -->
  <button class="button" (click)="closeDialog(true)" cdkFocusInitial>
    <span>Stay Signed In</span>
  </button>
</section>
