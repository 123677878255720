import { createSelector } from '@ngrx/store';

import { Payment } from 'src/app/models/payment.model';
import * as fromFeature from '../reducers';
import * as fromRouters from '../reducers/router.reducer';
import * as fromPayments from '../reducers/payments.reducer';

export const getPaymentsState = createSelector(
  fromFeature.getFeatureBillingState,
  (state: fromFeature.BillingState) => state.payments
);

export const getPaymentsEntities = createSelector(getPaymentsState, fromPayments.getPaymentsEntities);

export const getPaymentsLoaded = createSelector(getPaymentsState, fromPayments.getPaymentsLoaded);
export const getPaymentsLoading = createSelector(getPaymentsState, fromPayments.getPaymentsLoading);
export const getPaymentsFilterNumber = createSelector(getPaymentsState, fromPayments.getPaymentsFilterNumber);

export const getAllPayments = createSelector(getPaymentsEntities, getPaymentsFilterNumber, (entities, size) => {
  return Object.keys(entities)
    .slice(0, size)
    .map((id) => entities[id]);
});

// ROUTER SELECTORS
export const getCurrentRouterPayment = createSelector(
  getAllPayments,
  fromRouters.getRouterFeatureState,
  (payments, router): Payment => {
    if (router.state) {
      const payment = payments.find((x) => x.Id === router.state.params.paymentId);
      return payment;
    }
  }
);

// GET SELECTED
export const getSelectedPaymentId = createSelector(getPaymentsState, fromPayments.getPaymentSelected);
export const getSelectedPayment = createSelector(
  getPaymentsEntities,
  getSelectedPaymentId,
  (entities, paymentId): Payment => {
    return entities[paymentId];
  }
);

// GET LATEST PAYMENT
export const getLatestPayment = createSelector(
  getAllPayments,
  (payments): Payment => {
    if (payments.length > 0) {
      return payments[0];
    }
  }
);
