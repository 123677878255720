<div class="location-service" *ngIf="ticket.Service_Location_Address__c">
  <div class="location">
    <div class="label">Location of Service</div>
    <div class="icon-text">
      <div class="custom-icon location_dark">location</div>
      {{ ticket.Service_Location_Address__c | uppercase }}
    </div>
  </div>
  <div class="service">
    <div class="label">Service</div>
    <div class="icon-text">
      <div class="custom-icon circuit_dark">circuit</div>
      <span>
        {{ ticket.Service__r ? ticket.Service__r.Name : '- - -' }} -
        {{ ticket.Service__r.RecordType ? ticket.Service__r.RecordType.Name : '- - -' }}
        <ng-container *ngIf="ticket.Service__r.Bandwidth_Downstream__c">
          - {{ ticket.Service__r.Bandwidth_Downstream__c }}
        </ng-container>
        <ng-container *ngIf="ticket.Service__r.Asymmetrical_Bandwidth__c">
          / {{ ticket.Service__r.Bandwidth_Upstream__c }}
        </ng-container>
        <ng-container *ngIf="ticket.Service__r.Vendor__r">
          - {{ ticket.Service__r.Vendor__r.Display_Alias__c ? ticket.Service__r.Vendor__r.Display_Alias__c : ticket.Service__r.Vendor__r.Name }}
        </ng-container>
</span>
    </div>
  </div>
</div>
<div class="label">Customer Ticket #</div>
<!-- To make sure that any care tickets that previously had customer ticket number associated don't display them -->
<div class="text">
  {{ ticket.Customer_Ticket_Number__c && ticket.RecordType.Name !== 'CARE' ? ticket.Customer_Ticket_Number__c : '---' }}
</div>
