import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Ticket } from 'src/app/models/ticket.model';
import { api } from 'src/app/models/api-url.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private apiService: ApiService) {}

  getTickets(): Observable<Ticket[]> {
    return this.apiService.get(api.ticketsURL);
  }

  getOpenTicketTotal(): Observable<number> {
    return this.apiService.get(`${api.ticketsURL}/open_total`);
  }

  createNocTicket(ticketData): Observable<any> {
    return this.apiService.post(`${api.ticketsURL}/noc`, ticketData);
  }

  createCareTicket(ticketData): Observable<any> {
    return this.apiService.post(`${api.ticketsURL}/care`, ticketData);
  }

  createBillingTicket(ticketData): Observable<any> {
    return this.apiService.post(`${api.ticketsURL}/billing`, ticketData);
  }
}
