<section class="widget order-detail order-page">
  <div class="content" *ngIf="isOrderAvailable">
    <div class="orders-detail-header">
    <detail-header [order]="order" [browserSize]="browserSize"></detail-header>

    <div class="order-details">
      <div class="detail-wrapper" *ngIf="!hasErrors; hasErrorsTemplate">
        <div class="contacts-information">
          <div class="sales-rep">
            Sales Representative:
            <a
              (click)="viewContactInformation(order.Sales_Rep__r, 'Sales Representative')"
              *ngIf="order.Sales_Rep__c; else noSalesContact"
            >
              {{ order.Sales_Rep__r?.Name }}
            </a>
            <ng-template #noSalesContact>N/A</ng-template>
          </div>
          <div class="account-manager">
            Account Manager:
            <a
              (click)="viewContactInformation(order.Account_Manager__r, 'Account Manager')"
              *ngIf="order.Account_Manager__c; else noAMContact"
            >
              {{ order.Account_Manager__r?.Name }}
            </a>
            <ng-template #noAMContact>N/A</ng-template>
          </div>
          <div class="project-manager">
            Client Project Manager:
            <a
              (click)="viewContactInformation(order.Default_Task_Assignments__r.records[0].User__r, 'Client Project Manager')"
              *ngIf="order.Default_Task_Assignments__r; else noPMContact"
            >
              {{ order.Default_Task_Assignments__r?.records[0]?.User__r?.Name }}
            </a>
            <ng-template #noPMContact>N/A</ng-template>
          </div>
        </div>
        <ng-container *ngIf="isPdf">
          <button class="file-download" (click)="downloadFile()">
            <div class="custom-icon pdf">pdf icon</div>
          </button>
        </ng-container>
      </div>
      </div>
      </div>
    <div class="order-details">

      <ng-template #hasErrorsTemplate>
        <div class="detail-wrapper" *ngIf="hasErrors">
          <p>Data was not loaded correctly. Try refreshing the page.</p>
        </div>
      </ng-template>

      <div class="loading-indicator" *ngIf="isFileLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <!-- EMBEDDED PDF FILE -->
      <ng-container *ngIf="formAPIData && !isFileLoading && serviceOrderForm.ContentDocument && isFileLoaded">
        <div class="embedded-pdf" *ngIf="(isPdf && browserSize !== 'small'); else downloadSOF">
          <pdf-viewer
            [src]="fileUrl"
            [(page)]="currentFilePage"
            [show-all]="true"
            [show-borders]="false"
            [original-size]="false"
            [autoresize]="true"
            [render-text]="true"
            (error)="onFileError()"
            (after-load-complete)="afterFileLoadComplete($event)"
          ></pdf-viewer>
        </div>

        <ng-template #downloadSOF></ng-template>
        <div class="download-file">
          <div class="button-wrapper">
            <button class="button" (click)="downloadFile()">
              <span>Download Service Order Form</span>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isFileLoading && !serviceOrderForm.ContentDocument && isFileLoaded">
        <p class="no-sof">
          <br />
          No Service Order Form has been posted to this deal yet.
        </p>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isOrderAvailable && !areOrdersLoading">
    <div class="content">
      <br />
      <p>The order you are requesting does not exists.</p>
    </div>
  </ng-container>

  <app-footer></app-footer>
</section>
