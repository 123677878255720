<div class="public-view">
  <div class="header-wave-loop"></div>
  <router-outlet></router-outlet>
  <footer class="login-footer">
    <span>
      &#169; Copyright {{ copyrightYear }}. All rights reserved. 350 N Orleans St. #1300N, Chicago, IL 60654. Phone:
      <a href="tel:888-450-2100">888-450-2100</a>
    </span>
  </footer>
</div>
