import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// angular materials
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// external plugins
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxPermissionsGuard } from 'ngx-permissions';

// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effects, ordersReducers } from 'src/app/store';
import { NgxPaginationModule } from 'ngx-pagination';

// combined common modules into one file
import * as fromGuards from 'src/app/store/guards';
import { SharedModule } from '../shared/shared.module';

// components & containers
import * as fromComponents from './components';
import * as fromContainers from './containers';
import { NgChartsModule } from 'ng2-charts';

const orderRoutes: Routes = [
  {
    path: '',
    component: fromContainers.OrdersShellComponent,
    canActivate: [fromGuards.AuthGuard, fromGuards.OrdersGuard, NgxPermissionsGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    PdfViewerModule,

    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgChartsModule,

    RouterModule.forChild(orderRoutes),
    StoreModule.forFeature('orders', ordersReducers),
    EffectsModule.forFeature(effects)
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers],
  providers: [...fromGuards.guards],
  exports: [...fromContainers.containers, ...fromComponents.components]
})
export class OrdersModule {}
