import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { CommentService } from 'src/app/services/comment.service';
import { HeaderService } from 'src/app/services/header.service';
import { Comment } from 'src/app/models/comment.model';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-ticket-comment',
  templateUrl: './ticket-comment.component.html',
  styleUrls: ['./ticket-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketCommentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() ticket: Ticket;
  @Input() viewMoreComments: boolean;
  @Input() widgetHeight: number;
  @Input() descriptionContentHeight: number;
  @Input() fileUploadResult: any;
  @Input() allowUserInput: boolean;

  @Output() reloadTicketsData: EventEmitter<any> = new EventEmitter();
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  @ViewChild('commentsContainer', { static: false }) commentsContainer: ElementRef;

  commentForm: UntypedFormGroup;
  comments: Comment[];
  comment: Comment;
  isCommentSubmitting = false;
  isCommentEmpty = true;
  hasError = false;

  isTicketIdOnRouter = false;
  ticket$: Observable<Ticket>;
  ticketId: string;
  apiEmployeeId: string;

  ticketSubscription: Subscription;
  subscription: Subscription;

  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string;

  heightDiff = 0;
  commentHeight = 200;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private commentService: CommentService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public header: HeaderService,
    private store: Store<fromStore.State>
  ) {
    // TODO: remove from constructor
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        if (name !== this.browserSize && this.browserSize === 'small') {
          // you're currently changing from a small device to larger device size
          this.router.navigateByUrl('/tickets');
        }
        this.browserSize = name;
      }
    });

    // TODO: make this an observable and remove it from constructor
    this.heightDiff = this.widgetHeight - this.descriptionContentHeight;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
    this.fileUploadResultChange(changes);
  }

  private fileUploadResultChange(changes: SimpleChanges) {
    const oldName: string = changes.fileUploadResult?.previousValue?.files?.[0]?.fileName ?? '';
    const newName: string = changes.fileUploadResult?.currentValue.files?.[0].fileName ?? '';
    const success: boolean = changes.fileUploadResult?.currentValue.files?.[0].success ?? false;

    if (newName !== oldName && success) {
      this.saveComment({ Case__c: this.ticket.Id, Body__c: `File '${newName}' added.` });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
  }

  initialize() {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }

    this.isTicketIdOnRouter = !!this.route.snapshot.paramMap.get('ticketId');

    if (this.browserSize === 'small') {
      this.onSmallScreen();
    } else {
      this.onLargeScreen();
    }

    this.fileUploadResult = {};
  }

  onSmallScreen() {
    this.ticket$ = this.store.select(fromStore.getCurrentRouterTicket);
    this.ticketSubscription = this.ticket$.subscribe((ticket) => {
      if (ticket) {
        if (this.ticket) {
          // empty ticket array
          this.ticket = null;
        }
        this.ticket = ticket;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.populateCommentForm();
    this.scrollToBottom();

    setTimeout(() => {
      this.updateUnreadComments();
    }, 5000);
  }

  onLargeScreen() {
    if (this.isTicketIdOnRouter) {
      this.router.navigateByUrl('/tickets');
    }

    this.populateCommentForm();
    this.getCommentHeight();
  }

  populateCommentForm() {
    this.commentForm = this.fb.group({
      Case__c: this.ticket ? [`${this.ticket.Id}`] : [''],
      Body__c: ['', [Validators.required]]
    });
  }

  updateUnreadComments() {
    // if ticket has unread comments, change them to read
    if (this.ticket) {
      if (this.ticket.Comments__r && this.ticket.Comments__r.totalSize > 0) {
        if (this.hasUnreadComments(this.ticket.Comments__r.records)) {
          const updateObject = {
            Case__c: this.ticket.Id,
            Is_Read__c: true
          };
          this.commentService.updateCommentsByTicket(updateObject).subscribe(() => {
            this.store.dispatch(new fromStore.ClearTickets());
            this.store.dispatch(new fromStore.LoadTickets());
            this.onSmallScreen();
          });
        }
      }
    }
  }

  hasUnreadComments = (comments) => {
    if (comments) {
      return comments.some((c) => !c.Is_Read__c);
    }
    return false;
  };

  scrollToBottom() {
    if (this.commentsContainer) {
      this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
      window.scrollTo(0, this.commentsContainer.nativeElement.scrollHeight);
    }
  }

  getCommentHeight() {
    if (this.widgetHeight - this.descriptionContentHeight - 70 > 200) {
      this.commentHeight = this.widgetHeight - this.descriptionContentHeight - 70;
    }
  }

  saveFormComment(form: UntypedFormGroup) {
    if (!this.isCommentEmpty) {
      if (this.commentForm.valid) {
        this.saveComment(form.value);
      }
    }
  }

  private saveComment(comment: any) {
    this.isCommentSubmitting = true;

    this.commentService.createComment(comment).subscribe(
      (data) => {
        // Reset the form to clear the flags
        this.isCommentSubmitting = false;
        if (data.success) {
          this.isCommentEmpty = true;
          this.commentForm.reset();
        }

        // reload tickets & comments
        this.store.dispatch(new fromStore.LoadTickets());
        if (this.browserSize === 'small') {
          this.onSmallScreen();
        } else {
          this.reloadTicketsData.emit();
        }
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.isCommentSubmitting = false;
        this.hasError = true;
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  onCommentChanges($event: any) {
    if (this.browserSize !== 'small') {
      // remove spaces to count only letters.
      const text = $event.replace(/\s+/g, '');
      if (text.length > 1) {
        this.isCommentEmpty = false;
      } else {
        this.isCommentEmpty = true;
      }
    }
  }
}
