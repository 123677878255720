import { createSelector } from '@ngrx/store';
import * as fromNodes from '../reducers/nodes.reducer';

export const getDownNodesEntities = createSelector(fromNodes.getFeatureNodeState, fromNodes.getDownNodesEntities);

export const getAllDownNodes = createSelector(getDownNodesEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getDownNodesLoaded = createSelector(fromNodes.getFeatureNodeState, fromNodes.getDownNodesLoaded);
export const getDownNodesLoading = createSelector(fromNodes.getFeatureNodeState, fromNodes.getDownNodesLoading);
export const getDownNodesError = createSelector(fromNodes.getFeatureNodeState, fromNodes.getDownNodesError);
