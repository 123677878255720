<div class="line-item-inner">
  <div class="line-item-description">
    <div class="description">
      <div class="top-detail">
        <div class="order-type">
          Order Type: <span>{{ lineItem.RecordType.Name }}</span>
        </div>
        <div class="line-item-nit" *ngIf="lineItem.Service__r">
          <div class="custom-icon circuit_dark">circuit icon</div>
          {{ lineItem.Service__r.Name ? lineItem.Service__r.Name : '' }}
        </div>
      </div>

      <div class="bottom-detail">
        <div class="line-item-location" *ngIf="lineItem.Location__r">
          <div class="custom-icon location_dark">location icon</div>
          <h5>
            {{ lineItem.Location__r.Name }}
          </h5>
        </div>
        <div class="service-type">
          <div class="custom-icon component">component icon</div>
          {{ lineItem.Service_Type__c }}
        </div>
      </div>
    </div>

    <div class="status-term">
      <div class="status">
        <ng-container *ngIf="lineItem.Service_Order2__c && lineItem.Service_Order2__r?.Status__c !== 'Complete'">
          STATUS:
          <span
            class="label"
            [ngClass]="{
              progress: lineItem.Service_Order2__r?.Status__c === 'In Progress',
              pending:
                lineItem.Service_Order2__r?.Status__c === 'Ready' ||
                lineItem.Service_Order2__r?.Status__c === 'Pending',
              canceled: lineItem.Service_Order2__r?.Status__c === 'Canceled'
            }"
            [ngSwitch]="lineItem.Service_Order2__r?.Status__c"
          >
            <ng-template [ngSwitchCase]="'Ready'">Pending </ng-template>
            <ng-template ngSwitchDefault>
              {{ lineItem.Service_Order2__r?.Status__c !== null ? lineItem.Service_Order2__r?.Status__c : '' }}
            </ng-template>
          </span>
        </ng-container>

        <ng-container *ngIf="lineItem.Service_Order2__c && lineItem.Service_Order2__r?.Status__c === 'Complete'">
          <span class="label complete"> Completed </span>
          {{ lineItem.Service_Order2__r?.Completed_Date__c | date: 'MM/dd/yy' }}
        </ng-container>

        <div class="view-more">
          <div class="custom-icon arrow_progress"></div>
        </div>
      </div>

      <div class="line-item-term" *ngIf="lineItem.Term__c">
        Term:
        <span>{{ lineItem.Term__c }} months</span>
      </div>
    </div>
    <!-- <a routerLink="line-items/{{ lineItem.Id }}" tabindex="-1" class="line-item-link-outer mobile"
      ><div class="line-item-link mobile">mobile</div></a
    > -->
  </div>
  <a routerLink="{{ order.Id }}/line-items/{{ lineItem.Id }}" tabindex="-1" class="order-link-outer mobile">
    <div class="order-link mobile">View Line Item</div>
  </a>
</div>
