import * as fromLineItemModel from './line-item.model';
import * as fromTaskAssignmentModel from './task-assignment.model';
import { Account } from './account.model';
import { ContentDocumentLink } from './content-document-link.model';
import { OrderComment } from './orderComment.model';
import { SalesforceBase } from './salesforce-base.model';
import { User } from './user.model';

export interface Order extends SalesforceBase {
  Account__c?: string;
  Account__r?: Account;
  Account_Manager__c?: string;
  Account_Manager__r?: User;
  activeJeopardy: any;
  Customer_Order_OLI__r?: fromLineItemModel.Records;
  Customer_PON__c?: string;
  Order_Date__c?: Date;
  Order_Comments__r?: OrderComment[];
  OwnerId?: string;
  Owner_Name__c?: string;
  Sales_Rep__c?: string;
  Sales_Rep__r?: User;
  Status__c?: string;
  Completed_Date__c?: Date;
  Default_Task_Assignments__r?: fromTaskAssignmentModel.Records;
  ServiceOrderForm?: ContentDocumentLink;
  Vendor_FOC_Date__c?: Date;
  Nitel_FOC_Date__c?: Date;
  Equipment_Shipment_Tracking_Number__c?: string;
  Shipping_Carrier__c?: string;
  In_Staggered_Order_Jeopardy__c: boolean;
}

export interface DisplayOrder {
  detailView: string;
  orderId: string;
  lineItemId?: string;
}

export interface OrderView {
  order: string;
  lineItem: string;
}

export const OrderDetailViews: OrderView = {
  order: 'Order',
  lineItem: 'LineItem'
};
