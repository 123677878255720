import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'totals-display',
  styleUrls: ['totals-display.component.scss'],
  template: `<div class="service-cta">
    <div class="total-wrapper">
      <div class="number">{{ activeOrders.length || 0 }}</div>
      <div class="total-text">
        Total Active
        <span>Orders</span>
      </div>
    </div>
    <div class="total-locations">Spanning {{ totalActiveLocations }} locations</div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalsDisplayComponent {
  @Input() activeOrders: Order[];
  @Input() totalActiveLocations = 0;
}
