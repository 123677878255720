import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/store/';
import { Node } from 'src/app/models/node.model';

@Component({
  selector: 'app-widget-monitoring',
  templateUrl: './widget-monitoring.component.html',
  styleUrls: ['../widgets.scss', './widget-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetMonitoringComponent implements OnInit {
  downNodes$: Observable<Node[]>;
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  orionURL$: Observable<string>;
  errorMessage$: Observable<string>;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadDownNodes());
    this.downNodes$ = this.store.select(fromStore.getAllDownNodes);
    this.isLoading$ = this.store.select(fromStore.getDownNodesLoading);
    this.isLoaded$ = this.store.select(fromStore.getDownNodesLoaded);
    this.errorMessage$ = this.store.select(fromStore.getDownNodesError);
    this.orionURL$ = this.store.select(fromStore.getOrionURL);
  }
}
