<!-- <ng-container *ngIf="browserSize$ | async as browserSize">
  <totals-display *ngIf="browserSize !== 'small'"
                  [activeOrders]="activeOrders$ | async"
                  [totalActiveLocations]="totalActiveLocations$ | async"></totals-display>
</ng-container>
<div class="details-link-wrapper">
  <app-active-order-chart
            [data]=orderResCurr
            [total]="40"
            [order] ="activeOrders$ | async"
            [loading]="false"
          ></app-active-order-chart>

  <div class="button-wrapper-order">
  <a class="button" href="/orders-table" title="View Orders List">
    <span>
      <mat-icon [inline]="true">{{ iconDetail }}</mat-icon>
      View Orders List
    </span>
  </a>
</div>
</div> -->

<order-detail *ngIf="(detailView === selectedView.order)"
              [order]="order$ | async"
              [serviceOrderForm]="serviceOrderForm$ | async"
              [formAPIData]="formAPIData$ | async"
              [browserSize]="browserSize$ | async"
              [isFileLoading]="isSOFLoading$ | async"
              [areOrdersLoading]="isLoading$ | async"
              (popupContactInformation)="popupContactInformation($event)"></order-detail>

<line-item-detail *ngIf="(detailView === selectedView.lineItem)"
                  [order]="order$ | async"
                  [lineItem]="lineItem$ | async"
                  [browserSize]="browserSize$ | async"
                  [areOrdersLoading]="isLoading$ | async"
                  (popupContactInformation)="popupContactInformation($event)"></line-item-detail>
