import { Action } from '@ngrx/store';
import { Service } from 'src/app/models/service.model';

export const LOAD_SERVICES = '[Services] Load Services';
export const LOAD_SERVICES_FAIL = '[Services] Load Services Fail';
export const LOAD_SERVICES_SUCCESS = '[Services] Load Services Success';

export class LoadServices implements Action {
  readonly type = LOAD_SERVICES;
}

export class LoadServicesFail implements Action {
  readonly type = LOAD_SERVICES_FAIL;
  constructor(public payload: any) {}
}

export class LoadServicesSuccess implements Action {
  readonly type = LOAD_SERVICES_SUCCESS;
  constructor(public payload: Service[]) {}
}

export const UPDATE_SERVICES_BANLOOKUP = '[Services] Update Services BanLookup';
export const SET_SELECTED_SERVICE = '[Services] Set Selected Service';

export class UpdateServicesBanLookup implements Action {
  readonly type = UPDATE_SERVICES_BANLOOKUP;
  constructor(public payload: Service[]) {}
}

export class SetSelectedService implements Action {
  readonly type = SET_SELECTED_SERVICE;
  constructor(public payload: string) {}
}

// action types
export type ServicesAction =
  | LoadServices
  | LoadServicesFail
  | LoadServicesSuccess
  | UpdateServicesBanLookup
  | SetSelectedService;
