import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { BrowserService } from 'src/app/services/browser.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html'
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  constructor(private browserSizeService: BrowserService, private zone: NgZone) {
    this.browserSizeService.checkWidth();
  }

  ngOnInit(): void {
    this.changeViewHeight();
    this.initializeOrientationChangeHandler();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
    window.screen.orientation.removeEventListener('change', this.handleOrientationChange.bind(this));
  }

  onResize(): void {
    this.browserSizeService.checkWidth();
    this.changeViewHeight();
  }

  changeViewHeight(): void {
    // Fix for iPad Chrome
    this.zone.run(() => this.updateViewportHeight());
  }
  
  private updateViewportHeight(): void {
    const viewportHeight = window.innerHeight;
    const styleElementId = 'dynamic-viewport-height-style';
  
    let styleElement = document.getElementById(styleElementId) as HTMLStyleElement;
  
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = styleElementId;
      document.head.appendChild(styleElement);
    }
  
    styleElement.innerHTML = `:root { --vh: ${viewportHeight}px; }`;
  }  
  
  private initializeOrientationChangeHandler(): void {
    window.screen.orientation.addEventListener('change', this.handleOrientationChange.bind(this));
  }

  private handleOrientationChange(): void {
    this.zone.run(() => this.updateViewportHeight());
  }
}
