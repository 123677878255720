<div class="container-table">
  <div class="container-top">
    <div class="container-title">
      <mat-toolbar>
        <span class="spacer"></span>
        <!-- Toggle Filters Button -->
        <button mat-raised-button (click)="clearFilter()">Clear Filters</button>
        <button mat-raised-button color="primary" (click)="toggleFilters()">
          {{ showFilters ? "Hide Filters" : "Show Filters" }}
        </button>
      </mat-toolbar>
    </div>
  </div>

  <section class="table-unm">
    <div class="table-container">
      <!-- Angular Material Table -->
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Suppressed Column -->
        <ng-container matColumnDef="Suppressed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Suppressed</th>
          <td mat-cell *matCellDef="let product">{{ true }}</td>
        </ng-container>

        <!-- Account Column -->
        <ng-container matColumnDef="device_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Edge Name</th>
          <td mat-cell *matCellDef="let product">
            {{ product.device_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deviceNameFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'device_name')"
              placeholder="Filter Edge Name"
              class="table-input"
              [(ngModel)]="filterValues.device_name"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="deviceNameFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Link Column -->
        <ng-container matColumnDef="link_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Link Name</th>
          <td mat-cell *matCellDef="let product">{{ product.link_name }}</td>
        </ng-container>

        <ng-container matColumnDef="linkNameFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'link_name')"
              placeholder="Filter Link Name"
              class="table-input"
              [(ngModel)]="filterValues.device_name"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="linkNameFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Application Column -->
        <ng-container matColumnDef="application">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Application</th>
          <td mat-cell *matCellDef="let product">
            {{ product.application }}
          </td>
        </ng-container>

        <ng-container matColumnDef="applicationFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'application')"
              placeholder="Filter Application"
              class="table-input"
              [(ngModel)]="filterValues.application"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="applicationFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Interface Name Column -->
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Source</th>
          <td mat-cell *matCellDef="let product">{{ product.source }}</td>
        </ng-container>

        <ng-container matColumnDef="sourceFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'source')"
              placeholder="Filter Source"
              class="table-input"
              [(ngModel)]="filterValues.source"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="sourceFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="time_frame">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
          <td mat-cell *matCellDef="let product">{{ product.time_frame }}</td>
        </ng-container>

        <ng-container matColumnDef="timeframeFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <div class="date-filter-container">
              <!-- Start Date Picker -->
              <input
                matInput
                [matDatepicker]="startDatePicker"
                [(ngModel)]="filterValues.startDate"
                (dateChange)="applyDateFilter()"
                placeholder="Start Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
                class="date-picker-button"
              />
              <mat-datepicker #startDatePicker />

              <!-- Start Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="startTimePicker"
                [(ngModel)]="filterValues.startTime"
                placeholder="Start Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="startTimePicker" matSuffix />
              <ngx-mat-timepicker #startTimePicker />
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="timeframeFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th">
            <div class="date-filter-container">
              <!-- End Date Picker -->
              <input
                matInput
                [matDatepicker]="endDatePicker"
                [(ngModel)]="filterValues.endDate"
                (dateChange)="applyDateFilter()"
                placeholder="End Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
                class="date-picker-button"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>

              <!-- End Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="endTimePicker"
                [(ngModel)]="filterValues.endTime"
                placeholder="End Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="endTimePicker" matSuffix />
              <ngx-mat-timepicker #endTimePicker />
            </div>
          </th>
        </ng-container>

        <!-- Latency (ms) Column -->
        <ng-container matColumnDef="latency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Latency (ms)
          </th>
          <td mat-cell *matCellDef="let product">{{ product.latency }}</td>
        </ng-container>

        <ng-container matColumnDef="latencyFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'latency')"
              placeholder="Filter Latency"
              class="table-input"
              [(ngModel)]="filterValues.latency"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="latencyFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Jitter (ms) Column -->
        <ng-container matColumnDef="jitter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Jitter (ms)</th>
          <td mat-cell *matCellDef="let product">{{ product.jitter }}</td>
        </ng-container>

        <ng-container matColumnDef="jitterFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'jitter')"
              placeholder="Filter Jitter"
              class="table-input"
              [(ngModel)]="filterValues.jitter"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="jitterFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Packet Loss Column -->
        <ng-container matColumnDef="packet_loss">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Packet Loss (%)
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.packet_loss }}
          </td>
        </ng-container>

        <ng-container matColumnDef="packetLossFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'packet_loss')"
              placeholder="Filter Packet Loss"
              class="table-input"
              [(ngModel)]="filterValues.packet_loss"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="packetLossFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Data Utilization Column -->
        <ng-container matColumnDef="data_utilization">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Data Utilization (%)
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.data_utilization }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dataUtilizationFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'data_utilization')"
              placeholder="Filter Data Utilization"
              class="table-input"
              [(ngModel)]="filterValues.data_utilization"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="dataUtilizationFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Data Usage (MB) Column -->
        <ng-container matColumnDef="data_usage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Data Usage (MB)
          </th>
          <td mat-cell *matCellDef="let product">{{ product.data_usage }}</td>
        </ng-container>

        <ng-container matColumnDef="dataUsageFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'data_usage')"
              placeholder="Filter Data Usage"
              class="table-input"
              [(ngModel)]="filterValues.data_usage"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="dataUsageFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters2"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- No Data Message -->

        <tr *matNoDataRow [hidden]="loading">
          <td [attr.colspan]="displayedColumns.length" class="no-data-cell">
            <div class="no-content">No data available to display.</div>
          </td>
        </tr>
      </table>

      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <!-- Pagination Controls -->
      <mat-paginator
        showFirstLastButtons
        [pageSize]="defaultPageSizeOption"
        [pageSizeOptions]="pageSizeOptions"
      ></mat-paginator>
    </div>
  </section>
</div>
