import * as fromActions from '../actions';

/* export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
    return function clearStateFn(state: State, action: Action) {
        if (action.type === fromActions.LOGOUT) {
            state = {} as State; // ==> Emptying state here
    }
    return reducer(state, action);
   };
} */

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === fromActions.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
