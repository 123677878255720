export const types_dictionary = {
  internet: [
    'Internet',
    'Wireless Internet',
    'Wholesale Wireless Internet',
    'Network Service Management',
    'Firewall',
    'Managed Security',
    'Cloud Connect',
    'Professional Services',
    'Nitel Equipment Monitoring',
    'Multi-service',
    'Colocation'
  ],
  ethernet: ['Layer 2 Ethernet', 'ENNI', 'Metro Ethernet'],
  mpls: ['MPLS'],
  sdwan: ['SD-WAN'],
  voice: ['SIP', 'Toll Free', 'POTS', 'eFax', 'Hosted'],
  p2p: ['Point-to-Point', 'P2P', 'Private Line']
};
