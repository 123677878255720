import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, FormControl, UntypedFormArray } from '@angular/forms';

import { Service } from 'src/app/models/service.model';
import { Observable } from 'rxjs';

import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-service-location',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './service-location.component.html',
  styleUrls: [
    '../../../tickets/containers/create-ticket/create-ticket.component.scss',
    './service-location.component.scss'
  ]
})
export class ServiceLocationComponent implements OnInit {
  @Input() services: Service[];
  @Input() selectedServiceId: string;
  @Input() formRef: UntypedFormGroup = new UntypedFormGroup({});
  @Input() inlineErrors: any = {};
  @Input() validateForNoc: Boolean;
  @Output() servicesLoaded = new EventEmitter(false);

  isLoadingServices = true;
  uniqueLocations = [];
  allServices = [];
  serviceObject = {};
  LOCATION = 'location';
  SERVICE = 'service';
  ticketType = 'service';
  identifier = this.LOCATION;
  servicesAtChosenLocation = [];
  options: Observable<any>;
  filteredOptions: Observable<any[]>;
  chosenService = null;
  allTempLocations = [];
  myControl = new FormControl('');

  ngOnInit() {
    if (this.services) {
      this.initFormData()
    }
    if (this.selectedServiceId.length) {
      this.formRef.controls.identifyIssueBy.setValue(this.SERVICE);
      this.onServiceSelected(this.selectedServiceId);
    }
  }

  private initFormData() {
    const m = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const service of this.services) {
      this.serviceObject[service.Id] = service;

      // If service has location, create array.
      if (service.Z_Location__r && !m.has(service.Z_Location__c)) {
        m.set(service.Z_Location__c, true);
        this.uniqueLocations.push(service);
      }
    }
    this.filteredOptions = this.formRef.controls.service.valueChanges.pipe(
      startWith(''),
      map((name) => (name ? this._filter(name) : this.services.slice()))
    );

    this.uniqueLocations.sort((a, b) => {
      if (a.Z_Location__r.Name < b.Z_Location__r.Name) {
        return -1;
      }
      if (a.Z_Location__r.Name > b.Z_Location__r.Name) {
        return 1;
      } else {
        return 0;
      }
    });

    this.servicesLoaded.emit(true);
    this.allServices = this.services.map((service) => ({
      ...service,
      alias: service?.Vendor__r?.Display_Alias__c
    }));
    this.isLoadingServices = false;
    // set the initial validity
    if (this.allServices.length > 0) {
      this.resetRequiredLocationFields(this.uniqueLocations, this.allServices);
      this.formRef.controls.identifyIssueBy.setValue(this.LOCATION);
    }
    this.canSubmit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toUpperCase();

    this.allTempLocations = this.allServices.filter((option) => option.Name.includes(filterValue));
    return this.allTempLocations;
  }

  resetRequiredLocationFields(locations, services) {
    if (this.validateForNoc) {
      if (locations.length === 1) {
        this.formRef.controls.locationOfService.clearValidators();
        if (services.length === 1) {
          this.formRef.controls.service.clearValidators();
          this.formRef.controls.service.setValue(services[0].Id); // set the value of service
        } else if (services.length > 1) {
          this.formRef.controls.service.setValidators(Validators.required);
        }
      } else if (locations.length > 1) {
        this.formRef.controls.locationOfService.setValidators(Validators.required);
        this.formRef.controls.service.setValidators(Validators.required);
        if (services.length === 1) {
          this.formRef.controls.service.clearValidators();
          this.formRef.controls.service.setValue(services[0].Id); // set the value of service
        }
      }
    }
  }

  resetRequiredServiceFields(services) {
    if (this.validateForNoc) {
      if (services.length === 1) {
        this.formRef.controls.locationOfService.clearValidators();
        this.formRef.controls.service.clearValidators();
        this.formRef.controls.service.setValue(services[0].Id); // set the value of service
      } else if (services.length > 1) {
        this.formRef.controls.service.setValidators(Validators.required);
        this.formRef.controls.locationOfService.clearValidators();
      }
    }
  }

  onRadioChange() {
    this.identifier = this.formRef.value.identifyIssueBy;
    this.servicesAtChosenLocation = [];
    this.chosenService = null;
    this.formRef.controls.service.setValue(null);
    this.formRef.controls.locationOfService.setValue(null);
    this.selectedServiceId = null;

    if (this.identifier === this.LOCATION) {
      this.resetRequiredLocationFields(this.uniqueLocations, this.allServices);
    } else {
      this.resetRequiredServiceFields(this.allServices);
    }
    this.formRef.controls.locationOfService.updateValueAndValidity();
    this.formRef.controls.services.updateValueAndValidity();
    this.canSubmit();
  }

  getServicesAtLocation(locationId: string) {
    const servicesAtLocation = this.allServices.filter((service) =>
      service.Z_Location__c ? service.Z_Location__c === locationId : false
    );
    return servicesAtLocation;
  }

  onLocationSelected(locationId: string) {
    this.servicesAtChosenLocation = this.getServicesAtLocation(locationId);
    this.formRef.controls.locationOfService.setValue(locationId);
    this.formRef.controls.service.setValue('');
    this.resetRequiredLocationFields(this.uniqueLocations, this.servicesAtChosenLocation);
    this.canSubmit();
  }

  locationSelected(serviceLocation) {
    this.servicesAtChosenLocation = this.getServicesAtLocation(serviceLocation.Id);
    this.formRef.controls.locationOfService.setValue(serviceLocation.Id);
    this.formRef.controls.service.setValue('');
    this.resetRequiredLocationFields(this.uniqueLocations, this.servicesAtChosenLocation);
    this.canSubmit();
  }

  onServiceSelected(serviceId: string) {
    const serviceAtLocation = this.allServices.find((service) => (service.Id ? service.Id === serviceId : false));
    if (serviceAtLocation) this.populateServiceForm(serviceAtLocation);
  }

  serviceSelected(selectedService) {
    const serviceAtLocation = this.allServices.find((service) =>
      service.Name ? service.Name === selectedService.serviceName : false
    );
    if (serviceAtLocation) this.populateServiceForm(serviceAtLocation)
  }

  populateServiceForm(selectedService: Service) {
    this.chosenService = this.serviceObject[selectedService.Id];
    this.formRef.controls.service.setValue(selectedService.Id);
    this.resetRequiredServiceFields(this.allServices);
    this.canSubmit();
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.formRef.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.formRef.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.formRef.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
    return this.formRef.valid;
  }
}
