<div class="billing-header">
  <div class="custom-icon component">component icon</div>
  <h4>Billing Components ({{ components?.length || 0 }})</h4>
</div>

<div class="billing-services-list" *ngIf="components.length > 0">
  <ul>
    <li *ngFor="let component of components">
      {{ component.Display_Name__c }}
      <span>{{ component.MRR__c | currency }}</span>
    </li>
  </ul>
</div>
