// convert it all to lower case for further filtering
export const transport_types = [
	{
		product: 't1',
		interval: '30-45 days',
	},
	{
		product: 'nxt1',
		interval: '30-45 days',
	},
	{
		product: 'eoc',
		interval: '30-45 days',
	},
	{
		product: 'eods1',
		interval: '30-45 days',
	},
	{
		product: 'wireless',
		interval: '30-45 days',
	},
	{
		product: 'broadband',
		interval: '30-45 days',
	},
	{
		product: 'ds3',
		interval: '60-75 days',
	},
	{
		product: 'fe',
		interval: '90-120 days',
	},
	{
		product: 'ocn',
		interval: '90-120 days',
	},
	{
		product: 'gige',
		interval: '90-120 days',
	},
	{
		product: '2.5g-10g',
		interval: '90-120 days',
	},

];
