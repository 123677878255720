import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  Ticket,
  TicketType,
  getTicketType,
} from 'src/app/models/ticket.model';
@Component({
  selector: 'ticket-type-chart',
  styleUrls: ["ticket-type-chart.component.scss"],
  templateUrl: './ticket-type-chart.component.html'
})
export class TicketTypeChartComponent implements OnChanges {
  @Input() tickets: Ticket[];
  loading: boolean;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public pieChartData: ChartData<'pie'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Tickets',
        borderWidth: 1,
        hoverBorderWidth: 1,
        backgroundColor: ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64','#ff742e']
      }
    ]
  };
  backgroundColor = [];
  public pieChartType: ChartType = 'pie' as ChartType;
  public pieChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        maxWidth: 195,
        display: true,
        position: 'right',
        labels: {
          color: 'white',
          boxWidth: 20,
          font: {
            size: 18
          },
          boxHeight: 20
        },
        
      },
    
    },
    layout: {
      padding: {
        bottom: 5,
        left: 5,
        right: 5
      },
    }
    
  };
  
  ngOnInit() {
    this.pieChartData.labels = Object.keys(this.countTicketsByType(this.tickets));
    this.pieChartData.datasets[0].data = Object.values(this.countTicketsByType(this.tickets));
    // this.backgroundColor = this.generateColors(this.pieChartData.labels.length);
    this.backgroundColor = ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64','#ff742e'];
    this.chart?.update();
  }
  
  ngOnChanges() {
    this.pieChartData.labels = Object.keys(this.countTicketsByType(this.tickets));
    this.pieChartData.datasets[0].data = Object.values(this.countTicketsByType(this.tickets));
    this.backgroundColor = ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64','#ff742e'];

    this.chart?.update();
  }

  ngAfterViewChecked(){
    this.loading = false;
  }

  countTicketsByType(tickets: Ticket[]): { [type: string]: number } {
    const ticketCounts: { [type: string]: number } = {};
  
    tickets.forEach((ticket) => {
      const type = this.getTicketTypeString(getTicketType(ticket));
      if (type !== null && type !== undefined) {
        if (!ticketCounts[type]) {
          ticketCounts[type] = 0;
        }
        ticketCounts[type]++;
      }
    });
    this.chart?.update();
    return ticketCounts;
  }

  getTicketTypeString(ticketType: TicketType): string {
    switch (ticketType) {
      case TicketType.Care:
        return 'Care';
      case TicketType.Finance:
        return 'Finance';
      case TicketType.Maintenance:
        return 'Maintenance';
      case TicketType.Proactive:
        return 'Proactive';
      case TicketType.Support:
        return 'Support';
    }
  }
}