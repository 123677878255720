import { Order, OrderType } from './order';
import OrderInfoDetail from '../order-info.interface';

export class OrderP2P extends Order {

	constructor()	{
		super();
		this.type = OrderType.P2P;
	}

	public getRowInfo(): OrderInfoDetail[] {
		return [
			{
				title: 'Vendor/Access Provider',
				value: this.location_a_vendor_provider,
			},
			{
				title: 'FOC Date (Location A)',
				value: this.formatDate(this.foc_date),
			},
			{
				title: 'Vendor/Access Provider (Location B)',
				value: this.location_z_vendor_provider,
			},
			{
				title: 'FOC Date (Location B)',
				value: this.formatDate(this.location_z_foc_date),
			},
			{
				title: 'Vendor Circuit ID (Location A)',
				value: this.location_a_circuit_id,
			},
			{
				title: 'Address (Location A)',
				value: this.location_a_address,
			},
			{
				title: 'Vendor Circuit ID (Location B)',
				value: this.location_z_circuit_id,
			},
			{
				title: 'Address (Location B)',
				value: this.location_z_address,
			},
			{
				title: 'Last Mile Provider Circuit ID (Location A)',
				value: this.location_a_last_mile_id,
			},
			{
				title: '',
				value: '',
			},
			{
				title: 'Last Mile Provider Circuit ID (Location B)',
				value: this.location_z_last_mile_id,
			},
			{
				title: 'Order Date',
				value: this.formatDate(this.order_date),
			},
		];
	}

}
