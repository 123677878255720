<!-- eslint-disable import/no-cycle -->
<ng-container *ngIf="identifyIssueBy === 'service'">
  <div class="question">Which service?</div>
  <mat-form-field floatLabel="never">
    <mat-label>Please select a service</mat-label>
    <input
      type="text"
      placeholder="Please select a service"
      aria-label="NIT Number"
      matInput
      [matAutocomplete]="auto"
      [formControl]="autocompleteControl"
      #input
    />
    <mat-autocomplete
      (optionSelected)="onServiceSelected($event)"
      #auto="matAutocomplete"
      (valueChange)="onServiceSelected($event)"
    >
      <mat-option
        *ngFor="let s of filteredOptions | async"
        [value]="s[ticketNumberValue]"
        [disabled]="s.disabled"
      >
        {{ s[this.ticketNumberValue] }} –
        <span *ngIf="ticketType === 'service'">{{
          s[this.ticketNameValue][this.ticketNameSecondary]
        }}</span
        ><span *ngIf="ticketType !== 'service'">{{
          s[this.ticketNameValue]
        }}</span
        ><span *ngIf="s.bandwidth"> - {{ s.bandwidth }} </span
        ><span *ngIf="s.alias"> - {{ s?.alias }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <ng-container *ngIf="selectedServiceLocation">
     <div class="display-static">
        <h3>Service location:</h3>
        <div class="icon-text">
          <div class="custom-icon location_dark"></div>
          {{ selectedServiceLocation }}
        </div>
      </div>
  </ng-container>
</ng-container>
