import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ContactService } from 'src/app/services/contact.service';
import * as contactActions from '../actions/contacts.action';

@Injectable()
export class ContactEffects {
  constructor(private actions$: Actions, private contactService: ContactService) {}

  loadContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contactActions.LOAD_CONTACTS),
      switchMap(() => {
        return this.contactService.getContacts().pipe(
          map((user) => new contactActions.LoadContactsSuccess(user)),
          catchError((error) => of(new contactActions.LoadContactsFail(error)))
        );
      })
    );
  });
}
