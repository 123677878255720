import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TokenInterceptor } from './token.interceptor';
import { SharedModule } from '../shared/shared.module';
import { ImpersonationComponent } from './impersonate/impersonation.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [ForgotPasswordComponent, LoginComponent, ResetPasswordComponent, ImpersonationComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
