import { createFeatureSelector } from '@ngrx/store';
import { Contact } from 'src/app/models/contact.model';
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for this feature (User)
export interface UserState {
  currentUser: Contact;
  loading: boolean;
  loaded: boolean;
  error: string;
}

const initialState: UserState = {
  currentUser: null,
  loading: true,
  loaded: false,
  error: ''
};

export interface State extends fromRoot.State {
  user: UserState;
}

export const getUserFeatureState = createFeatureSelector<UserState>('user');

export function reducer(state = initialState, action: fromActions.UsersAction): UserState {
  switch (action.type) {
    case fromActions.LOAD_CURRENT_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_CURRENT_USER_SUCCESS: {
      const user = action.payload;

      return {
        ...state,
        loading: false,
        loaded: true,
        currentUser: user
      };
    }

    case fromActions.LOAD_CURRENT_USER_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromActions.SET_CURRENT_USER: {
      const user = action.payload;

      return {
        ...state,
        currentUser: user,
        loading: false,
        loaded: true
      };
    }

    default: {
      return state;
    }
  }
}

export const getCurrentUser = (state: UserState) => state.currentUser;
export const getUserLoading = (state: UserState) => state.loading;
export const getUserLoaded = (state: UserState) => state.loaded;
export const getUserError = (state: UserState) => state.error;
