import { createFeatureSelector } from '@ngrx/store';
import * as fromActions from '../actions';

export interface BrowserState {
  sizeName: string;
  width: number;
  height: number;
  error: string;
}

const initialState: BrowserState = {
  sizeName: '',
  width: 0,
  height: 0,
  error: ''
};

export const getBrowserFeatureState = createFeatureSelector<BrowserState>('browser');

export function reducer(state = initialState, action: fromActions.BrowserAction): BrowserState {
  switch (action.type) {
    case fromActions.UPDATE_BROWSER_SIZE_NAME: {
      return {
        ...state,
        sizeName: action.payload
      };
    }

    case fromActions.UPDATE_BROWSER_WIDTH: {
      return {
        ...state,
        width: action.payload
      };
    }

    case fromActions.UPDATE_BROWSER_HEIGHT: {
      return {
        ...state,
        height: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getBrowserSizeName = (state: BrowserState) => state.sizeName;
export const getBrowserWidth = (state: BrowserState) => state.width;
export const getBrowserHeight = (state: BrowserState) => state.height;
