import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { Injectable } from '@angular/core';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export const getRouterFeatureState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize = (routerState: RouterStateSnapshot) => {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  };
}
