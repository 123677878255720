import { Order, OrderType } from './order';
import OrderInfoDetail from '../order-info.interface';

export class OrderVoice extends Order {

	constructor()	{
		super();
		this.type = OrderType.VOICE;
	}

	public getRowInfo(): OrderInfoDetail[] {
		return [
			{
				title: 'Order Date',
				value: this.formatDate(this.order_date),
			},
			{
				title: 'Start of Service Date',
				value: this.formatDate(this.start_service),
			},
			{
				title: 'Activation Date',
				value: this.formatDate(this.activation_date),
			},
		];
	}

}
