import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BillingService } from 'src/app/services/billing.service';
import * as paymentActions from '../actions/payments.action';

@Injectable()
export class PaymentEffects {
  constructor(private actions$: Actions, private billingService: BillingService) {}

  loadPayments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(paymentActions.LOAD_PAYMENTS),
      switchMap(() => {
        return this.billingService.getPayments().pipe(
          map((payments) => new paymentActions.LoadPaymentsSuccess(payments)),
          catchError((error) => of(new paymentActions.LoadPaymentsFail(error)))
        );
      })
    );
  });
}
