/* eslint-disable no-shadow */
import { Invoice } from 'src/app/models/invoice.model';
import * as fromActions from '../actions';

// State for Invoices
export interface InvoiceState {
  entities: { [id: string]: Invoice };
  loaded: boolean;
  loading: boolean;
  selectedInvoiceId: string;
}

export const initialState: InvoiceState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedInvoiceId: null
};

export function reducer(state = initialState, action: fromActions.InvoicesAction): InvoiceState {
  switch (action.type) {
    case fromActions.LOAD_INVOICES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_INVOICES_SUCCESS: {
      const invoices = action.payload;
      let entities = {};

      if (invoices !== null) {
        entities = invoices.reduce(
          (entities: { [id: string]: Invoice }, invoice) => {
            return {
              ...entities,
              [invoice.Id]: invoice
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_INVOICES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromActions.SET_SELECTED_INVOICE: {
      const invoiceId = action.payload;

      return {
        ...state,
        selectedInvoiceId: invoiceId
      };
    }

    default: {
      return state;
    }
  }
}

export const getInvoicesEntities = (state: InvoiceState) => state.entities;
export const getInvoicesLoading = (state: InvoiceState) => state.loading;
export const getInvoicesLoaded = (state: InvoiceState) => state.loaded;
export const getInvoiceSelected = (state: InvoiceState) => state.selectedInvoiceId;
