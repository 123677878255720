import { createSelector } from '@ngrx/store';

import { MappedDisconnectService, Service } from 'src/app/models/service.model';
import * as fromRouters from '../reducers/router.reducer';
import * as fromServices from '../reducers/services.reducer';

// helper function to sort services by name
// this extracts the number from the name, assuming "NITXXXXX"
const sortServicesByName = (a: Service, b: Service) => {
  const aNameAsNumber = a.Name ? parseInt(a.Name.substring(3)) : 0;
  const bNameAsNumber = b.Name ? parseInt(b.Name.substring(3)) : 0;

  if (aNameAsNumber < bNameAsNumber) {
    return -1;
  }
  if (aNameAsNumber > bNameAsNumber) {
    return 1;
  }

  return 0;
};

export const getServicesEntities = createSelector(
  fromServices.getServiceFeatureState,
  fromServices.getServicesEntities
);

export const getAllServices = createSelector(getServicesEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

// ROUTER SELECTORS
export const getCurrentRouterService = createSelector(
  getAllServices,
  fromRouters.getRouterFeatureState,
  (services, router): Service => {
    if (router.state) {
      const service = services.find((x) => x.Id === router.state.params.serviceId);
      return service;
    }
  }
);

export const getServicesLoaded = createSelector(fromServices.getServiceFeatureState, fromServices.getServicesLoaded);
export const getServicesLoading = createSelector(fromServices.getServiceFeatureState, fromServices.getServicesLoading);

// GET SELECTED
export const getSelectedServiceId = createSelector(
  fromServices.getServiceFeatureState,
  fromServices.getServiceSelected
);
export const getSelectedService = createSelector(
  getServicesEntities,
  getSelectedServiceId,
  (entities, serviceId): Service => {
    return entities[serviceId];
  }
);

// Get Services 'In Service'
export const getServicesInService = createSelector(getAllServices, (services): Service[] => {
  const inService = services.filter((x) => {
    if (x.Status__c === 'In Service' || (x.Status__c === 'New' && x.Activation_Pending__c === true)) {
      return x;
    }
  });

  return inService.sort(sortServicesByName);
});

export const getActiveServices = createSelector(getAllServices, (services): Service[] => {
  const activeServices = services.filter((x) => x.Status__c === 'In Service');
  return activeServices.sort(sortServicesByName);
});

// Get Services To Disconnect
export const getServicesToDisconnect = createSelector(getServicesInService, (services): MappedDisconnectService[] => {
  const mappedServices = services.map((s) => {
    return {
      id: s.Id,
      nit: s.Name,
      product: s.RecordType.Name,
      locationId: s.Z_Location__c,
      locationName: s.Z_Location__r.Name,
      disabled: false
    };
  });

  return mappedServices;
});

export const getLocationsFilterOptions = createSelector(getAllServices, (services) => {
  return services
    .map((a) => a.Z_Location__r.Name)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((service1, service2) => (service1 < service2 ? -1 : service1 > service2 ? 1 : 0));
});

export const getServiceFilterOptions = createSelector(getAllServices, (services) => {
  return services
    .map((a) => a.RecordType.Name)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((service1, service2) => (service1 < service2 ? -1 : service1 > service2 ? 1 : 0));
});

export const getStatusFilterOptions = createSelector(getAllServices, (services) => {
  return services
    .map((a) => a.Status__c)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((service1, service2) => (service1 < service2 ? -1 : service1 > service2 ? 1 : 0));
});



export const getTotalActiveServiceLocations = createSelector(getActiveServices, (services): number => {
  const uniqueLocations = services
    .map((a) => a.Z_Location__r.Name)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();
  return uniqueLocations.length;
});