import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewChild, 
  Output,
  EventEmitter,
} from '@angular/core';

import {
  Ticket,
} from 'src/app/models/ticket.model';
import { Subscription } from 'rxjs';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'ticket-top',
  templateUrl: 'ticket-top.component.html',
  styleUrls: ["ticket-top.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTopComponent{
  @Output() timeRangeChanged = new EventEmitter();
  @Input() tickets: Ticket[];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public iconDetail = 'assignment';

  subscription: Subscription;
  ordersSubscription: Subscription;

  browserSize: string;

  activeOrderSubscription: Subscription;
  orderResCurr: any;

  onTimeRangeChanged(timeRange: string) {
    this.timeRangeChanged.emit(timeRange);
  }
}

