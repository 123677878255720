import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-activation-time-dropdown',
  templateUrl: './activation-time-dropdown.component.html',
  styleUrls: ['./activation-time-dropdown.component.scss']
})
export class ActivationTimeDropdown implements OnInit {
  public selectedOption = '6h'; // Default is "6 hours"
  public optionList = [
    { value: '6h', viewValue: 'Last 6 hours' },
    { value: '12h', viewValue: 'Last 12 hours' },
    { value: '1m', viewValue: 'Last 1 month' },
    { value: '3m', viewValue: 'Last 3 months' },
    { value: '6m', viewValue: 'Last 6 months' }
  ];

  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.emitSelectedOption(); // Emit default value on initialization
  }

  // Method to emit the selected option
  emitSelectedOption(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }

  // Called when the selection changes
  onSelectionChange(value: string): void {
    this.selectedOption = value;
    this.emitSelectedOption();
  }
}