import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
 
@Component({
  selector: 'app-service-progress-bar',
  templateUrl: './service-progress-bar.component.html',
  styleUrls: ['./service-progress-bar.component.scss']
})

export class ServiceProgressBarComponent implements OnChanges {
  @Input() inService: number = 0;
  @Input() pending: number = 0;
  
  total: number = 0;
  inServicePercentage: number = 0
  pendingPercentage: number = 0;
 
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inService || changes.pending) {
      this.calculatePercentages();
    }
  }

  private calculatePercentages(): void {
    this.total = this.inService + this.pending;

    if (this.total > 0) {
      this.inServicePercentage = (this.inService / this.total) * 100;
      this.pendingPercentage = (this.pending / this.total) * 100;
    }
  }
}