import { Action } from '@ngrx/store';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';

// load service order forms
export const LOAD_SERVICE_ORDER_FORMS = '[Service Order Forms] Load Forms';
export const LOAD_SERVICE_ORDER_FORMS_FAIL = '[Service Order Forms] Load Forms Fail';
export const LOAD_SERVICE_ORDER_FORMS_SUCCESS = '[Service Order Forms] Load Forms Success';
export const SET_SELECTED_SOF = '[Service Order Forms] Set Selected SOF';

export class LoadServiceOrderForms implements Action {
  readonly type = LOAD_SERVICE_ORDER_FORMS;
}

export class LoadServiceOrderFormsFail implements Action {
  readonly type = LOAD_SERVICE_ORDER_FORMS_FAIL;
  constructor(public payload: any) {}
}

export class LoadServiceOrderFormsSuccess implements Action {
  readonly type = LOAD_SERVICE_ORDER_FORMS_SUCCESS;
  constructor(public payload: ContentDocumentLink[]) {}
}

export class SetSelectedSOF implements Action {
  readonly type = SET_SELECTED_SOF;
  constructor(public payload: string) {}
}

// action types
export type ServiceOrderFormsAction =
  | LoadServiceOrderForms
  | LoadServiceOrderFormsFail
  | LoadServiceOrderFormsSuccess
  | SetSelectedSOF;
