<section class="card">
  <div class="widget-container">
    <div class="widget-title">
      <h3>Circuit Milestones ({{ total }} in progress)</h3>
      <h4>Excludes related Voice and SD-WAN orders</h4>
    </div>

    <div class="loading-spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="chart" *ngIf="!loading">
      <canvas
        baseChart
        id="circuit-milestones-chart"
        height="380px"
        [data]="barChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="barChartType"
      ></canvas>
    </div>
  </div>
</section>
