<div class="number">
  <div class="label">Ticket #</div>
  {{ ticket.CaseNumber || '---' }}
</div>
<div class="type-status">
  <div class="type">
    <div class="label">Title</div>
    <div class="text">
      {{ ticket.Subject || ticket.Portal_Ticket_Type__c || '---' }}
    </div>
  </div>
  <div class="status-date">
    <div class="label">
      Status<ng-container *ngIf="browserSize === 'medium' || browserSize === 'small'">:</ng-container>
    </div>
    <div class="status-container">
      <span *ngIf="ticket.Status !== 'Closed'" class="open">Open</span>
      <span *ngIf="ticket.Status === 'Closed'" class="closed">Closed</span>
      <div *ngIf="ticket.Status !== 'Closed'" class="date">
        opened {{ ticket.CreatedDate | date: 'h:mma' }}<br />
        {{ ticket.CreatedDate | date: 'MMM d, y' }}
      </div>
      <div *ngIf="ticket.Status === 'Closed'" class="date">
        closed {{ ticket.ClosedDate | date: 'h:mma' }}<br />
        {{ ticket.ClosedDate | date: 'MMM d, y' }}
      </div>
    </div>
  </div>
</div>
