import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromReducer from '../reducers/invoice-attachments.reducer';

export const getInvoiceAttachmentsState = createSelector(
  fromFeature.getFeatureBillingState,
  (state: fromFeature.BillingState) => state.invoiceAttachments
);

export const getInvoiceAttachmentsEntities = createSelector(
  getInvoiceAttachmentsState,
  fromReducer.getInvoiceAttachmentEntities
);

export const getInvoiceAttachmentsLoaded = createSelector(
  getInvoiceAttachmentsState,
  fromReducer.getInvoiceAttachmentLoaded
);
export const getInvoiceAttachmentsLoading = createSelector(
  getInvoiceAttachmentsState,
  fromReducer.getInvoiceAttachmentLoading
);
export const getSelectedInvoiceAttachment = createSelector(
  getInvoiceAttachmentsState,
  fromReducer.getSelectedInvoiceAttachment
);
export const getInvoiceAttachmentFilterNumber = createSelector(
  getInvoiceAttachmentsState,
  fromReducer.getInvoiceAttachmentFilterNumber
);

export const getAllInvoiceAttachments = createSelector(
  getInvoiceAttachmentsEntities,
  getInvoiceAttachmentFilterNumber,
  (entities, size) => {
    return Object.keys(entities)
      .slice(0, size)
      .map((id) => entities[id]);
  }
);
