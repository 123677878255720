import { Component, ViewChild, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

interface NetworkMetrics {
  device_name: string;
  source: string;
  time_frame: string;
  latency: number;
  jitter: number;
  packet_loss: number;
  data_usage: number;
  data_utilization: number;
  link_name: string;
  application: string;
}

@Component({
  selector: 'app-metric-details',
  styleUrls: ['./metric-details.component.scss'],
  templateUrl: './metric-details.component.html'
})
export class MetricDetails implements OnInit, OnChanges{

  private deviceColumnsAndFiltersMap: Map<string, string[]> = new Map([
    ['columns', ['device_name', 'source', 'time_frame', 'data_usage']],
    ['filters', ['deviceNameFilter', 'sourceFilter', 'timeframeFilter', 'dataUsageFilter']],
    ['filters2', ['deviceNameFilter2', 'sourceFilter2', 'timeframeFilter2', 'dataUsageFilter2']]
  ]);

  private applicationColumnsAndFiltersMap: Map<string, string[]> = new Map([
    ['columns', ['application', 'source', 'time_frame', 'data_usage']],
    ['filters', ['applicationFilter', 'sourceFilter', 'timeframeFilter', 'dataUsageFilter']],
    ['filters2', ['applicationFilter2', 'sourceFilter2', 'timeframeFilter2', 'dataUsageFilter2']]
  ]);

  private connectionColumnsAndFiltersMap: Map<string, string[]> = new Map([
    ['columns', ['link_name', 'source', 'time_frame', 'data_utilization', 'data_usage', 'packet_loss', 'latency', 'jitter']],
    ['filters', ['linkNameFilter', 'sourceFilter', 'timeframeFilter', 'dataUtilizationFilter' , 'dataUsageFilter', 'packetLossFilter', 'latencyFilter', 'jitterFilter']],
    ['filters2', ['linkNameFilter2', 'sourceFilter2', 'timeframeFilter2', 'dataUtilizationFilter2' , 'dataUsageFilter2', 'packetLossFilter2', 'latencyFilter2', 'jitterFilter2']]
  ]);

  private displayColumnsAndFiltersByDimension: Map<string, Map<string, string[]>> = new Map([
    ['device', this.deviceColumnsAndFiltersMap],
    ['application', this.applicationColumnsAndFiltersMap],
    ['connection', this.connectionColumnsAndFiltersMap]
  ]);

  displayedColumns: string[] = [];

  selectedDate: Date | null = null;
  showFilters = false; // Initial state, filters are hidden
  filters = [];
  filters2 = [];
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  defaultPageSizeOption: number = 10;

   // Data source for the table
  @Input() dataSource: MatTableDataSource<NetworkMetrics>;
  @Input() loading: boolean = false; // Loading state
  @Input() isNoContent = false; // Display flag for content availability
  @Input() tableDimension: string = "device"; //parameter for displaying different columns in the table;

  @ViewChild(MatSort, { static: true }) sort: MatSort;          // Sorting reference
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;  // Pagination reference

  // Object to hold filter values for each column
  filterValues: { [key: string]: string } = {
    device_name: '',
    time_frame: '',
    latency: '',
    jitter: '',
    packet_loss: '',
    data_usage: '',
    data_utilization: '',
    application: '',
    link_name: '',
    source: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  constructor() { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource && this.dataSource) {
      this.init();
    }
  }

  init() {
    if (this.tableDimension) {
      this.displayedColumns = this.displayColumnsAndFiltersByDimension.get(this.tableDimension).get('columns');
      this.filters = this.displayColumnsAndFiltersByDimension.get(this.tableDimension).get('filters');
      this.filters2 = this.displayColumnsAndFiltersByDimension.get(this.tableDimension).get('filters2');
    }
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property === 'time_frame') {
          return new Date(item.time_frame).getTime();
        }
        else if (['latency', 'jitter'].includes(property)) {
          return new Number(item[property]);
        }
        else if (['packet_loss'].includes(property)) {
          return new Number(item.packet_loss);
        }
        else if (['data_usage'].includes(property)) {
          return new Number(item.data_usage);
        }
        else if (['data_utilization'].includes(property)) {
          return new Number(item.data_utilization);
        }
        return item[property];
      };
      this.dataSource.paginator = this.paginator;

      // Custom filter predicate to filter by multiple columns
      this.dataSource.filterPredicate = this.createFilter();
      this.clearFilter();
    }
  }

  // Method to update the filter values and apply the filter
  applyFilter(event: Event, column: string) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.filterValues[column] = filterValue;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  applyPicklistFilter(filterValue: string | null, column: string) {
    this.filterValues[column] = filterValue ? filterValue.trim().toLowerCase() : '';
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  // Custom filter function to filter data based on multiple columns
  createFilter(): (data: NetworkMetrics, filter: string) => boolean {
    return (data: NetworkMetrics, filter: string): boolean => {
      const searchTerms = JSON.parse(filter);
      const timestamp = new Date(data.time_frame);
      return Object.keys(searchTerms).every((key) => {

        const filterValue = searchTerms[key];

        if (!filterValue) return true; // Skip if no filter value

        // Combine start date and time for filtering
        if (key === 'startDate' || key === 'startTime') {
          const startDateTime = searchTerms.startDate
            ? new Date(`${new Date(searchTerms.startDate).toLocaleDateString("en-US")} ${searchTerms.startTime || '12:00 AM'}`)
            : null;
          if (startDateTime) {
            return timestamp >= startDateTime;
          }
        }

        // Combine end date and time for filtering
        if (key === 'endDate' || key === 'endTime') {
          const endDateTime = searchTerms.endDate
            ? new Date(`${new Date(searchTerms.endDate).toLocaleDateString("en-US")} ${searchTerms.endTime || '11:59 PM'}`)
            : null;
          if (endDateTime) {
            return timestamp <= endDateTime;
          }
        }

        // Handle numeric values for comparison
        if (['latency', 'jitter', 'packet_loss', 'data_usage', 'data_utilization'].includes(key)) {
          return searchTerms[key] === '' || data[key as keyof NetworkMetrics].toString().indexOf(searchTerms[key]) !== -1;
        }
        return searchTerms[key] === '' || searchTerms[key] === null || data[key as keyof NetworkMetrics].toString().toLowerCase().indexOf(searchTerms[key]) !== -1;
      });
    };
  }

  clearFilter() {
    this.filterValues = {
      device_name: '',
      source: '',
      latency: '',
      jitter: '',
      packet_loss: '',
      dataUsage: '',
      application: '',
      data_utilization: '',
      link_name: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  // Apply the date filter based on start and end dates
  applyDateFilter() {
    if (this.filterValues.startDate && !this.filterValues.startTime) {
      this.filterValues.startTime = this.getCurrentTimeIn12HourFormat();
    }
    if (this.filterValues.endDate && !this.filterValues.endTime) {
      this.filterValues.endTime = this.getCurrentTimeIn12HourFormat();
    }
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  // This method retrieves the current local time, formats it into the 12-hour clock format
  private getCurrentTimeIn12HourFormat(): string {
    const now = new Date();

    // Get hours and minutes
    let hours = now.getHours();
    const minutes = now.getMinutes();

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 -> 12)

    // Format minutes to always have two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into 12-hour format string
    return `${hours}:${formattedMinutes} ${ampm}`;
  }
}
