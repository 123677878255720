import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-sorting-dropdown",
  templateUrl: "./sorting-dropdown.component.html",
  styleUrls: ["./sorting-dropdown.component.scss"],
})
export class SortingDropdown implements OnInit {
  public selectedOption = "DESC"; // Default is "6 hours"
  public optionList = [
    { value: "DESC", viewValue: "Top 10 Performing" },
    { value: "ASC", viewValue: "Bottom 10 Performing" },
  ];

  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.emitSelectedOption(); // Emit default value on initialization
  }

  // Method to emit the selected option
  emitSelectedOption(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }

  // Called when the selection changes
  onSelectionChange(value: string): void {
    this.selectedOption = value;
    this.emitSelectedOption();
  }
}
