import { createSelector } from '@ngrx/store';

import { Ticket } from 'src/app/models/ticket.model';
import * as fromRouters from '../reducers/router.reducer';
import * as fromReducer from '../reducers/tickets.reducer';

export const getTicketsEntities = createSelector(fromReducer.getTicketFeatureState, fromReducer.getTicketsEntities);

export const getAllTickets = createSelector(getTicketsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getTicketsLoaded = createSelector(fromReducer.getTicketFeatureState, fromReducer.getTicketsLoaded);
export const getTicketsLoading = createSelector(fromReducer.getTicketFeatureState, fromReducer.getTicketsLoading);
export const getTotalOpenTickets = createSelector(fromReducer.getTicketFeatureState, fromReducer.getTotalOpenTickets);

// ROUTER SELECTORS
export const getCurrentRouterTicket = createSelector(
  getAllTickets,
  fromRouters.getRouterFeatureState,
  (tickets, router): Ticket => {
    if (router.state) {
      const ticket = tickets.find((x) => x.Id === router.state.params.ticketId);
      return ticket;
    }
  }
);

// GET SELECTED
export const getSelectedTicketId = createSelector(fromReducer.getTicketFeatureState, fromReducer.getTicketSelected);
export const getSelectedTicket = createSelector(
  getTicketsEntities,
  getSelectedTicketId,
  (entities, ticketId): Ticket => {
    return entities[ticketId];
  }
);
