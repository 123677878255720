<div class="top-header-wrapper">
  <ng-container *ngIf="browserSize$ | async as browserSize">
    <totals-display
      *ngIf="browserSize !== 'small'"
      [activeOrders]="activeOrders$ | async"
      [totalActiveLocations]="totalActiveLocations$ | async"
    ></totals-display>
  </ng-container>

  <app-active-order-chart
    [data]="orderResCurr"
    [total]="40"
    [order]="activeOrders$ | async"
    [loading]="false"
  ></app-active-order-chart>
</div>
