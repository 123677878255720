/* eslint-disable no-shadow */
import { Payment } from 'src/app/models/payment.model';
import * as fromActions from '../actions';

// State for Payments
export interface PaymentState {
  entities: { [id: string]: Payment };
  loaded: boolean;
  loading: boolean;
  selectedPaymentId: string;
  filterNumber: number;
}

export const initialState: PaymentState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedPaymentId: null,
  filterNumber: 6
};

export function reducer(state = initialState, action: fromActions.PaymentsAction): PaymentState {
  switch (action.type) {
    case fromActions.LOAD_PAYMENTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_PAYMENTS_SUCCESS: {
      const invoices = action.payload;
      let entities = {};

      if (invoices !== null) {
        entities = invoices.reduce(
          (entities: { [id: string]: Payment }, form) => {
            return {
              ...entities,
              [form.Id]: form
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_PAYMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromActions.SET_PAYMENTS_FILTER_NUMBER: {
      const selectedNumber = action.payload;

      return {
        ...state,
        filterNumber: selectedNumber
      };
    }

    case fromActions.SET_SELECTED_PAYMENT: {
      const currentId = action.payload;

      return {
        ...state,
        selectedPaymentId: currentId
      };
    }

    default: {
      return state;
    }
  }
}

export const getPaymentsEntities = (state: PaymentState) => state.entities;
export const getPaymentsLoading = (state: PaymentState) => state.loading;
export const getPaymentsLoaded = (state: PaymentState) => state.loaded;
export const getPaymentsFilterNumber = (state: PaymentState) => state.filterNumber;
export const getPaymentSelected = (state: PaymentState) => state.selectedPaymentId;
