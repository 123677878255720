import { Component, Input, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
@Component({
  selector: 'service-type-chart',
  templateUrl: './service-type-chart.component.html',
  styleUrls: ['./service-type-chart.component.scss']
})
export class ServiceTypeChartComponent {
  @Input() data: { [key: string]: number };
  loading: boolean;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  backgroundColor: string[];
  public pieChartType: ChartType = 'pie' as ChartType;
  public pieChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        maxWidth: 260,
        display: true,
        position: 'right',
        labels: {
          color: 'white',
          boxWidth: 20,
          font: {
            size: 18
          },
          boxHeight: 20
        }
      },
    },
    layout: {
      padding: {
        top: 5,
        bottom: 5,
        left: 5,
        right: 5
      }
    },
  };
  public pieChartData: ChartData<'pie'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Total',
        borderWidth: 1,
        hoverBorderWidth: 1,
        backgroundColor: ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64','#ff742e']
      }
    ]
  };
  ngOnInit() {
    this.loading = true;
  }
  ngAfterViewChecked(){
    this.loading = false;
  }
  ngOnChanges(): void {
    if (this.data && Object.keys(this.data).length > 0) {
      this.setChartData();
    }
  }
  private setChartData() {
    this.pieChartData.labels = Object.keys(this.data);
    this.pieChartData.datasets[0].data = Object.values(this.data);
    this.chart?.update();
  }
}