import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // eslint-disable-next-line class-methods-use-this
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('mynitel_auth_token');
    const resetToken = localStorage.getItem('mynitel_reset_token');
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          'Cache-control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '-1'
        }
      });
      return next.handle(req);
    }
    if (resetToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${resetToken}`,
          'Content-Type': 'application/json',
          'Cache-control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '-1'
        }
      });
      localStorage.removeItem('mynitel_reset_token');
      return next.handle(req);
    }
    return next.handle(req);
  }
}
