import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NitelComponent } from 'src/app/models/nitel-component.model';

@Component({
  selector: 'app-service-billing',
  templateUrl: './service-billing.component.html',
  styleUrls: ['./service-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBillingComponent {
  @Input() components: NitelComponent[];
}
