import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';

import * as fromInvoices from './invoices.reducer';
import * as fromInvoiceAttachments from './invoice-attachments.reducer';
import * as fromOrders from './orders.reducer';
import * as fromPayments from './payments.reducer';
import * as fromSOF from './sof.reducer';

import * as fromRouterReducer from './router.reducer';
import * as fromBrowser from './browser.reducer';

// Representation of the entire app state
// Extended by lazy loaded modules
export interface State {
  router: fromRouter.RouterReducerState<fromRouterReducer.RouterStateUrl>;
  browser: fromBrowser.BrowserState;
}

export const appReducer: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  browser: fromBrowser.reducer
};

// Orders state
export interface OrdersState {
  orders: fromOrders.OrderState;
  serviceOrderForms: fromSOF.SOFState;
}

export const ordersReducers: ActionReducerMap<OrdersState> = {
  orders: fromOrders.reducer,
  serviceOrderForms: fromSOF.reducer
};

export const getFeatureOrdersState = createFeatureSelector<OrdersState>('orders');

// Billing state
export interface BillingState {
  invoices: fromInvoices.InvoiceState;
  payments: fromPayments.PaymentState;
  invoiceAttachments: fromInvoiceAttachments.InvoiceAttachmentState;
}

export const billingReducers: ActionReducerMap<BillingState> = {
  invoices: fromInvoices.reducer,
  payments: fromPayments.reducer,
  invoiceAttachments: fromInvoiceAttachments.reducer
};

export const getFeatureBillingState = createFeatureSelector<BillingState>('billing');
