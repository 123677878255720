import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user.model';
import { api } from 'src/app/models/api-url.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private apiService: ApiService) {}

  getContacts(): Observable<User[]> {
    return this.apiService.get(`${api.contactsURL}`);
  }

  getLoggedInContact(): Observable<User> {
    return this.apiService.get(`${api.loggedInContactURL}`);
  }
}
