<section class="login">
  <div class="login-form-wrapper">
    <div class="logo">Nitel Logo</div>
    <div *ngIf="!hasError; else notAuthorized" class="split-row login-form">
      <h1>You are about to impersonate...</h1>
      <div class="loading-indicator">
        <mat-spinner [diameter]="100"></mat-spinner>
      </div>
    </div>

    <ng-template #notAuthorized>
      <div class="result-message">
        <div class="message">
          You are not authorized to impersonate. Please go through the proper channels to impersonate a user.
        </div>
      </div>
    </ng-template>
  </div>
</section>
