<div class="service-status-content">
    <div class="service-cta">
        <div class="total-wrapper">
            <div class="number">{{ totalServices }}</div>
            <div class="total-text">
            Total Active
            <span>Services</span>
            </div>
        </div>
        <div class="total-locations">Spanning {{ totalLocations }} locations</div>
    </div>

    <app-service-progress-bar [inService]="inServiceServices" [pending]="pendingServices"></app-service-progress-bar>
</div>

<div class="service-pie-chart"><service-type-chart [data]="serviceTypeObject"></service-type-chart></div>