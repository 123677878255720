import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

import { WidgetDirective } from '../widget.directive';
import { widgetTypes } from '../widget-types';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardWidgetComponent implements OnInit {
  @Input() widgetType: string;

  @ViewChild(WidgetDirective, { static: true }) widgetDirective: WidgetDirective;

  constructor(
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    const dynamicComponent = widgetTypes[this.widgetType].component;
    this.ngxPermissionsService.getPermissions();

    if (dynamicComponent) {
      const { viewContainerRef } = this.widgetDirective;
      viewContainerRef.clear();
      viewContainerRef.createComponent(dynamicComponent);
    }
  }
}
