import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noc-policy',
  templateUrl: './noc-policy.component.html',
  styleUrls: ['./noc-policy.component.scss']
})
export class NocPolicyComponent {
  // implements OnInit {
  // constructor() {}
  // ngOnInit(): void {}
}
