<section class="card">
	<div class="widget-container {{ bulletClass }}">
		<div class="bullet-box">
			<div class="loading-spinner" *ngIf="loading">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
			<div *ngIf="!loading" class="bullet"></div>
		</div>
		<div class="status">
			<span class="value">{{ value == null ? '...' : value }} Circuit{{ (value == 1 ? '' : 's') }}</span>
			<span class="title">{{ title }}</span>
		</div>
	</div>
</section>
