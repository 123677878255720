import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'csv-download',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<button class="csv-download" (click)="downloadCSV(csvData)">
    <div class="custom-icon csv">csv icon</div>
  </button>`
})
export class CSVDownloadComponent {
  @Input() csvData: any[];
  @Input() fileName: string;

  constructor(private papa: Papa) {}

  downloadCSV(data) {
    const csvFile: string = this.fileName;
    const csvUnparse = this.papa.unparse(data);
    const csvBlob = new Blob([csvUnparse], { type: 'text/csv;charset=utf-8;' });
    let csvURL = window.URL.createObjectURL(csvBlob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', csvFile);
    tempLink.click();
  }
}
