<div class="ticket-count-dropdown">
  <mat-form-field>
    <mat-label>Select a time range</mat-label>
    <mat-select [(value)]="selectedTimeRange" (selectionChange)="onTimeRangeChange($event)">
      <mat-option value="allTime" >All Time</mat-option>
      <mat-option value="6hours">Last 6 hours</mat-option>
      <mat-option value="1day">Last 24 hours</mat-option>
      <mat-option value="1month">Last 30 days</mat-option>
      <mat-option value="3months">Last 90 days</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="ticket-count-chart-container">
  <div class="ticket-box proactive">
    <div class="ticket-count-wrapper">
      <div class="ticket-count-number">
        {{ ticketTypeCount?.proactive || 0 }}
      </div>
      <div class="ticket-count-label">
        Proactive
        <span>Ticket{{ ticketTypeCount?.proactive === 1 ? '' : 's' }}</span>
      </div>
    </div>
    <div class="ticket-averages">

    <div>Average Pickup Time: <span>{{ proactiveAverageTimeToFirstTouch }}</span></div>
    <div>Average Repair Time: <span>{{ proactiveAverageTimeToRepair }}</span></div>
    </div>
  </div>
  <div class="ticket-box non-proactive">
    <div class="ticket-count-wrapper">
      <div class="ticket-count-number">
        {{ ticketTypeCount?.nonProactive || 0 }}
      </div>
      <div class="ticket-count-label">Non-Proactive 
        <span>Ticket{{ ticketTypeCount?.nonProactive === 1 ? '' : 's' }}</span>
      </div>
    </div>
    <div class="ticket-averages">

    <div>Average NOC Pickup Time: <span>{{ nonProactiveAverageTimeToFirstTouch }}</span></div>
    <div>Average NOC Repair Time: <span>{{ nonProactiveAverageTimeToRepair }}</span></div>
    </div>
  </div>
</div>
