<div class="order-detail">
	<h4>Key Dates</h4>
	<span *ngIf="upcoming" class="ul-title">Upcoming</span>
	<ul *ngIf="upcoming" class="upcoming">
		<li *ngFor="let i of upcoming" class="upcoming-ul">
			<mat-icon [inline]="true">{{ iconUpcoming }}</mat-icon>
			<span class="info-title">
				{{ i.title }}
			</span>
			<span class="info-value">{{ i.value || '-' }}</span>
		</li>
	</ul>
	<span *ngIf="past" class="ul-title">Past</span>
	<ul *ngIf="past" class="past-ul">
		<li *ngFor="let i of past">
			<mat-icon [inline]="true">{{ iconPast }}</mat-icon>
			<span class="info-title">
				{{ i.title }}
			</span>
			<span class="info-value">{{ i.value | date: 'MM/dd/yyyy' }}</span>
		</li>
	</ul>

</div>
