<div class="data-container">
  <div class="header">Highlighted Network Measurements Per Severity</div>

  <div class="data-item" [ngClass]="'category-' + category">
    <span class="data-label">Data Utilization:</span>
    <span class="data-value">{{ dataUtilization }}%</span>
  </div>

  <div class="data-item" [ngClass]="'category-' + category">
    <span class="data-label">Latency:</span>
    <span class="data-value">{{ latency }}ms</span>
  </div>

  <div class="data-item" [ngClass]="'category-' + category">
    <span class="data-label">Jitter:</span>
    <span class="data-value">{{ jitter }}ms</span>
  </div>

  <div class="data-item" [ngClass]="'category-' + category">
    <span class="data-label">Packet Loss:</span>
    <span class="data-value">{{ packetLost }}%</span>
  </div>
</div>
