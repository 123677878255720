import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Ticket, TicketType, getTicketType } from "src/app/models/ticket.model";
@Component({
  selector: "ticket-count-chart",
  styleUrls: ["./ticket-count-display.component.scss"],
  templateUrl: "./ticket-count-chart.component.html",
})

export class TicketCountChartComponent implements OnChanges {
  @Output() timeRangeChanged = new EventEmitter();
  @Input() tickets: Ticket[];
  public ticketTypeCount: any;
  public averageTimeToFirstTouch: string;
  public averageTimeToRepair: string;
  filteredTickets: Ticket[];
  selectedTimeRange: string;
  proactiveTickets: number;
  nonProactiveTickets: number;
  nonProactiveAverageTimeToRepair: string;
  proactiveAverageTimeToRepair: string;
  nonProactiveAverageTimeToFirstTouch: string;
  proactiveAverageTimeToFirstTouch: string;
  timeRange: string;

  ngOnInit() {
    if (this.tickets?.length) {
      this.updateTicketCounts();
    }
    this.filteredTickets = this.tickets;
  }

  ngOnChanges(): void {
    this.filteredTickets = this.tickets;
    this.updateTicketCounts();
  }

  onTimeRangeChange(event: any): void {
    this.selectedTimeRange = event.value;
    this.updateTicketCounts();
    this.timeRangeChanged.emit(event.value);
  }

  updateTicketCounts(): void {
    this.ticketTypeCount = this.countProactiveAndNonProactiveTickets(
      this.filteredTickets
    );
    const proactiveTickets = this.filteredTickets.filter(
      (ticket) =>
        this.getTicketTypeString(getTicketType(ticket)) === "Proactive"
    );
    const nonProactiveTickets = this.filteredTickets.filter(
      (ticket) =>
        this.getTicketTypeString(getTicketType(ticket)) !== "Proactive" &&
        this.getTicketTypeString(getTicketType(ticket)) !== null &&
        this.getTicketTypeString(getTicketType(ticket)) !== undefined
    );

    const proactiveAverageTimeToFirstTouch =
      this.calculateAverageTimeToFirstTouch(proactiveTickets);
    this.proactiveAverageTimeToFirstTouch =
      proactiveAverageTimeToFirstTouch !== null
        ? this.convertMillisecondsToDaysAndHours(
            proactiveAverageTimeToFirstTouch
          )
        : "N/A";

    const nonProactiveAverageTimeToFirstTouch =
      this.calculateAverageTimeToFirstTouch(nonProactiveTickets);
    this.nonProactiveAverageTimeToFirstTouch =
      nonProactiveAverageTimeToFirstTouch !== null
        ? this.convertMillisecondsToDaysAndHours(
            nonProactiveAverageTimeToFirstTouch
          )
        : "N/A";

    const proactiveAverageTimeToRepair =
      this.calculateAverageTimeToRepair(proactiveTickets);
    this.proactiveAverageTimeToRepair = proactiveAverageTimeToRepair;
    const nonProactiveAverageTimeToRepair =
      this.calculateAverageTimeToRepair(nonProactiveTickets);
    this.nonProactiveAverageTimeToRepair = nonProactiveAverageTimeToRepair;
  }

  calculateAverageTimeToRepair(tickets: Ticket[]): string {
    let totalTime = 0;
    let count = 0;

    tickets.forEach((ticket) => {
      const type = this.getTicketTypeString(getTicketType(ticket));
      if (
        type !== null &&
        type !== undefined &&
        ticket?.Time_to_Repair_Days__c !== null &&
        ticket?.Time_to_Repair_Days__c !== undefined
      ) {
        totalTime += ticket.Time_to_Repair_Days__c;
        count++;
      }
    });

    if (count === 0) {
      return "N/A";
    }

    const averageTime = totalTime / count;
    return this.convertDaysToDaysAndHours(averageTime);
  }

  convertDaysToDaysAndHours(days: number): string {
    const wholeDays = Math.floor(days);
    const remainingHours = (days - wholeDays) * 24;
    const hours = Math.floor(remainingHours);
    const minutes = Math.floor((remainingHours - hours) * 60);

    if (wholeDays === 0) {
      return `${hours} hr, ${minutes} min`;
    } else if (hours === 0) {
      return `${wholeDays} days`;
    } else {
      return `${wholeDays} days, ${hours} hr, ${minutes} min`;
    }
  }

  convertMillisecondsToDaysAndHours(milliseconds: number): string {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return `${days} days, ${hours} hr, ${minutes} min`;
  }
  
  calculateAverageTimeToFirstTouch(tickets: Ticket[]): number | null {
    let totalTime = 0;
    let count = 0;

    tickets.forEach((ticket) => {
      if (ticket.First_touch__c) {
        const createTime = new Date(ticket.CreatedDate).getTime();
        const firstTouchTime = new Date(ticket.First_touch__c).getTime();
        const timeDiff = firstTouchTime - createTime;
        totalTime += timeDiff;
        count++;
      }
    });

    if (count === 0) {
      return null;
    }

    return totalTime / count;
  }

  countProactiveAndNonProactiveTickets(tickets: Ticket[]): {
    proactive: number;
    nonProactive: number;
  } {
    let proactiveCount = 0;
    let nonProactiveCount = 0;

    tickets.forEach((ticket) => {
      const type = this.getTicketTypeString(getTicketType(ticket));
      if (type === "Proactive") {
        proactiveCount++;
      } else if (type !== null && type !== undefined) {
        nonProactiveCount++;
      }
    });

    return { proactive: proactiveCount, nonProactive: nonProactiveCount };
  }

  getTicketTypeString(ticketType: TicketType): string {
    switch (ticketType) {
      case TicketType.Care:
        return "Care";
      case TicketType.Finance:
        return "Finance";
      case TicketType.Maintenance:
        return "Maintenance";
      case TicketType.Proactive:
        return "Proactive";
      case TicketType.Support:
        return "Support";
    }
  }
}
