<section>
  <h1>Create <span class="emphasis">Disconnect</span> Ticket</h1>

  <form
    [formGroup]="disconnectTxGroup"
    *ngIf="disconnectTxGroup"
    [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
    autocomplete="off"
  >
    <ng-container *ngIf="disconnectReasons.length > 0">
      <h2>General Information</h2>
      <h3>Reason for Disconnect</h3>
      <mat-form-field floatLabel="never" (keyup)="canSubmit()">
        <mat-label>Please select</mat-label>
        <mat-select formControlName="reason" (keyup)="canSubmit()">
          <mat-option *ngFor="let reason of disconnectReasons" [value]="reason">
            {{ reason }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="never" *ngIf="disconnectTxGroup.value.reason === 'Other'">
        <mat-label>Enter reason</mat-label>
        <input matInput type="text" formControlName="otherReasonDetails" (keyup)="canSubmit()" />
      </mat-form-field>
    </ng-container>

    <h2>Contact Information</h2>
    <app-primary-reporter
      [formRef]="disconnectTxGroup"
      [reporters]="primaryReporters"
      [altContact]="false"
      (usersLoaded)="usersLoaded()"
    >
    </app-primary-reporter>

    <h2>Service Information</h2>
    <app-multiple-service-location
      [formRef]="disconnectTxGroup"
      [inlineErrors]="inlineErrors"
      [selectedServiceId]="selectedServiceId"
      (servicesLoaded)="servicesLoaded()"
      (keyup)="canSubmit()"
    >
    </app-multiple-service-location>

    <h3>Additional comments</h3>
    <mat-form-field class="custom-textarea" floatLabel="never">
      <mat-label>Any additional feedback or comments you'd like to give?</mat-label>
      <textarea matInput formControlName="comments" rows="5"></textarea>
    </mat-form-field>

    <div class="server-error" *ngIf="submissionError">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ errorMessage }}</div>
    </div>

    <div class="form-error" *ngIf="(inlineErrors | json) !== '{}'">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">Please fill out the required field(s) circled in red.</div>
    </div>

    <div class="loading-indicator" *ngIf="isSubmitting">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <button
      class="button submit-button"
      (click)="submitTicket()"
      [disabled]="isLoadingServices || loadingUsers || isSubmitting"
    >
      <span>Submit ticket</span>
    </button>
  </form>
</section>
