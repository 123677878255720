
<div class="icon-list" *ngIf="tasks !== null">
  <div
    class="status preparing"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' && orderStatus.preparingOrder.status === false) ||
        (orderType === 'Disconnect' && disconnectOrderStatus.requestReceived.status === false),
      completed:
        (orderType !== 'Disconnect' && orderStatus.preparingOrder.status === true) ||
        (orderType === 'Disconnect' && disconnectOrderStatus.requestReceived.status === true)
    }"
  >
    <div class="icon-wrapper">
      <div class="custom-icon preparing-white" *ngIf="orderType !== 'Disconnect'"></div>
      <div class="custom-icon survey-white" *ngIf="orderType === 'Disconnect'"></div>
    </div>
    <div class="status-label" *ngIf="orderType !== 'Disconnect'">{{ orderStatus.preparingOrder.title }}</div>
    <div class="status-label" *ngIf="orderType === 'Disconnect'">{{ disconnectOrderStatus.requestReceived.title }}</div>
  </div>
  <div class="divider"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.surveyDelivery.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === false &&
          disconnectOrderStatus.requestReceived.status === true),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.surveyDelivery.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true)
    }">
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
  <div
    class="status delivery"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.surveyDelivery.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === false &&
          disconnectOrderStatus.requestReceived.status === true),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true)
  }"
  >
    <div class="icon-wrapper">
      <div class="custom-icon survey-white" *ngIf="orderType !== 'Disconnect'"></div>
      <div class="custom-icon engineering-white" *ngIf="orderType === 'Disconnect'"></div>
    </div>
    <div class="status-label" *ngIf="orderType !== 'Disconnect'">{{ orderStatus.surveyDelivery.title }}</div>
    <div class="status-label" *ngIf="orderType === 'Disconnect'">{{ disconnectOrderStatus.disconnectOrder.title }}</div>
  </div>
  <div class="divider"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === false),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true)
    }">
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
  <div
    class="status provisioning"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === false),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true)
    }"
  >
    <div class="icon-wrapper">
      <div class="custom-icon engineering-white" *ngIf="orderType !== 'Disconnect'"></div>
      <div class="custom-icon mail-white" *ngIf="orderType === 'Disconnect'"></div>
    </div>
    <div class="status-label" *ngIf="orderType !== 'Disconnect'">{{ orderStatus.serviceProvisioning.title }}</div>
    <div class="status-label" *ngIf="orderType === 'Disconnect'">{{ disconnectOrderStatus.equipmentReturn.title }}</div>
  </div>
  <div class="divider"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true &&
          orderStatus.complete.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true &&
          orderStatus.complete.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true)
    }">
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
  <div
    class="status complete"
    [ngClass]="{
      active:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true &&
          orderStatus.complete.status === false) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true),
      completed:
        (orderType !== 'Disconnect' &&
          orderStatus.surveyDelivery.status === true &&
          orderStatus.preparingOrder.status === true &&
          orderStatus.serviceProvisioning.status === true &&
          orderStatus.complete.status === true) ||
        (orderType === 'Disconnect' &&
          disconnectOrderStatus.disconnectOrder.status === true &&
          disconnectOrderStatus.requestReceived.status === true &&
          disconnectOrderStatus.equipmentReturn.status === true)
    }"
  >
    <div class="icon-wrapper">
      <div class="custom-icon checkmark-white"></div>
    </div>
    <div class="status-label" *ngIf="orderType !== 'Disconnect'">{{ orderStatus.complete.title }}</div>
    <div class="status-label" *ngIf="orderType === 'Disconnect'">{{ disconnectOrderStatus.complete.title }}</div>
  </div>
</div>

<!-- <div class="icon-list error" *ngIf="!isLoading && !isLoaded">
  <p>Error: Unable to load the order's status progress. Try refreshing the page.</p>
</div> -->
