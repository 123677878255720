<div class="order-detail">
	<h4>{{ title }}</h4>
	<ul>
		<li *ngFor="let i of data">
			<mat-icon [inline]="true" *ngIf="i.icon">{{ i.icon }}</mat-icon>
			<span class="info-title">
				{{ i.title }}
			</span>
			<span class="info-value" *ngIf="(i.type != 'date')">{{ i.value }}</span>
			<span class="info-value" *ngIf="(i.type == 'date')">{{ i.value | date: 'yyyy-MM-dd' }}</span>
		</li>
	</ul>

</div>
