import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'MyNitel: Log In';

  passwordChanged = false;
  sessionExpired = false;
  loggedOut = false;
  loginFormGroup: UntypedFormGroup;
  usernameValid = false;

  isLoading = false;
  errorMessage: string;
  isLoading$: Observable<boolean>;
  errorMessage$: Observable<string>;
  subscription: Subscription;

  messages = {
    usernameInput: '',
    passwordInput: '',
    passwordChanged: 'Password changed!',
    sessionExpired: "Session expired. We've logged you out of your account.",
    loggedOut: 'You have successfully logged out.'
  };

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.loginFormGroup = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      toggle: new UntypedFormControl(true)
    });
  }

  ngOnInit() {
    this.passwordChanged = this.route.snapshot.queryParamMap.get('passwordChanged') === 'true';
    this.sessionExpired = this.route.snapshot.queryParamMap.get('logout') === 'expired';
    this.loggedOut = this.route.snapshot.queryParamMap.get('logout') === 'manual';
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#usernameId').focus();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  login(): void {
    if (this.loginFormGroup.valid) {
      const uid = this.loginFormGroup.controls.username.value;
      const password = this.loginFormGroup.controls.password.value;
      const rememberMe = this.loginFormGroup.controls.toggle.value;
      const regex = /(\s*$)|(^\s*)/g;
      const cleanUsername = uid.replace(regex, '');

      this.isLoading = true;
      this.subscription = this.authService.login(cleanUsername, password, rememberMe).subscribe(
        (data) => {
          this.isLoading = false;

          if (!data || !data.auth_token) {
            this.messages.usernameInput = data.usernameError ? data.usernameError : '';
            this.messages.passwordInput = data.passwordError ? data.passwordError : '';
            this.errorMessage = data.error ? data.error : '';
          }
          this.changeDetectorRef.detectChanges();
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          let invalidUserOrPasswordMessage = 'Incorrect username or password.';
          switch (error.status) {
            case 401: {
              // 401 if incorrect password
              this.messages.passwordInput = invalidUserOrPasswordMessage;
              break;
            }
            case 404: {
              // 404 if user not found
              this.messages.usernameInput = invalidUserOrPasswordMessage;
              break;
            }
            default: {
              this.errorMessage = "We can't log you in right now. Please try again later.";
              break;
            }
          }
          this.changeDetectorRef.detectChanges();
        }
      );
    } else {
      if (!this.loginFormGroup.controls.username.value) {
        this.messages.usernameInput = 'Please enter a username.';
      } else {
        this.messages.usernameInput = '';
      }
      this.messages.passwordInput = this.loginFormGroup.controls.password.value ? '' : 'Please enter a password.';
    }
  }
}
