import { createSelector } from '@ngrx/store';

import { Invoice } from 'src/app/models/invoice.model';
import * as fromFeature from '../reducers';
import * as fromRouters from '../reducers/router.reducer';
import * as fromInvoices from '../reducers/invoices.reducer';

export const getInvoicesState = createSelector(
  fromFeature.getFeatureBillingState,
  (state: fromFeature.BillingState) => state.invoices
);

export const getInvoicesEntities = createSelector(getInvoicesState, fromInvoices.getInvoicesEntities);

export const getAllInvoices = createSelector(getInvoicesEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getInvoicesLoaded = createSelector(getInvoicesState, fromInvoices.getInvoicesLoaded);

export const getInvoicesLoading = createSelector(getInvoicesState, fromInvoices.getInvoicesLoading);

// ROUTER SELECTORS
export const getCurrentRouterInvoice = createSelector(
  getAllInvoices,
  fromRouters.getRouterFeatureState,
  (invoices, router): Invoice => {
    if (router.state) {
      const invoice = invoices.find((x) => x.Id === router.state.params.invoiceId);
      return invoice;
    }
  }
);

// GET SELECTED
export const getSelectedInvoiceId = createSelector(getInvoicesState, fromInvoices.getInvoiceSelected);
export const getSelectedInvoice = createSelector(
  getInvoicesEntities,
  getSelectedInvoiceId,
  (entities, invoiceId): Invoice => {
    if (entities.invoiceId !== undefined) {
      return entities[invoiceId];
    }
  }
);

// GET LATEST INVOICE
export const getLatestInvoiceDate = createSelector(
  getAllInvoices,
  (invoices): Date => {
    if (invoices.length > 0) {
      return new Date(invoices[0].Transaction_Date__c);
    }
  }
);
