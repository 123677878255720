import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from 'src/app/models/api-url.model';
import { Node } from 'src/app/models/node.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
  constructor(private apiService: ApiService, private authService: AuthService) {}

  getDownNodes(): Observable<Node[]> {
    const orionUserId = this.authService.getOrionUserId;
    const orionPassword = this.authService.getOrionPassword;
    const params = {
      'Orion_UserID__c': orionUserId,
      'Orion_Password__c': orionPassword
    };
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
    httpParams = httpParams.set(key, params[key]);
    });
    return this.apiService.get(api.nodesDownURL, {params: httpParams});
  }
}
