import { Component, Input } from '@angular/core';

import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'ticket-detail-header',
  templateUrl: './ticket-detail-header.component.html',
  styleUrls: ['./ticket-detail-header.component.scss']
})
export class TicketDetailHeaderComponent {
  @Input() ticket: Ticket;
  @Input() browserSize: string;
}
