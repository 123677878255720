import { Action } from '@ngrx/store';
import { Ticket } from 'src/app/models/ticket.model';

// Load tickets
export const LOAD_TICKETS = '[Tickets] Load Tickets';
export const LOAD_TICKETS_FAIL = '[Tickets] Load Tickets Fail';
export const LOAD_TICKETS_SUCCESS = '[Tickets] Load Tickets Success';

export class LoadTickets implements Action {
  readonly type = LOAD_TICKETS;
}

export class LoadTicketsFail implements Action {
  readonly type = LOAD_TICKETS_FAIL;
  constructor(public payload: any) {}
}

export class LoadTicketsSuccess implements Action {
  readonly type = LOAD_TICKETS_SUCCESS;
  constructor(public payload: Ticket[]) {}
}

export const CLEAR_TICKETS = '[Tickets] Clear Tickets';
export const SET_SELECTED_TICKET = '[Tickets] Set Selected Ticket';

export class ClearTickets implements Action {
  readonly type = CLEAR_TICKETS;
}

export class SetSelectedTicket implements Action {
  readonly type = SET_SELECTED_TICKET;
  constructor(public payload: string) {}
}

export const LOAD_TOTAL_OPEN_TICKETS = '[Tickets] Load Total Open Tickets';
export const LOAD_TOTAL_OPEN_TICKETS_FAIL = '[Tickets] Load Total Open Tickets Fail';
export const LOAD_TOTAL_OPEN_TICKETS_SUCCESS = '[Tickets] Load Total Open Tickets Success';

export class LoadTotalOpenTickets implements Action {
  readonly type = LOAD_TOTAL_OPEN_TICKETS;
}

export class LoadTotalOpenTicketsFail implements Action {
  readonly type = LOAD_TOTAL_OPEN_TICKETS_FAIL;
  constructor(public payload: any) {}
}

export class LoadTotalOpenTicketsSuccess implements Action {
  readonly type = LOAD_TOTAL_OPEN_TICKETS_SUCCESS;
  constructor(public payload: number) {}
}

export const UPDATE_TICKET = '[Tickets] Update Ticket';

export class UpdateTicket implements Action {
  readonly type = UPDATE_TICKET;
  constructor(public payload: Ticket) {}
}

// action types
export type TicketsAction =
  | LoadTickets
  | LoadTicketsFail
  | LoadTicketsSuccess
  | ClearTickets
  | SetSelectedTicket
  | LoadTotalOpenTickets
  | LoadTotalOpenTicketsFail
  | LoadTotalOpenTicketsSuccess
  | UpdateTicket;
