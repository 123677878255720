import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable, Subscription } from 'rxjs';
import { Service } from 'src/app/models/service.model';

import * as fromStore from 'src/app/store/';

@Component({
  selector: 'app-widget-services',
  templateUrl: './widget-services.component.html',
  styleUrls: ['../widgets.scss', './widget-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetServicesComponent implements OnInit {
  totalServices: number;
  isLoading$: Observable<boolean>;
  totalLocations$: Observable<number>;
  totalOpenServices$: Observable<Service[]>;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.isLoading$ =  this.store.select(fromStore.getServicesLoading);
    this.store.dispatch(new fromStore.LoadServices());
    this.totalOpenServices$ = this.store.select(fromStore.getActiveServices);
    this.totalLocations$ = this.store.select(fromStore.getTotalActiveServiceLocations);
  }
}
