import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'detail-header',
  styleUrls: ['./detail-header.component.scss'],
  template: `<div class="back-heading" *ngIf="browserSize === 'small'">
      <a class="back-link icon-text" [routerLink]="['/orders']">
        <div class="custom-icon arrow_back"></div>
        Back to Order Status
      </a>
    </div>
    <div class="order-detail-content">
      <div class="order-detail-header">
        <div class="order-title">
          <h2>
            <div class="custom-icon circuit_dark">circuit icon</div>
            {{ order.Name || '' }} ({{ order.Status__c === 'Ready' ? 'Pending' : order.Status__c }})
          </h2>
        </div>
        <div class="order-date" *ngIf="order.Order_Date__c">
          Order Date: <span>{{ order.Order_Date__c | date: 'MM/dd/yy' }}</span>
        </div>
      </div>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailHeaderComponent {
  @Input() order: Order;
  @Input() browserSize: string;
}
