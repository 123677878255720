import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ServiceService } from 'src/app/services/service.service';
import * as serviceActions from '../actions/services.action';

@Injectable()
export class ServiceEffects {
  constructor(private actions$: Actions, private serviceService: ServiceService) {}

  loadServices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(serviceActions.LOAD_SERVICES),
      switchMap(() => {
        return this.serviceService.getServices().pipe(
          map((services) => new serviceActions.LoadServicesSuccess(services)),
          catchError((error) => of(new serviceActions.LoadServicesFail(error)))
        );
      })
    );
  });
}
