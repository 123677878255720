import { Component, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class OrderPaginatorComponent extends MatPaginatorIntl {
	
	itemsPerPageLabel = 'Displaying:';
  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return 'No orders';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return 'Showing ' + (startIndex + 1) + ' - ' + endIndex + ' of ' + length;
  };
}
