import { Action } from '@ngrx/store';
import { Payment } from 'src/app/models/payment.model';

export const LOAD_PAYMENTS = '[Payments] Load Payment';
export const LOAD_PAYMENTS_FAIL = '[Payments] Load Payment Fail';
export const LOAD_PAYMENTS_SUCCESS = '[Payments] Load Payment Success';
export const SET_PAYMENTS_FILTER_NUMBER = '[Payments] Set Payments Filter Number';
export const SET_SELECTED_PAYMENT = '[Payments] Set Selected Payment';

export class LoadPayments implements Action {
  readonly type = LOAD_PAYMENTS;
}

export class LoadPaymentsFail implements Action {
  readonly type = LOAD_PAYMENTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadPaymentsSuccess implements Action {
  readonly type = LOAD_PAYMENTS_SUCCESS;
  constructor(public payload: Payment[]) {}
}

export class SetPaymentsFilterNumber implements Action {
  readonly type = SET_PAYMENTS_FILTER_NUMBER;
  constructor(public payload: number) {}
}

export class SetSelectedPayment implements Action {
  readonly type = SET_SELECTED_PAYMENT;
  constructor(public payload: string) {}
}

// action types
export type PaymentsAction =
  | LoadPayments
  | LoadPaymentsFail
  | LoadPaymentsSuccess
  | SetPaymentsFilterNumber
  | SetSelectedPayment;
