import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UntypedFormGroup, FormControl } from "@angular/forms";

const ticketMap = {
  service: {
    ticketNumber: "Name",
    ticketName: "RecordType",
    ticketNameSecondary: "Name",
    vendor: "Vendor__r",
    alias: "Display_Alias__c",
  },
  disconnect: { ticketNumber: "nit", ticketName: "product" },
  tob: { ticketNumber: "nit", ticketName: "product" },
};

@Component({
  selector: "app-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() formRef: UntypedFormGroup = new UntypedFormGroup({});
  @Input() allServices: any[] = [];
  @Input() ticketType: any = [];
  @Input() identifyIssueBy: string;
  @Input() serviceIterator: any;
  @Input() selectedServiceId: string;
  @Output() serviceSelected: EventEmitter<any> = new EventEmitter();

  filteredOptions: Observable<any[]>;
  selectedServiceLocation: any;
  ticketNumberValue: any;
  ticketNameValue: any;
  ticketNameSecondary: any;
  ticketVendor: String;
  ticketAlias: String;
  allTempLocations = [];
  autocompleteControl = new FormControl("");

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
      startWith(""),
      map((name) => (name ? this._filter(name) : this.allServices.slice()))
    );
    this.ticketNumberValue = ticketMap[this.ticketType].ticketNumber;
    this.ticketNameValue = ticketMap[this.ticketType].ticketName;
    this.ticketVendor = ticketMap[this.ticketType].ticketVendor;
    this.ticketAlias = ticketMap[this.ticketType].ticketTicketAlias;
    if (this.ticketType === "service") {
      this.ticketNameSecondary = ticketMap[this.ticketType].ticketNameSecondary;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.identifyIssueBy) {
      this.selectedServiceLocation = null;
      this.autocompleteControl.setValue('');
      if (this.selectedServiceId?.length) {
        this.initSelectedService(this.selectedServiceId);
      }
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toUpperCase();
    const ticketMapValue = ticketMap[this.ticketType];
    this.allTempLocations = this.allServices.filter((option) =>
      option[ticketMapValue.ticketNumber].includes(filterValue)
    );
    return this.allTempLocations;
  }

  onServiceSelected(selectedService: any) {
    if (selectedService !== null) {
      const foundService = this.allServices.find(
        (service) =>
          service[ticketMap[this.ticketType].ticketNumber] ===
          selectedService?.option?.value
      );
      this.selectedServiceLocation =
      foundService?.Z_Location__r?.Name || foundService.locationName;
      this.serviceSelected.emit({
        locationAddress:
        foundService?.Z_Location__r?.Name || foundService.locationName,
      serviceId: foundService?.Id || foundService?.id,
      serviceName: foundService?.Name || foundService?.nit,
      locationId: foundService?.Z_Location__r?.Id || foundService.locationId,
      serviceIterator: foundService?.serviceIterator || 0,
      });
    }
  }

  initSelectedService(selectedServiceId: string) {
    const foundService: any = this.allServices.find(
      (service) =>
        service?.Id === selectedServiceId || service?.id === selectedServiceId
    );
    this.autocompleteControl.setValue(foundService?.Name || foundService?.nit);
    this.selectedServiceLocation =
      foundService?.Z_Location__r?.Name || foundService.locationName;
    this.serviceSelected.emit({
      locationAddress:
        foundService?.Z_Location__r?.Name || foundService.locationName,
      serviceId: foundService?.Id || foundService?.id,
      serviceName: foundService?.Name || foundService?.nit,
      locationId: foundService?.Z_Location__r?.Id || foundService.locationId,
      serviceIterator: foundService?.serviceIterator || 0,
    });
  }
}
