import { WidgetMonitoringComponent } from './widget-monitoring/widget-monitoring.component';
import { WidgetBillingComponent } from './widget-billing/widget-billing.component';
import { WidgetTicketsComponent } from './widget-tickets/widget-tickets.component';
import { WidgetOrdersComponent } from './widget-orders/widget-orders.component';
import { WidgetServicesComponent } from './widget-service/widget-services.component';
import { WidgetSummaryComponent } from './widget-summary/widget-summary.component';

export const widgetTypes = {
  MONITORING: {
    label: 'MONITORING',
    component: WidgetMonitoringComponent
  },
  BILLING: {
    label: 'BILLING',
    component: WidgetBillingComponent
  },
  SUMMARY: {
    label: 'SUMMARY',
    component: WidgetSummaryComponent
  },
  TICKETING: {
    label: 'TICKETING',
    component: WidgetTicketsComponent
  },
  ORDERS: {
    label: 'ORDERS',
    component: WidgetOrdersComponent
  },
  SERVICES: {
    label: 'SERVICES',
    component: WidgetServicesComponent
  }
};
