import { Action } from '@ngrx/store';
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';

// load service order forms
export const LOAD_INVOICE_ATTACHMENTS = '[Invoice Attachments] Load Invoice Attachments';
export const LOAD_INVOICE_ATTACHMENTS_FAIL = '[Invoice Attachments] Load Invoice Attachments Fail';
export const LOAD_INVOICE_ATTACHMENTS_SUCCESS = '[Invoice Attachments] Load Invoice Attachments Success';
export const SET_INVOICE_ATTACHMENTS_FILTER_NUMBER = '[Invoice Attachments] Set Invoice Filter Number';
export const SET_SELECTED_INVOICE_ATTACHMENT = '[Invoice Attachments] Set Selected Invoice Attachment';

export class LoadInvoiceAttachments implements Action {
  readonly type = LOAD_INVOICE_ATTACHMENTS;
}

export class LoadInvoiceAttachmentsFail implements Action {
  readonly type = LOAD_INVOICE_ATTACHMENTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadInvoiceAttachmentsSuccess implements Action {
  readonly type = LOAD_INVOICE_ATTACHMENTS_SUCCESS;
  constructor(public payload: ContentDocumentLink[]) {}
}

export class SetInvoiceFilterNumber implements Action {
  readonly type = SET_INVOICE_ATTACHMENTS_FILTER_NUMBER;
  constructor(public payload: number) {}
}

export class SetSelectedInvoiceAttachment implements Action {
  readonly type = SET_SELECTED_INVOICE_ATTACHMENT;
  constructor(public payload: ContentDocumentLink) {}
}

// action types
export type InvoiceAttachmentsAction =
  | LoadInvoiceAttachments
  | LoadInvoiceAttachmentsFail
  | LoadInvoiceAttachmentsSuccess
  | SetInvoiceFilterNumber
  | SetSelectedInvoiceAttachment;
