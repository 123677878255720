import { InventoryDetailComponent } from './inventory-detail/inventory-detail.component';
import { ServiceProgressBarComponent } from './service-progress-bar/service-progress-bar.component';
import { ServiceTopHeaderComponent } from './service-top-header/service-top-header.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { LocationNameComponent } from './location-name/location-name.component';
import { ServiceBillingComponent } from './service-billing/service-billing.component';
import { ServiceTypeChartComponent} from './service-type-chart/service-type-chart.component';

export const components: any[] = [
  InventoryDetailComponent,
  InventoryListComponent,
  LocationNameComponent,
  ServiceBillingComponent,
  ServiceProgressBarComponent,
  ServiceTypeChartComponent,
  ServiceTopHeaderComponent
];

export * from './inventory-detail/inventory-detail.component';
export * from './inventory-list/inventory-list.component';
export * from './location-name/location-name.component';
export * from './service-billing/service-billing.component';
export * from './service-progress-bar/service-progress-bar.component';
export * from './service-type-chart/service-type-chart.component';