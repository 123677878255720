import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Order } from 'src/app/models/order.model';
import { api } from 'src/app/models/api-url.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private apiService: ApiService) {}

  getOrders(): Observable<Order[]> {
    return this.apiService.get(api.ordersURL);
  }
  getOpenOrdersTotal(): Observable<number> {
    return this.apiService.get(`${api.ordersURL}/open_total`);
  }

  createOrder(orderData: any): Observable<Order> {
    return this.apiService.post(`${api.ordersURL}`, orderData);
  }

  getLastUpdated(): Promise<any> {
    return Promise.resolve('--');
  }
}
