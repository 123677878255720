import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gainsight'
})
export class GainsightPipe implements PipeTransform {

  transform(value: string, feature: string = "gainsight"): string {
    return `gpx-${feature}-${value.replace(/\s/g, '').toLowerCase()} ${value.replace(/\s/g, '').toLowerCase()}`;
  }

}