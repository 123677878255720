<div class="container-top" *ngIf="!loading">
	<div class="flex-item">
		<div class="container-title">
			<div class="back-nav" (click)="goBack();">
				<mat-icon>chevron_left</mat-icon>
				<span>Return to Orders List</span>
			</div>
			<h1>{{ order.nit }}</h1>
		</div>
	</div>
	<div class="flex-item actions">
		<button class="button" (click)="onCreateTicket()">
			<span>Create Ticket</span>
		</button>
	</div>
</div>

<div class="loading-spinner" *ngIf="loading">
	<mat-spinner [diameter]="50"></mat-spinner>
</div>

<div class="order-data">
	<div class="flex-item">
		<app-order-details-box class="info-box-component" title="General Information" [data]="generalInfo">
		</app-order-details-box>
		<app-order-dates-box *ngIf="!datesInCenter" class="info-box-component" [dates]="keyDates">
		</app-order-dates-box>
	</div>
	<div class="flex-item">
		<app-order-details-box *ngIf="basicServiceInfo?.length > 0" class="info-box-component" title="Basic Service Information" [data]="basicServiceInfo">
		</app-order-details-box>
		<app-order-details-box *ngIf="!datesInCenter" class="info-box-component" title="Circuit Order Specifics" [data]="circuitOrdersInfo">
		</app-order-details-box>
		<app-order-dates-box *ngIf="datesInCenter" class="info-box-component" [dates]="keyDates">
		</app-order-dates-box>
	</div>
	<div class="flex-item last-column">
		<app-order-contacts-box class="info-box-component" [data]="contactsInfo">
		</app-order-contacts-box>
	</div>
</div>
