import * as fromComment from './comment.model';
import { SalesforceBase } from './salesforce-base.model';
import { Contact } from './contact.model';
import { RecordType } from './record-type.model';

export interface Ticket extends SalesforceBase {
  Time_to_Repair_Days__c: number;
  First_touch__c: any;
  Business_Purpose__c?: string;
  CaseNumber?: string;
  ClosedDate?: Date;
  Comments__r?: fromComment.Record;
  Contact?: Contact;
  Customer_Ticket_Number__c?: string;
  CreatedDate: Date;
  Description?: string;
  IsClosed?: boolean;
  Maintenance_Date__c?: Date;
  Portal_Ticket_Type__c?: string;
  Service_Location_Address__c?: string;
  Service__c?: string;
  Service__r?: any;
  Status?: string;
  Subject?: string;
  RecordType?: RecordType;
  AccountId?: string;
}

export enum TicketGrouping {
  Type,
  Status,
  Location
}

export enum TicketType {
  Care,
  Proactive,
  Maintenance,
  Support,
  Finance,
  Other // can be used in case we forget to update the frontend when there are other ticket types. More ticket types should be added before this one
}

/**
 * attempts to map a string value to the type of grouping we doing.
 * If the string value is not one defined, defaults to grouping by Type
 * @param value String value we are mapping to the TicketGrouping Enum
 * @returns The TicketGrouping enum value.
 */
export function mapStringToTicketGrouping(value: String): TicketGrouping {
  if (value.toLocaleLowerCase() === 'type') {
    return TicketGrouping.Type;
  } else if (value.toLocaleLowerCase() === 'status') {
    return TicketGrouping.Status;
  } else if (value.toLocaleLowerCase() === 'location') {
    return TicketGrouping.Location;
  }

  // didn't match any type, so just group by type by default
  return TicketGrouping.Type;
}

export function getTicketType(ticket: Ticket): TicketType {
  if (ticket.RecordType.Name === 'Finance' && !ticket.Business_Purpose__c) {
    return TicketType.Finance;
  }
  if (ticket.RecordType.Name === 'Care') {
    return TicketType.Care;
  }

  if (isTicketProactive(ticket)) {
    return TicketType.Proactive;
  }
  if (isTicketMaintenance(ticket)) {
    return TicketType.Maintenance;
  }
  if (isTicketSupport(ticket)) {
    return TicketType.Support;
  }

  return TicketType.Other;
}

export function isTicketProactive(ticket: Ticket): boolean {
  return (
    ticket.Business_Purpose__c === 'NOC - Nitel Proactive Notification' ||
    ticket.Business_Purpose__c === 'NOC - Proactive Alert/Carrier' ||
    ticket.Business_Purpose__c === 'NOC - Proactive Alert/Nitel' ||
    ticket.Business_Purpose__c === 'NOC - Proactive Alert/Customer' ||
    ticket.Business_Purpose__c === 'NOC - Proactive Versa Alert'
  );
}

export function isTicketMaintenance(ticket: Ticket): boolean {
  return (
    ticket.Business_Purpose__c === 'NOC - Scheduled Maintenance' ||
    ticket.Business_Purpose__c === 'NOC - Emergency Maintenance' ||
    ticket.Business_Purpose__c === 'NetEng - Emergency Maintenance' ||
    ticket.Business_Purpose__c === 'NetEng - Standard Maintenance'
  );
}

export function isTicketSupport(ticket: Ticket): boolean {
  return ticket?.RecordType.Name === 'NOC' && !isTicketProactive(ticket) && !isTicketMaintenance(ticket);
}
