import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSOF from '../reducers/sof.reducer';

export const getSOFState = createSelector(
  fromFeature.getFeatureOrdersState,
  (state: fromFeature.OrdersState) => state.serviceOrderForms
);

export const getSOFEntities = createSelector(getSOFState, fromSOF.getSOFEntities);

export const getAllSOF = createSelector(getSOFEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getSOFLoaded = createSelector(getSOFState, fromSOF.getSOFLoaded);

export const getSOFLoading = createSelector(getSOFState, fromSOF.getSOFLoading);
export const getSOFSelected = createSelector(getSOFState, fromSOF.getSOFSelected);
