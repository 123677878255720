import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AttachmentService } from 'src/app/services/attachment.service';
import * as sofActions from '../actions/sof.action';

@Injectable()
export class SOFEffects {
  constructor(private actions$: Actions, private attachmentService: AttachmentService) {}

  loadSOF$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sofActions.LOAD_SERVICE_ORDER_FORMS),
      switchMap(() => {
        return this.attachmentService.getServiceOrderForms().pipe(
          map((forms) => new sofActions.LoadServiceOrderFormsSuccess(forms)),
          catchError((error) => of(new sofActions.LoadServiceOrderFormsFail(error)))
        );
      })
    );
  });
}
