import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ServiceSummaryRow {
	icon?: string;
	value: number;
	title: string;
	link?: string;
}

@Component({
  selector: 'app-service-overview',
  templateUrl: './service-overview.component.html',
  styleUrls: ['../widgets.scss', './service-overview.component.scss']
})
export class ServiceOverviewComponent implements OnInit {

	@Input() title: string = "...";
	@Input() list: ServiceSummaryRow[] = [];
	@Input() extraClass: string;
	@Input() loading: boolean = false;
	@Input() emptyMessage: string;
	@Output() navigate: EventEmitter<string> = new EventEmitter();
	showLinks: boolean;

	constructor(
	) { }

	ngOnInit(): void {
		if(this.title === 'Service Overview'){
			this.showLinks = true;
		}
	}

	goLink(link: string): void {
		if(!this.showLinks || !link) return;
		this.loading = true;
		this.navigate.emit(link);
	}

}
