<section>
  <div class="icon-text header">
    <div class="custom-icon billing_dollar"></div>
    <h1>Billing</h1>
  </div>
  <div class="loading-indicator" *ngIf="(isLoadingInvoices$ | async) && (isLoadingPayments$ | async)">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="billing-widget-wrapper" *ngIf="!(isLoadingInvoices$ | async) && !(isLoadingPayments$ | async)">
    <div class="invoice-container" *ngIf="invoiceDate$ | async as invoiceDate; else noInvoices">
      <img class="invoice-image" alt="invoice icon" src="/assets/images/img_widget_invoice_std@2x.png" />
      <div class="invoice-detail">
        <h2>Invoice</h2>
        <div class="posted-date">posted {{ invoiceDate | date: 'MMM d, y.' }}</div>
      </div>
    </div>
    <ng-template #noInvoices>
      <div class="invoice-container">
        <img class="invoice-image" alt="invoice icon" src="/assets/images/img_widget_invoice_empty@2x.png" />
        <div class="invoice-detail">
          <h2 class="empty">No Invoices.</h2>
        </div>
      </div>
    </ng-template>
    <div class="payment-container" *ngIf="payment$ | async as payment; else noPayments">
      <h2>Payment</h2>
      <div class="posted-date">posted {{ payment.Transaction_Date__c | date: 'MMM d, y.' }}</div>
      <div class="payment-value">
        <span>$</span>{{ getPaymentDollars(payment) }}<span>{{ getPaymentCents(payment) }}</span>
      </div>
    </div>
    <ng-template #noPayments>
      <div class="payment-container">
        <h2 class="empty">No Payment Yet.</h2>
        <div class="payment-value"><span>$</span>&nbsp;- - -</div>
      </div>
    </ng-template>
  </div>
  <div class="widget_cta"><a routerLink="/billing">View Billing</a></div>
  <a routerLink="/billing" tabindex="-1" class="dashboard-link desktop"><div class="cta-link">desktop</div></a>
</section>
