import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-banner-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.scss']
})
export class BannerMessageComponent implements OnInit {
  @Input() message: string;
  @Input() dismiss = false;
  @Input() status = '';

  hideMessage = false;
  statusIcon = '';
  iconMap = {
    success: 'inline_success_white',
    warning: 'inline_error_white',
    error: 'inline_error_white',
    default: 'inline_error_white'
  };

  ngOnInit() {
    this.statusIcon = this.iconMap[this.status] ? this.iconMap[this.status] : this.iconMap.default;
  }

  dismissMessage() {
    this.hideMessage = true;
  }
}
