import { ChangeDetectorRef, ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { StateService } from 'src/app/services/state.service';

import * as Orders from '../../orders-table/models';
import { SummaryParserService } from '../summary-parser.service';
import { ServiceSummaryRow } from '../service-overview/service-overview.component';
import GraphDataItem from '../graph-data.interface';

@Component({
  selector: 'app-summary',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent implements OnInit {
  public breakpoint = 4;
  public loading = true;
  public loadingAllPage = false;

  public serviceOverviewList: ServiceSummaryRow[] = [];
  public startOfServiceList: ServiceSummaryRow[] = [];

  public countPreparing: number;
  public countImplementation: number;
  public countInService: number;

  public milestonesData: GraphDataItem[] = [];
  public milestonesDataLoading = true;

  public pendingFocDateList: GraphDataItem[] = [];
  public pendingFocDateTotal: number;
  public pendingFocDateLoading = true;

  public inFlightServiceList: ServiceSummaryRow[] = [];
  public inFlightServiceLoading = true;

  private ordersTableLink = '/orders-table';

  constructor(
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private route: Router,
    private State: StateService,
    private Service: SummaryParserService
  ) {}

  ngOnInit() {
    this.setBreakpoint(window.innerWidth);
    this.getData();
  }

  public onResize(event) {
    this.setBreakpoint(event.target.innerWidth);
  }
  private setBreakpoint(width: number) {
    this.breakpoint = width <= 700 ? 1 : 4;
  }

  private getData(): void {
    this.Service.getSummary().subscribe((data) => {
      const { types } = data;
      const activeTypes = data.active_types;
      const { status } = data;
      this.getServiceOverviewList(types, status);
      this.getStartOfServiceList(status);
      this.getInFlightServiceList(activeTypes);
      this.fillBullets(status);

      const delayTime = (max) => Math.floor(Math.random() * max) + 100;

      window.setTimeout(() => {
        this.getCircuitMilestoneList(status);
      }, delayTime(1500));
      window.setTimeout(() => {
        this.getPendingFOCDateList(data.pending_reasons);
      }, delayTime(3000));
      this.ref.detectChanges();
    });
  }

  private getServiceOverviewList(types, status): void {
    let noData = false;
    if (!types && !status) {
      noData = true;
    }
    let completed = noData ? 0 : status.complete - status.disconnected;
    if (completed < 0) completed = 0;
    this.serviceOverviewList = [
      {
        icon: 'account_tree',
        value: noData ? 0 : types.all,
        title: 'Total Orders',
        link: this.ordersTableLink
      },
      {
        icon: 'build_circle',
        value: noData ? 0 : status.in_progress,
        title: 'In-Progress Orders',
        link: `${this.ordersTableLink}?status=${[
          Orders.Status.IN_PROGRESS
        ].join(',')}`
      },
      {
        icon: 'cancel',
        value: noData ? 0 : status.canceled,
        title: 'Canceled',
        link: `${this.ordersTableLink}?status=${Orders.Status.CANCELED}`
      },
      // {
      //   icon: 'sync_problem',
      //   value: noData ? 0 : status.disconnected_ip,
      //   title: 'In-progress Disconnects',
      //   link: `${this.ordersTableLink}?status=${Orders.Status.DISCONNECTED_IN_PROGRESS}`
      // },
      // {
      //   icon: 'edit_location_alt',
      //   value: noData ? 0 : status.move_request_ip,
      //   title: 'In-progress Move Requests',
      //   link: `${this.ordersTableLink}?status=${Orders.Status.MOVE_REQUESTED_IN_PROGRESS}`
      // }
    ];
  }

  private getStartOfServiceList(status): void {
    const noData = !status;

    this.startOfServiceList = [
      {
        icon: 'build_circle',
        value: noData ? 0 : status.pending_activation,
        title: 'Pending Activation',
        link: `${this.ordersTableLink}?status=${Orders.Status.ACTIVATION_PENDING}`
      },
      {
        icon: 'build_circle',
        value: noData ? 0 : status.scheduled_activation,
        title: 'Scheduled Activation',
        link: `${this.ordersTableLink}?status=${Orders.Status.ACTIVATION_SCHEDULED}`
      },
      {
        icon: 'check_circle',
        value: noData ? 0 : status.complete,
        title: 'Activation Complete',
        link: `${this.ordersTableLink}?status=${Orders.Status.COMPLETE}`
      }
    ];
  }

  private fillBullets(status): void {
    const noData = !status;
    this.countPreparing = noData ? 0 : status.preparing;
    this.countImplementation = noData
      ? 0
      : status.pending_foc + status.foc_assigned + status.vendor_completion + status.equipment_shipped;
    this.countInService = noData ? 0 : status.pending_activation + status.scheduled_activation;
    this.loading = false;
  }

  private getInFlightServiceList(activeTypes: any): void {
    let noData = false;
    if (!activeTypes) {
      activeTypes = {};
      noData = true;
    }
    this.inFlightServiceList = [];
    Object.keys(activeTypes).map((type) => {
      const data = activeTypes[type];
      this.inFlightServiceList.push({
        value: data,
        title: type,
        link: `${this.ordersTableLink}?service=${type}`
      });
    });
    this.inFlightServiceList = noData ? [] : this.inFlightServiceList.sort((a, b) => b.value - a.value);
    this.inFlightServiceLoading = false;
    this.ref.detectChanges();
  }

  private getCircuitMilestoneList(status): void {
    const noData = !status;

    this.milestonesData = [
      {
        value: noData ? 0 : status.preparing,
        label: 'Preparing Order',
        color: '#714AA4',
        link: `${this.ordersTableLink}?status=${Orders.Status.PREPARING}`
      },
      {
        value: noData ? 0 : status.preparing,
        label: ['Pending', 'FOC Date'],
        color: '#00A4D8',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_PENDING}`
      },
      {
        value: noData ? 0 : status.foc_assigned,
        label: ['FOC Date', 'Assigned'],
        color: '#00A4D8',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_ASSIGNED}`
      },
      {
        value: noData ? 0 : status.vendor_completion,
        label: 'Vendor Completion',
        color: '#00A4D8',
        link: `${this.ordersTableLink}?status=${Orders.Status.VENDOR_COMPLETION}`
      },
      {
        value: noData ? 0 : status.equipment_shipped,
        label: ['Equipment', 'Shipped'],
        color: '#00A4D8',
        link: `${this.ordersTableLink}?status=${Orders.Status.EQUIPMENT_SHIPPED}`
      },
      {
        value: noData ? 0 : status.pending_activation,
        label: 'Pending Activation',
        color: '#1916B4',
        link: `${this.ordersTableLink}?status=${Orders.Status.ACTIVATION_PENDING}`
      },
      {
        value: noData ? 0 : status.scheduled_activation,
        label: 'Scheduled Activation',
        color: '#1916B4',
        link: `${this.ordersTableLink}?status=${Orders.Status.ACTIVATION_SCHEDULED}`
      }
    ];
    this.pendingFocDateTotal = noData ? 0 : status.pending_foc;
    this.milestonesDataLoading = false;
    this.ref.detectChanges();
  }

  private getPendingFOCDateList(reasons): void {
    const noData = !reasons;

    this.pendingFocDateList = [
      {
        value: noData ? 0 : reasons.waiting_vendor,
        label: ['Waiting on', 'Vendor'],
        color: '#00A4D8',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_PENDING}`
      },
      {
        value: noData ? 0 : reasons.special_construction,
        label: 'Special Construction',
        color: '#CF3B3B',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_PENDING}`
      },
      {
        value: noData ? 0 : reasons.carrier_build,
        label: 'Carrier Build',
        color: '#CF3B3B',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_PENDING}`
      },
      {
        value: noData ? 0 : reasons.cust_req,
        label: 'Customer Req.',
        color: '#CF3B3B',
        link: `${this.ordersTableLink}?status=${Orders.Status.FOC_PENDING}`
      }
    ];
    this.pendingFocDateLoading = false;
    this.ref.detectChanges();
  }

  public bulletPreparingClick(): void {
    const link = `${this.ordersTableLink}?status=${Orders.Status.PREPARING}`;
    this.navigate(link);
  }
  public bulletImplementationClick(): void {
    const status = [
      -4,
      Orders.Status.FOC_PENDING,
      Orders.Status.FOC_ASSIGNED,
      Orders.Status.VENDOR_COMPLETION,
      Orders.Status.EQUIPMENT_SHIPPED
    ];
    const link = `${this.ordersTableLink}?status=${status.join(',')}`;
    this.navigate(link);
  }
  public bulletInServiceClick(): void {
    const status = [-5, Orders.Status.ACTIVATION_PENDING, Orders.Status.ACTIVATION_SCHEDULED];
    const link = `${this.ordersTableLink}?status=${status.join(',')}`;
    this.navigate(link);
  }

  public navigate(link: string) {
    this.State.sendState('show_loading_overlay');
    window.setTimeout(() => {
      this.route.navigateByUrl(link);
    }, 100);
  }
}
