import { Action } from '@ngrx/store';

// load orders
export const LOAD_DISCONNECT_REASONS = '[Line Items] Load Disconnect Reasons';
export const LOAD_DISCONNECT_REASONS_FAIL = '[Line Items] Load Disconnect Reasons Fail';
export const LOAD_DISCONNECT_REASONS_SUCCESS = '[Line Items] Load Disconnect Reasons Success';

export class LoadDisconnectReasons implements Action {
  readonly type = LOAD_DISCONNECT_REASONS;
}

export class LoadDisconnectReasonsFail implements Action {
  readonly type = LOAD_DISCONNECT_REASONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadDisconnectReasonsSuccess implements Action {
  readonly type = LOAD_DISCONNECT_REASONS_SUCCESS;
  constructor(public payload: string[]) {}
}

// action types
export type LineItemsAction = LoadDisconnectReasons | LoadDisconnectReasonsFail | LoadDisconnectReasonsSuccess;
