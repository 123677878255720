import { Action } from '@ngrx/store';
import { Node } from 'src/app/models/node.model';

export const LOAD_DOWN_NODES = '[Nodes] Load Down Nodes';
export const LOAD_DOWN_NODES_FAIL = '[Nodes] Load Down Nodes Fail';
export const LOAD_DOWN_NODES_SUCCESS = '[Nodes] Load Down Nodes Success';

export class LoadDownNodes implements Action {
  readonly type = LOAD_DOWN_NODES;
}

export class LoadDownNodesFail implements Action {
  readonly type = LOAD_DOWN_NODES_FAIL;
  constructor(public payload: any) {}
}

export class LoadDownNodesSuccess implements Action {
  readonly type = LOAD_DOWN_NODES_SUCCESS;
  constructor(public payload: Node[]) {}
}

// action types
export type NodesAction = LoadDownNodes | LoadDownNodesFail | LoadDownNodesSuccess;
