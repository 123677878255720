import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from 'src/app/services/header.service';
import { StateService } from 'src/app/services/state.service';
import { MatMenuModule } from '@angular/material/menu';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Navigation, nav, nav2 } from 'src/app/models/navigation.model';

@Component({
  selector: 'app-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  networkUrl: string;
  nav: Navigation[] = nav;
  permissions = [];
  hasMonitoringLink: boolean;
  sortedMonitoringList: any;
  nav2: Navigation[] = nav2;

  // List of routes under "Analytics" that should trigger highlighting
  analyticsRoutes: string[] = ['/unusual-network-metrics', '/metric-details'];
  isAnalyticsActive = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private route: Router,
    private state: StateService,
    private authService: AuthService,
    public header: HeaderService
  ) {}

  ngOnInit() {
    if (this.authService.apiAccount) {
      this.permissions = this.authService.apiAccount.user.Permissions;
      // TODO: rename the variables for more clarity or primaryNav and secondaryNav?
      this.nav = this.nav.filter((tab) => this.permissions.includes(tab.permissions));
      this.nav2 = this.nav2.filter((tab) => this.permissions.includes(tab.permissions));
    }
    this.hasMonitoringLink = false;
    if (this.authService.apiAccount) {
      this.currentUser = this.authService.apiAccount.user;
    }
    if (this.authService.vigilURL !== '') {
      this.networkUrl = this.authService.vigilURL;
    }
    if (this.currentUser?.Monitoring_System_Links__r?.records) {
      this.hasMonitoringLink = true;
    }
    if (this.hasMonitoringLink) {
      this.sortedMonitoringList = this.currentUser.Monitoring_System_Links__r.records.sort((user1, user2) =>
        user1.Name < user2.Name ? -1 : user1.Name > user2.Name ? 1 : 0
      );

      this.sortedMonitoringList.push({ Name: 'Managed Routers', URL__c: this.networkUrl });
    } else {
      this.sortedMonitoringList = [{ Name: 'Managed Routers', URL__c: this.networkUrl }];
    }

    this.checkIfAnalyticsRoute(this.route.url);

    this.route.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        // Check if the current route is a sub-route of Analytics
        this.isAnalyticsActive = this.analyticsRoutes.some(route => event.urlAfterRedirects === route);
      });
  }

  logOut(): void {
    this.authService.logout('manual');
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get username(): string {
    if (this.authService.apiAccount) {
      return this.authService.apiAccount.name;
    }
    return '';
  }

  goToLink(url: string) {
    this.state.sendState('show_loading_overlay');
    window.setTimeout((_) => {
      this.route.navigateByUrl(url);
    }, 100);
  }

  checkIfAnalyticsRoute(url: string): void {
    // Check if the current URL matches any route in analyticsRoutes array
    this.isAnalyticsActive = this.analyticsRoutes.some(route => url === route);
  }

  ngOnDestroy(): void {
    // Complete the destroy$ subject to clean up subscriptions
    this.destroy$.next();
    this.destroy$.complete();
  }
  

}
