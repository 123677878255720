import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-analytics-time-selector',
  templateUrl: './analytics-time-selector.component.html',
  styleUrls: ['./analytics-time-selector.component.scss']
})
export class AnalyticsTimeSelectorComponent implements OnInit {
  public selectedOption = '6h'; // Default is "6 hours"
  public optionList = [
    { value: '6h', viewValue: 'Last 6 hours' },
    { value: '12h', viewValue: 'Last 12 hours' },
    { value: '1m', viewValue: 'Last 1 month' },
    { value: '3m', viewValue: 'Last 3 months' },
    { value: '6m', viewValue: 'Last 6 months' }
  ];
  @Input() label: string = 'Time';  // Input property
  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.emitSelectedOption(); // Emit default value on initialization
  }

  // Method to emit the selected option
  emitSelectedOption(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }

  // Called when the selection changes
  onSelectionChange(value: string): void {
    this.selectedOption = value;
    this.emitSelectedOption();
  }
}
