/* eslint-disable no-shadow */
import { ContentDocumentLink } from 'src/app/models/content-document-link.model';
import * as fromActions from '../actions';

// State for Invoice Attachments
export interface InvoiceAttachmentState {
  entities: { [id: string]: ContentDocumentLink };
  loaded: boolean;
  loading: boolean;
  selectedInvoiceAttachment: ContentDocumentLink;
  filterNumber: number;
}

export const initialState: InvoiceAttachmentState = {
  entities: {},
  loaded: false,
  loading: false,
  selectedInvoiceAttachment: null,
  filterNumber: 6
};

export function reducer(state = initialState, action: fromActions.InvoiceAttachmentsAction): InvoiceAttachmentState {
  switch (action.type) {
    case fromActions.LOAD_INVOICE_ATTACHMENTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_INVOICE_ATTACHMENTS_SUCCESS: {
      const files = action.payload;
      let entities = {};

      if (files !== null) {
        entities = files.reduce(
          (entities: { [id: string]: ContentDocumentLink }, file) => {
            return {
              ...entities,
              [file.ContentDocumentId]: file
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_INVOICE_ATTACHMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromActions.SET_INVOICE_ATTACHMENTS_FILTER_NUMBER: {
      const selectedNumber = action.payload;

      return {
        ...state,
        filterNumber: selectedNumber
      };
    }

    case fromActions.SET_SELECTED_INVOICE_ATTACHMENT: {
      const selectedInvoice = action.payload;

      return {
        ...state,
        selectedInvoiceAttachment: selectedInvoice
      };
    }

    default: {
      return state;
    }
  }
}

export const getInvoiceAttachmentEntities = (state: InvoiceAttachmentState) => state.entities;
export const getInvoiceAttachmentLoading = (state: InvoiceAttachmentState) => state.loading;
export const getInvoiceAttachmentLoaded = (state: InvoiceAttachmentState) => state.loaded;
export const getInvoiceAttachmentFilterNumber = (state: InvoiceAttachmentState) => state.filterNumber;
export const getSelectedInvoiceAttachment = (state: InvoiceAttachmentState) => state.selectedInvoiceAttachment;
