<div class="primary-contact">
  <div class="label">Primary Reporter</div>
  <div class="contact-container">
    <div class="createdby-photo">
      <span><img alt="reporter icon" src="assets/images/img_user_default@2x.png"/></span>
    </div>
    <div class="contact-details">
      <div class="profile-photo"></div>
      <div class="name-title">
        {{ (ticket.Contact.Name | titlecase) || '' }}
        <span *ngIf="ticket.Contact.Title">{{ (ticket.Contact.Title | titlecase) || '' }}</span>
      </div>
      <div class="phone">
        <div class="custom-icon phone">phone</div>
        <span>
          {{ (ticket.Contact.Phone | phoneNumber: 'US') || 'N/A' }}
        </span>
      </div>
      <div class="email">
        <div class="custom-icon email">email</div>
        <span>
          {{ (ticket.Contact.Email | lowercase) || 'N/A' }}
        </span>
      </div>
    </div>
  </div>
</div>
