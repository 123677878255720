<section class="parent-view parent-container">
  <app-billing-list
    [browserSize]="browserSize$ | async"
    [payments]="payments$ | async"
    [isPaymentsLoading]="isPaymentsLoading$ | async"
    [isPaymentsLoaded]="isPaymentsLoaded$ | async"
    [invoices]="invoices$ | async"
    [isInvoicesLoading]="isInvoicesLoading$ | async"
    [isInvoicesLoaded]="isInvoicesLoaded$ | async"
    [selectedInvoice]="selectedInvoice$ | async"
    (onSelectInvoice)="onSelectInvoice($event)"
    (downloadInvoice)="downloadFile($event)"
    (changeInvoiceFilterSelection)="changeInvoiceFilterSelection($event)"
    (changePaymentFilterSelection)="changePaymentFilterSelection($event)"
    ></app-billing-list>

  <app-billing-detail
    *ngIf="browserSize !== 'small'"
    class="app-billing-detail"
    [isInvoicesLoading]="isInvoicesLoading$ | async"
    [selectedInvoice]="selectedInvoice$ | async"
    (downloadInvoice)="downloadFile($event)"
    ></app-billing-detail>
</section>
