import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Order } from '../../models';
import OrderInfoDetail from '../../order-info.interface';

@Component({
  selector: 'app-extended-row',
  templateUrl: './extended-row.component.html',
  styleUrls: ['./extended-row.component.scss']
})
export class ExtendedRowComponent implements OnInit {

	@Input() order: Order;
	@Output() openOrder: EventEmitter<Order> = new EventEmitter();
	public title: string = "...";
	public breakpoint: number = 4;
	public info: OrderInfoDetail[] = null;
	public cancelColspan: number = -1;

	constructor() { }

	ngOnInit(): void {
		this.setBreakpoint(window.innerWidth);
		this.setTitle();
		this.getCustomInfo();
		this.setCancelColspan();
	}

	private setTitle(): void {
		this.title = this.order.getService() + " Order Detail";
	}

	private getCustomInfo(): void {
		this.info = this.order.getRowInfo();
	}

	public onResize(event) {
		this.setBreakpoint(event.target.innerWidth);
	}

	private setBreakpoint(width: number) {
		this.breakpoint = (width <= 700) ? 2 : 4;
	}
	private setCancelColspan() {
		if(!this.order.canceled) {
			this.cancelColspan = 0;
			return;
		}
		this.cancelColspan = this.breakpoint - (this.info.length % this.breakpoint) - 1;
	}

	public viewMore() {
		this.openOrder.emit(this.order);
	}

}
