<app-help-cta [originPage]="'Billing'" [hasInvoice]="selectedInvoice ? true : false"></app-help-cta>

<div class="widget billing-detail">
  <div class="loading-indicator" *ngIf="isInvoicesLoading || pdfLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!isInvoicesLoading && !pdfLoading">
    <ng-container *ngIf="selectedInvoice; else noInvoiceBlock">
      <div class="billing-detail-header">
        <h1>Invoice:</h1>
        <div class="bookends">
          <h2>Posted on {{ selectedInvoice.ContentDocument.Title.slice(0, 10) | date: 'longDate' }}</h2>
          <button class="download-button" (click)="onDownload(selectedInvoice)">
            <div class="custom-icon pdf"></div>
          </button>
        </div>
      </div>

      <div class="embedded-pdf">
        <pdf-viewer
          [src]="pdfUrl"
          [(page)]="page"
          [show-all]="true"
          [show-borders]="false"
          [original-size]="false"
          [autoresize]="true"
          [render-text]="true"
          (error)="onError()"
          (after-load-complete)="afterLoadComplete($event)"
        ></pdf-viewer>
      </div>

      <div *ngIf="!canEmbedPdf && !pdfLoading">
        <div class="pdf-warning">
          You don't have a PDF plugin, but you can
          <button class="pdf-download" (click)="onDownload(selectedInvoice)">download the PDF</button>
        </div>
      </div>
    </ng-container>

    <ng-template #noInvoiceBlock>
      <div class="widget billing-detail">
        <div class="billing-detail-header">
          <h1>Invoice:</h1>
          <div class="bookends">
            <h2>- - -</h2>
          </div>
        </div>
        <p class="no-invoices" *ngIf="!selectedInvoice && !isInvoicesLoading">
          No invoices have posted to this account yet.
        </p>
      </div>
    </ng-template>
  </ng-container>
</div>
