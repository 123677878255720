import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js/auto';
import { BaseChartDirective } from 'ng2-charts';
import GraphDataItem from '../graph-data.interface';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-pending-foc-dates',
  templateUrl: './pending-foc-dates.component.html',
  styleUrls: ['../widgets.scss', './pending-foc-dates.component.scss']
})
export class PendingFocDatesComponent implements OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input() data: GraphDataItem[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = true;
  @Output() navigate: EventEmitter<string> = new EventEmitter();

  constructor() {}

  public barChartType: ChartType = 'bar' as ChartType;
  public barChartPlugins = [ChartDataLabels];
  public barChartLinks: Array<string> = [];

  public barChartOptions: ChartConfiguration['options'] = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 40,
        bottom: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
          weight: 'bolder',
          family: '"open-sans", sans-serif'
        }
      }
    }
  };

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Pending FOC Date',
        barPercentage: 0.7,
        borderWidth: 1,
        hoverBorderWidth: 1
      }
    ]
  };

  ngOnChanges(): void {
    if (this.data.length > 0) {
      this.setData();
    }
  }

  public chartClicked(clickedData): void {
    if (!clickedData.active?.length && !clickedData.active[0]?.index) return;

    let link: string = this.barChartLinks[clickedData.active[0]?.index];
    if (!link) return;

    this.loading = true;
    this.navigate.emit(link);
  }

  private setData(): void {
    this.barChartData.labels = [];
    this.barChartData.datasets[0].data = [];
    this.barChartData.datasets[0].backgroundColor = [];

    const colorData = [];

    this.data.map((data) => {
      this.barChartData.labels.push(data.label);
      this.barChartData.datasets[0].data.push(data.value);
      colorData.push(data.color);
      this.barChartLinks.push(data.link);
    });
    this.barChartData.datasets[0].backgroundColor = colorData;
    this.barChartData.datasets[0].borderColor = colorData;
    this.barChartData.datasets[0].hoverBackgroundColor = colorData;
    this.barChartData.datasets[0].hoverBorderColor = colorData;
    this.chart?.update();
  }
}
