<section class = "order-section">
  <div class="icon-text header">
    <div class="custom-icon order_lrg_cir_white"></div>
    <h1>Orders</h1>
  </div>
  <div class="order_content">
    <!-- Ticket Count -->
    <div class="loading-indicator" *ngIf="isLoading$ | async">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="order_count" *ngIf="!(isLoading$ | async)">
        <div class="number">{{ (totalOpenOrders$ | async).length }}</div>
        <div class="description">
          <div class="order_status upper">Active</div>
          <div class="order_label">Order{{ (totalOpenOrders$ | async).length > 1 ? 's' : '' }}</div>
        </div>
    </div>
    <!-- Ticket Icon -->
    <div class="order_icon" *ngIf="!(isLoading$ | async)">
      <div class="total-locations">Spanning {{ (totalActiveLocations$ | async) }} locations</div>
    </div>
  </div>
  <div class="widget_cta"><a routerLink="/orders">View Orders</a></div>
  <a routerLink="/orders" tabindex="-1" class="dashboard-link desktop"><div class="cta-link">desktop</div></a>
</section>
