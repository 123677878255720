/* NgRx */
import { Action } from '@ngrx/store';

export const UPDATE_BROWSER_SIZE_NAME = '[Browser] Update Browser Size Name';
export const UPDATE_BROWSER_WIDTH = '[Browser] Update Browser Width';
export const UPDATE_BROWSER_HEIGHT = '[Browser] Update Browser Height';

export class UpdateBrowserSizeName implements Action {
  readonly type = UPDATE_BROWSER_SIZE_NAME;
  constructor(public payload: string) {}
}

export class UpdateBrowserWidth implements Action {
  readonly type = UPDATE_BROWSER_WIDTH;
  constructor(public payload: number) {}
}

export class UpdateBrowserHeight implements Action {
  readonly type = UPDATE_BROWSER_HEIGHT;
  constructor(public payload: number) {}
}

// action types
export type BrowserAction = UpdateBrowserSizeName | UpdateBrowserWidth | UpdateBrowserHeight;
