import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CountryCode } from '../../../models/countryCodeModel';
import { countryCodeListModel } from '../../../models/countryCodeListModel';
import { phoneNumberValidator } from '../../validators/phoneNumberValidator';
import { ViewChild } from '@angular/core';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'app-radio-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.scss', '../../../tickets/containers/create-ticket/create-ticket.component.scss']
})
export class RadioSelectComponent implements OnInit {
  @Input() formRef: UntypedFormGroup;
  @Input() reporter: any;
  @Input() inlineErrors: any = {};
  @Input() altContact: boolean = true;
  @Output() changeContact = new EventEmitter();
  @ViewChild('alternatePhoneRadioButton') alternatePhoneRadioButton: MatRadioButton;

  altPhoneCountry: string;
  countryCodes: CountryCode[] = countryCodeListModel();
  AltPhone = 'Alternate Phone';

  ngOnInit(): void {
    this.formRef.addValidators(phoneNumberValidator());
  }

  onRadioChange() {
    // this.showAltContactOptions = false;
    if (this.formRef.get('preferredContactMethod').value === 'Alternate Phone') {
      this.setAltContactValues(this.AltPhone, true, false);
    } else {
      this.setAltContactValues(null, false, true);
    }
    this.changeContact.emit();
  }

  onAltContactValueChange() {
    this.formRef.errors;
    this.formRef.get('alternatePhone').statusChanges.subscribe((val) => {
      if (val === 'INVALID') {
        this.inlineErrors.alternatePhone = 'Invalid phone number.';
      } else {
        this.inlineErrors.alternatePhone = '';
      }
    });
  }

  selectAlternatePhoneRadioButton() {
    this.alternatePhoneRadioButton.checked = true;
  }

  altPhoneKeypress = (event: KeyboardEvent) => {
    // google-libphonenumber can do some funny things at times,
    // such as trying to map alphas to numbers.
    // Limit what the user can enter
    const allowableChars = [
      { key: 'Tab', keyCode: 0x09 },
      { key: ' ', keyCode: 0x20 },
      { key: 'Spacebar', keyCode: 0x20 },
      { key: '0', keyCode: 0x30 },
      { key: '1', keyCode: 0x31 },
      { key: '2', keyCode: 0x32 },
      { key: '3', keyCode: 0x33 },
      { key: '4', keyCode: 0x34 },
      { key: '5', keyCode: 0x35 },
      { key: '6', keyCode: 0x36 },
      { key: '7', keyCode: 0x37 },
      { key: '8', keyCode: 0x38 },
      { key: '9', keyCode: 0x39 },
      { key: 'Add', keyCode: 0xab },
      { key: '+', keyCode: 0xab },
      { key: 'Multiply', keyCode: 0xaa },
      { key: '*', keyCode: 0xaa },
      { key: '#', keyCode: '#' },
      { key: 'Decimal', keyCode: 0xbe },
      { key: '.', keyCode: 0xbe },
      { key: 'Subtract', keyCode: 0xad },
      { key: '-', keyCode: 0xad },
      { key: ',', keyCode: 0xbc },
      { key: '(', keyCode: 0xa8 },
      { key: ')', keyCode: 0xa9 }
    ];

    // event.keyCode is deprecated, use event.key if it's available.
    if (event.key) {
      if (!allowableChars.some((c) => c.key === event.key)) {
        return false;
      }
    } else {
      if (!allowableChars.some((c) => c.keyCode === event.keyCode)) {
        return false;
      }
    }
  };

  setAltContactValues(altContactMethod: string, showFullInputField: boolean, resetAltContactValue: boolean = false) {
    if (altContactMethod) {
      this.formRef.controls.alternatePhone.setValidators([Validators.required]);
      this.formRef.controls.countryCode.setValidators([Validators.required]);
      this.formRef.controls.preferredContactMethod.clearValidators();
    } else {
      this.altContact ? this.formRef.controls.alternatePhone.setValidators(null) : null;
      this.altContact ? this.formRef.controls.alternateContactMethod.setValidators(null) : null;
      this.formRef.controls.preferredContactMethod.setValidators(Validators.required);
    }
    if (resetAltContactValue) {
      this.altContact ? this.formRef.controls.alternatePhone.setValue(null) : null;
    }
    this.formRef.updateValueAndValidity();
  }
}
