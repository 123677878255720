/* eslint-disable @typescript-eslint/lines-between-class-members */
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login/login.component.scss', './forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements AfterViewInit {
  forgotPasswordFormGroup: UntypedFormGroup;
  displayForm = true;
  usernameValid = false;
  errorMessage = '';
  isLoading = false;
  authSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.forgotPasswordFormGroup = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required])
    });
  }

  ngAfterViewInit(): void {
    this.renderer.selectRootElement('#username').focus();
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  submitForgotPasswordForm(): void {
    const username = this.forgotPasswordFormGroup.controls.username.value;
    this.errorMessage = '';

    if (!username) {
      this.errorMessage = 'Please enter a username.';
      return;
    }
    const regex = /(\s*$)|(^\s*)/g;
    const cleanUsername = username.replace(regex, '');
    this.isLoading = true;
    this.authSubscription = this.authService.forgotPassword(cleanUsername).subscribe(
      () => {
        this.isLoading = false;
        this.displayForm = false;
        this.changeDetectorRef.detectChanges();
      },
      (err) => {
        this.isLoading = false;
        if (err.status === 404) {
          this.errorMessage = 'Invalid username or password.';
        } else {
          this.errorMessage =
            err.statusText !== '' ? err.statusText : 'We are unable to log you in.  Please try again later';
        }
        this.changeDetectorRef.detectChanges();
      }
    );
    // return username;
  }
}
