<div class="order-detail">

	<h4>Key Project Contacts</h4>
	<div class="contact-box" *ngFor="let i of data;">
		<span class="contact-position">{{ i.position }}</span>
		<div class="contact-data">
			<div class="contact-line">
				<span class="contact-name">{{ i.name }}</span>
				<span class="contact-phone">{{ i.phone1 }}</span>
			</div>
			<div class="contact-line">
				<span class="contact-email" *ngIf="i.email">
					<a href="mailto:{{i.email}}">{{ i.email }}</a>
				</span>
				<span class="contact-phone">{{ i.phone2 }}</span>
			</div>
		</div>
	</div>

</div>
