<section class="login">
  <div class="loading" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div class="login-form-wrapper">
    <div class="logo">Nitel Logo</div>
    <app-banner-message *ngIf="passwordChanged" [message]="messages.passwordChanged" [dismiss]="true" status="success">
    </app-banner-message>
    <app-banner-message *ngIf="sessionExpired" [message]="messages.sessionExpired" [dismiss]="true" status="warning">
    </app-banner-message>
    <app-banner-message *ngIf="loggedOut" [message]="messages.loggedOut" [dismiss]="true" status="success">
    </app-banner-message>

    <div class="split-row login-form">
      <form [formGroup]="loginFormGroup" novalidate (ngSubmit)="login()" autocomplete="off">
        <div class="alert alert-danger expanded" *ngIf="errorMessage">
          <div class="custom-icon inline_error">inline error</div>
          {{ errorMessage }}
        </div>
        <label class="username-wrapper">
          <span>Username</span>
          <input
            formControlName="username"
            id="usernameId"
            class="form-control"
            type="text"
            placeholder="Enter Username"
            required
            name="username"
            [ngClass]="{ 'is-invalid': !!messages.usernameInput }"
          />
          <div class="alert alert-danger" [ngClass]="{ expanded: !!messages.usernameInput }">
            <div class="custom-icon inline_error">inline error</div>
            {{ messages.usernameInput }}
          </div>
        </label>

        <label class="password-wrapper">
          <span>Password</span>
          <input
            formControlName="password"
            id="passwordId"
            class="form-control"
            type="password"
            placeholder="Enter Password"
            required
            name="password"
            [ngClass]="{ 'is-invalid': !!messages.passwordInput }"
          />
          <div class="alert alert-danger" [ngClass]="{ expanded: !!messages.passwordInput }">
            <div class="custom-icon inline_error">inline error</div>
            {{ messages.passwordInput }}
          </div>
        </label>

        <div class="reset-wrapper">
          <a routerLink="/forgot-password" title="Forgot your password?">Forgot your password?</a>
        </div>
        <div class="button-wrapper">
          <button class="button" type="submit">
            <span>Login</span>
          </button>
        </div>
        <div class="toggle-container">
          <label class="toggle-wrapper"
            >Keep me logged in
            <input formControlName="toggle" type="checkbox" name="toggle" />
            <span class="checkmark"></span>
          </label>
        </div>
      </form>
    </div>
  </div>
</section>
