import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { Service } from 'src/app/models/service.model';

@Component({
  selector: 'app-ticket-noc',
  templateUrl: './ticket-noc.component.html',
  styleUrls: ['../../containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketNocComponent implements OnInit {
  @Input() services: Service[];
  @Input() selectedServiceId: string;
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Input() primaryReporters: Contact[];
  @Input() submissionError: boolean;
  @Input() isSubmitting: boolean;
  @Output() submitNocForm = new EventEmitter();
  @Input() errorMessage: string;

  nocFormGroup: UntypedFormGroup;
  protected uniqueLocations: any[] = [];
  protected allServices: any[] = [];
  loadingServices = true;
  loadingUsers = true;
  inlineErrors: any = {};
  formSubmitAttempted = false;
  selectedService: {};

  constructor() {
    this.nocFormGroup = new UntypedFormGroup({
      reporter: new UntypedFormControl('', Validators.required),
      locationOfService: new UntypedFormControl(''),
      service: new UntypedFormControl('', Validators.required),
      serviceArea: new UntypedFormControl('', Validators.required),
      preferredContactMethod: new UntypedFormControl('phone', Validators.required),
      alternateContactMethod: new UntypedFormControl(''),
      alternatePhone: new UntypedFormControl(''),
      description: new UntypedFormControl('', Validators.required),
      identifyIssueBy: new UntypedFormControl('location'),
      isPowerOn: new UntypedFormControl(''),
      canPerformIntrusiveTests: new UntypedFormControl(''),
      testDays: new UntypedFormControl(''),
      testHoursStart: new UntypedFormControl(''),
      testHoursEnd: new UntypedFormControl(''),
      accessHours: new UntypedFormControl('Anytime'),
      siteLocalFirst: new UntypedFormControl(''),
      siteLocalLast: new UntypedFormControl(''),
      siteLocalPhone: new UntypedFormControl(''),
      siteLocalEmail: new UntypedFormControl(''),
      customerTicketNumber: new UntypedFormControl(''),
      countryCode: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.canSubmit();
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.nocFormGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.nocFormGroup.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.nocFormGroup.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }

    return this.nocFormGroup.valid;
  }

  changeServiceArea() {
    if (
      this.nocFormGroup.value.serviceArea === 'My Service Is Down' ||
      this.nocFormGroup.value.serviceArea === 'My Service is Degraded or Intermittent'
    ) {
      this.nocFormGroup.controls['isPowerOn'].setValidators(Validators.required);
      this.nocFormGroup.controls['canPerformIntrusiveTests'].setValidators(Validators.required);
      this.nocFormGroup.controls['accessHours'].setValidators(Validators.required);
      this.nocFormGroup.controls['siteLocalFirst'].setValidators(Validators.required);
      this.nocFormGroup.controls['siteLocalLast'].setValidators(Validators.required);
      this.nocFormGroup.controls['siteLocalPhone'].setValidators(Validators.required);
      this.nocFormGroup.controls['siteLocalEmail'].setValidators(Validators.required);
    } else {
      this.nocFormGroup.controls['isPowerOn'].clearValidators();
      this.nocFormGroup.controls['canPerformIntrusiveTests'].clearValidators();
      this.nocFormGroup.controls['accessHours'].clearValidators();
      this.nocFormGroup.controls['siteLocalFirst'].clearValidators();
      this.nocFormGroup.controls['siteLocalLast'].clearValidators();
      this.nocFormGroup.controls['siteLocalPhone'].clearValidators();
      this.nocFormGroup.controls['siteLocalEmail'].clearValidators();
      this.nocFormGroup.controls['testDays'].clearValidators();
      this.nocFormGroup.controls['testHoursStart'].clearValidators();
      this.nocFormGroup.controls['testHoursEnd'].clearValidators();
    }

    this.canSubmit();
  }

  changeintrusiveTesting() {
    if (this.nocFormGroup.value.canPerformIntrusiveTests === 'Yes (Custom)') {
      this.nocFormGroup.controls.testDays.setValidators(Validators.required);
      this.nocFormGroup.controls.testHoursStart.setValidators(Validators.required);
      this.nocFormGroup.controls.testHoursEnd.setValidators(Validators.required);
    } else {
      this.nocFormGroup.controls.testDays.clearValidators();
      this.nocFormGroup.controls.testHoursStart.clearValidators();
      this.nocFormGroup.controls.testHoursEnd.clearValidators();
    }
    this.canSubmit();
  }

  submitTicket() {
    this.formSubmitAttempted = true;
    const selectedService = this.services.find((service) => service.Id === this.nocFormGroup.value.service);
    if (this.canSubmit()) {
      const request = {
        contactId: this.nocFormGroup.value.reporter,
        customerTicketNumber: this.nocFormGroup.value.customerTicketNumber,
        portalcustomcontact__c: this.nocFormGroup.value.alternatePhone,
        description: this.nocFormGroup.value.description,
        service__c: selectedService.Id,
        portal_ticket_type__c: this.nocFormGroup.value.serviceArea,
        preferred_contact_method__c:
          this.nocFormGroup.value.preferredContactMethod || this.nocFormGroup.value.alternateContactMethod,
        canPerformIntrusiveTests: this.nocFormGroup.value.canPerformIntrusiveTests,
        testDays: this.nocFormGroup.value.testDays,
        testHoursStart: new Date(`${new Date().toDateString()} ${this.nocFormGroup.value.testHoursStart}`),
        testHoursEnd: new Date(`${new Date().toDateString()} ${this.nocFormGroup.value.testHoursEnd}`),
        isPowerOn: this.nocFormGroup.value.isPowerOn,
        accessHours: this.nocFormGroup.value.accessHours,
        siteLocalFirst: this.nocFormGroup.value.siteLocalFirst,
        siteLocalLast: this.nocFormGroup.value.siteLocalLast,
        siteLocalPhone: this.nocFormGroup.value.siteLocalPhone,
        siteLocalEmail: this.nocFormGroup.value.siteLocalEmail
      };
      this.submitNocForm.emit(request);
    }
  }

  usersLoaded() {
    this.loadingUsers = false;
  }

  servicesLoaded() {
    this.loadingServices = false;
  }
}
