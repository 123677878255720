import { Action } from '@ngrx/store';

export const LOGOUT = '[App] Logout';

export class Logout implements Action {
  readonly type = LOGOUT;
}

// action types
export type logoutAction = Logout;
