import { Order, OrderType } from './order';
import OrderInfoDetail from '../order-info.interface';

export class OrderSdWan extends Order {

	constructor()	{
		super();
		this.type = OrderType.SDWAN;
	}

	public getRowInfo(): OrderInfoDetail[] {
		return [
			{
				title: 'Equipment Type',
				value: this.equipment,
			},
			{
				title: 'Equipment Tracking Number',
				value: this.tracking_number,
			},
			{
				title: 'Shipping Address',
				value: this.shipping_address,
			},
		];
	}

}
