import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from 'src/app/models/api-url.model';
import { Task } from 'src/app/models/task.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  constructor(private apiService: ApiService) {}

  getTasks(orderId: string): Observable<Task[]> {
    return this.apiService.get(`${api.ordersURL}/${orderId}${api.tasksURL}`);
  }
}
