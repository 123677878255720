<section class="ticket-content-container">
  <div class="custom-icon close" (click)="closeDialog()"></div>
  <section class="form-type-container">
    <form [formGroup]="locationNameFormGroup" (ngSubmit)="onSubmit()">
      <h1>Edit Location Name</h1>
      <div class="location-address">
        <div class="custom-icon location_dark">location icon</div>
        {{ locationAddress }}
      </div>
      <div class="input-label">
        <span>Current Name</span>
      </div>
      <mat-form-field floatLabel="never" class="location-name-alias">
        <mat-label>Enter new name</mat-label>
        <input matInput formControlName="locationNameAlias" rows="1" (keyup.enter)="onSubmit()" />
      </mat-form-field>
      <mat-error *ngIf="hasError"> <strong>Error:</strong> Location name was not saved. Please try again!</mat-error>
      <div class="button-wrapper">
        <button class="button delete-btn" (click)="onDelete()"><span>Delete</span></button>
        <button class="button" (click)="onSubmit()"><span>Save</span></button>
      </div>
    </form>
  </section>
</section>
