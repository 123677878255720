import { APP_INITIALIZER, CSP_NONCE, Provider } from "@angular/core";
import { NonceService } from "./services/nonce.service"; // adjust the import path as necessary
import { environment } from "src/environments/environment";
import { RouterStateSerializer } from "@ngrx/router-store";
import { CustomSerializer } from "./store/reducers/router.reducer";

export const googleMeasurementId = environment.production
  ? "G-S1MYCEH0GM"
  : "G-8V2BK3ZR53";

function initializeAppFactory(nonceService: NonceService): () => Promise<void> {
  return () => nonceService.getNonce().then(() => {});
}

function cspNonceFactory(nonceService: NonceService): string {
  return nonceService.getCachedNonce();
}

export const initializeAppProviders: Provider[] = [
  { provide: RouterStateSerializer, useClass: CustomSerializer },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [NonceService],
    multi: true,
  },
  { provide: "googleTagManagerId", useValue: googleMeasurementId },
  {
    provide: "googleTagManagerCSPNonce",
    useFactory: cspNonceFactory,
    deps: [NonceService],
  },
  // {
  //   provide: CSP_NONCE,
  //   useFactory: cspNonceFactory,
  //   deps: [NonceService]
  // }
];
