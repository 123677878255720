import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/contacts.reducer';

export const getContactsEntities = createSelector(fromReducer.getContactsFeatureState, fromReducer.getContactsEntities);

export const getContacts = createSelector(getContactsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getContactsLoaded = createSelector(fromReducer.getContactsFeatureState, fromReducer.getContactsLoaded);
export const getContactsLoading = createSelector(fromReducer.getContactsFeatureState, fromReducer.getContactsLoading);
export const getContactsError = createSelector(fromReducer.getContactsFeatureState, fromReducer.getContactsError);
