<section
  class="orders-page order-view parent-container custom-order"
  (window:resize)="onWindowResize()"
>
  <orders-top></orders-top>

  <div class="order-status-view">
    <section class="widget orders-list">
      <div class="headerWrapper" #headerContainer>
        <div class="widget-header">
          <div class="icon-text">
            <div class="custom-icon order-icon">order icon</div>
            <h1>Order Status</h1>
          </div>

          <div class="button-wrapper-order">
            <a href="/orders-table" title="View Orders List">
              <span>View Orders List</span>
              <mat-icon>assignment</mat-icon>
            </a>
          <div class="csv-export" *ngIf="formattedCSVData.length > 0">
            <span>Download</span>
            <csv-download
              [csvData]="formattedCSVData"
              [fileName]="'Orders-Status.csv'"
            ></csv-download>
          </div>
          </div>
        </div>

        <div class="widget-header" *ngIf="!(isLoading$ | async)">
          <div class="search-wrapper">
            <mat-form-field>
              <div class="custom-icon search">search icon</div>
              <input
                matInput
                [formControl]="searchTermControl"
                placeholder="Search"
                autocomplete="on"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="scrolling-list">
        <mat-accordion *ngIf="orders$ | async as orders">
          <div class="loading-indicator" *ngIf="isLoading$ | async">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
          <ng-container *ngIf="orders$ | async as orders">
            <div
              class="orders-scroll"
              *ngIf="orders.length === 0 && !(isLoading$ | async)"
            >
              <div class="orders-empty">
                There are currently no pending orders.
              </div>
            </div>

            <div
              class="orders-scroll"
              *ngIf="orders.length > 0 && !(isLoading$ | async)"
            >
              <div
                class="orders-block"
                id="ordersData"
                *ngFor="
                  let order of orders
                    | filter : searchTerm
                    | paginate : { itemsPerPage: 10, currentPage: page };
                  index as x
                "
              >
                <mat-expansion-panel>
                  <mat-expansion-panel-header
                    [collapsedHeight]="'50px'"
                    class="matDescription"
                    (click)="
                      onSelect({
                        detailView: 'Order',
                        orderId: order.Id,
                        lineItemId: null
                      })
                    "
                    (keyup.enter)="
                      onSelect({
                        detailView: 'Order',
                        orderId: order.Id,
                        lineItemId: null
                      })
                    "
                    [class.selected]="
                      order.Id === (selectedOrderId$ | async) &&
                      (detailView$ | async) === viewsObject.order &&
                      browserSize !== 'small'
                    "
                  >
                    <mat-panel-description>
                      <order-list-display
                        class="orderList"
                        [order]="order"
                        [isSelected]="
                          order.Id === (selectedOrderId$ | async) &&
                          (detailView$ | async) === viewsObject.order
                        "
                      >
                      </order-list-display>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div
                    class="recordList"
                    *ngFor="
                      let line of order.Customer_Order_OLI__r?.records;
                      index as x
                    "
                    tabindex="0"
                    (click)="
                      onSelect({
                        detailView: 'LineItem',
                        orderId: order.Id,
                        lineItemId: line.Id
                      })
                    "
                    (keyup.enter)="
                      onSelect({
                        detailView: 'LineItem',
                        orderId: order.Id,
                        lineItemId: line.Id
                      })
                    "
                    [class.selected]="
                      line.Id === (selectedLineItemId$ | async) &&
                      (detailView$ | async) === viewsObject.lineItem
                    "
                  >
                    <line-item-list-display
                      [order]="order"
                      [lineItem]="line"
                      [isSelected]="
                        line.Id === (selectedLineItemId$ | async) &&
                        (detailView$ | async) === viewsObject.lineItem
                      "
                    >
                    </line-item-list-display>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </ng-container>
        </mat-accordion>
        <pagination-controls
          (pageChange)="page = $event"
          [autoHide]="true"
        ></pagination-controls>
      </div>

      <app-footer *ngIf="browserSize === 'small'"></app-footer>
    </section>

    <orders-detail-shell
      *ngIf="browserSize !== 'small'"
      [detailView]="detailView$ | async"
    ></orders-detail-shell>
  </div>
</section>
