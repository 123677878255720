<div class="widget need-help bookends" *ngxPermissionsOnly="'Ticketing: Write'">
  <div class="customer-cta icon-text">
    <div class="custom-icon customer"></div>
    <div class="header-label">
      <span class="custom-icon help"></span>
      <div class="blimp-question" #toggleHelpBlimpButton (click)="toggleBlimp()">Have a question?</div>
    </div>
    <div #helpBlimpView class="help-blimp" [ngClass]="{ visible: showBlimp }">
      <div class="arrow-up"></div>
      <h3 #helpBlimpView>Need Help?</h3>
      <p #helpBlimpView>Create a ticket with the subject “Billing” and we&apos;ll take it from there!</p>
    </div>
  </div>
  <ng-container *ngxPermissionsOnly="'Ticketing: Write'">
    <div class="button-wrapper" *ngIf="hasInvoice">
      <button class="button" (click)="onCreateTicket()">
        <span>Create Ticket</span>
      </button>
    </div>
  </ng-container>
</div>
