import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { Service } from 'src/app/models/service.model';

@Component({
  selector: 'app-ticket-care',
  templateUrl: './ticket-care.component.html',
  styleUrls: ['../../containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketCareComponent implements OnInit {
  @Input() services: Service[];
  @Input() selectedServiceId: string;
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Input() primaryReporters: Contact[];
  @Input() submissionError: boolean;
  @Input() isSubmitting: boolean;
  @Output() submitCareForm = new EventEmitter();
  @Input() errorMessage: string;

  careFormGroup: UntypedFormGroup;
  protected uniqueLocations: any[] = [];
  protected allServices: any[] = [];
  loadingServices = true;
  loadingUsers = true;
  inlineErrors: any = {};
  formSubmitAttempted = false;
  constructor() {
    this.careFormGroup = new UntypedFormGroup({
      reporter: new UntypedFormControl(''),
      locationOfService: new UntypedFormControl(''),
      service: new UntypedFormControl(''),
      Business_Purpose__c: new UntypedFormControl(''),
      subject: new UntypedFormControl('', Validators.required),
      preferredContactMethod: new UntypedFormControl('phone'),
      alternateContactMethod: new UntypedFormControl(''),
      alternatePhone: new UntypedFormControl(''),
      description: new UntypedFormControl('', Validators.required),
      identifyIssueBy: new UntypedFormControl('location'),
      // customerTicketNumber: new UntypedFormControl(''),
      countryCode: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.canSubmit();
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.careFormGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.careFormGroup.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.careFormGroup.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }

    return this.careFormGroup.valid;
  }

  submitTicket() {
    this.formSubmitAttempted = true;

    if (this.canSubmit()) {
      const request = {
        Business_Purpose__c: 'Care - Other',
        contactId: this.careFormGroup.value.reporter,
        // customerTicketNumber: this.careFormGroup.value.customerTicketNumber,
        portalcustomcontact__c: this.careFormGroup.value.alternatePhone,
        description: this.careFormGroup.value.description,
        service__c: this.careFormGroup.value.service,
        preferred_contact_method__c:
          this.careFormGroup.value.preferredContactMethod || this.careFormGroup.value.alternateContactMethod,
        subject: this.careFormGroup.value.subject
      };
      this.submitCareForm.emit(request);
    }
  }

  usersLoaded() {
    this.loadingUsers = false;
  }

  servicesLoaded() {
    this.loadingServices = false;
  }
}
