<section>
  <h1>Create <span class="emphasis">Transfer of Services</span> Ticket</h1>

  <form
    [formGroup]="tobTxGroup"
    *ngIf="tobTxGroup"
    [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
    autocomplete="off"
  >
    <h2>Contact Information</h2>
    <app-primary-reporter
      [formRef]="tobTxGroup"
      [reporters]="primaryReporters"
      [altContact]="false"
      (usersLoaded)="usersLoaded()"
    >
    </app-primary-reporter>

    <h2>New Account Information</h2>
    <h3>New Account Name</h3>
    <mat-form-field floatLabel="never">
      <mat-label>Account Name</mat-label>
      <input matInput type="text" formControlName="newAccountName" (keyup)="canSubmit()" />
    </mat-form-field>

    <h3>New FEIN#</h3>
    <mat-form-field floatLabel="never">
      <mat-label>FEIN #</mat-label>
      <input matInput type="text" formControlName="newFEIN" (keyup)="canSubmit()" />
    </mat-form-field>

    <h3>New Billing Address</h3>
    <ng-container formGroupName="newAddress">
      <mat-form-field floatLabel="never">
        <mat-label>Street</mat-label>
        <input matInput type="text" formControlName="street1" (keyup)="canSubmit()" />
      </mat-form-field>
      <mat-form-field floatLabel="never">
        <mat-label>Apt, Suite, Floor (optional)</mat-label>
        <input matInput type="text" formControlName="street2" />
      </mat-form-field>
      <div class="address-merged">
        <mat-form-field floatLabel="never">
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city" (keyup)="canSubmit()" />
        </mat-form-field>
        <mat-form-field floatLabel="never">
          <mat-label>State</mat-label>
          <input matInput type="text" formControlName="state" (keyup)="canSubmit()" />
        </mat-form-field>
        <mat-form-field floatLabel="never">
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="zip" (keyup)="canSubmit()" />
        </mat-form-field>
      </div>
    </ng-container>

    <h3>Desired transfer date</h3>
    <mat-form-field class="date-picker" floatLabel="never">
      <mat-label>Please select a date</mat-label>
      <input
        matInput
        formControlName="desiredDate"
        (dateChange)="canSubmit()"
        [matDatepickerFilter]="filterDates"
        [matDatepicker]="picker"
        placeholder="Please select"
        (keyup)="canSubmit()"
        (click)="picker.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon svgIcon="nitelCalendar" matDatepickerToggleIcon></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker [calendarHeaderComponent]="customCalHeader" [startAt]="startCalendarAt"></mat-datepicker>
    </mat-form-field>

    <h2>Services To Transfer</h2>
    <app-select-multiple-services
      [formRef]="tobTxGroup"
      [inlineErrors]="inlineErrors"
      (servicesLoaded)="servicesLoaded()"
      [selectedServiceId]="selectedServiceId"
      (keyup)="canSubmit()"
    >
    </app-select-multiple-services>

    <h3>Additional comments</h3>
    <mat-form-field class="custom-textarea" floatLabel="never">
      <mat-label>Any additional feedback or comments you'd like to give?</mat-label>
      <textarea matInput formControlName="additionalComment" rows="5"></textarea>
    </mat-form-field>

    <div class="form-error" *ngIf="(inlineErrors | json) != '{}'">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">Please fill out the required field(s) circled in red.</div>
    </div>

    <div class="server-error" *ngIf="submissionError">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ errorMessage }}</div>
    </div>

    <div class="loading-indicator" *ngIf="isSubmitting">
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>

    <button
      class="button submit-button"
      (click)="submitTicket()"
      [disabled]="isLoadingServices || loadingUsers || isSubmitting"
    >
      <span>Submit ticket</span>
    </button>
  </form>
</section>
