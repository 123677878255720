import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NodeService } from 'src/app/services/node.service';
import * as nodeActions from '../actions/nodes.action';

@Injectable()
export class NodeEffects {
  constructor(private actions$: Actions, private nodeService: NodeService) {}

  loadDownNodes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nodeActions.LOAD_DOWN_NODES),
      switchMap(() => {
        return this.nodeService.getDownNodes().pipe(
          map((nodes) => new nodeActions.LoadDownNodesSuccess(nodes)),
          catchError((error) => of(new nodeActions.LoadDownNodesFail(error)))
        );
      })
    );
  });
}
