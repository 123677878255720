import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Service } from "../../../models/service.model";

@Component({
  selector: "app-service-top-header",
  templateUrl: "./service-top-header.component.html",
  styleUrls: ["./service-top-header.component.scss"],
})
export class ServiceTopHeaderComponent implements OnChanges {
  @Input() services: Service[];
  @Input() selectedService: Service;
  @Input() activeServices: Service[];

  totalServices: number = 0;
  totalLocations: number = 0;

  pendingServices: number = 0;
  inServiceServices: number = 0;
  serviceTypeObject: { [recordTypeName: string]: number; };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.services) {
      const services = this.services || [];
      this.pendingServices = services.filter(s => ['New', 'Pending'].includes(s.Status__c)).length;
      this.inServiceServices = services.filter(s => s.Status__c === 'In Service').length;
    }
    if (changes.activeServices) {
      this.getTotalActiveRecords();
    }
    this.getRecordTypeCounts();
  }
  
  getRecordTypeCounts(){
    const recordTypeCounts: { [recordTypeName: string]: number } = {};
  
    this.services.filter(s => !['Canceled', 'Disconnected', 'New', 'Pending'].includes(s.Status__c)).forEach((service) => {
      const recordTypeName = service.RecordType.Name;
      if (recordTypeCounts[recordTypeName]) {
        recordTypeCounts[recordTypeName]++;
      } else {
        recordTypeCounts[recordTypeName] = 1;
      }
    });
  
    this.serviceTypeObject = recordTypeCounts;
  }

  private getTotalActiveRecords() {
      this.totalServices = this.activeServices.length;

      // get locations & filter out duplicates
      this.totalLocations = this.activeServices
        .map((a) => a.Z_Location__r.Name)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort()
        .length;
  }
}
