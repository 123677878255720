import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { AuthService } from 'src/app/services/auth.service';
import { widgetTypes } from '../widget-types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
  widgetTypes = widgetTypes;
  subscription: Subscription;
  browserSize$: Observable<string>;
  browserSize: string;
  hasOrionCredentials = !!this.authService.vigilURL;
  dashboardPerm = this.authService?.apiAccount?.user?.Permissions?.includes('Dashboard');

  constructor(private store: Store<fromStore.State>, private authService: AuthService) {
    this.browserSize$ = this.store.select(fromStore.getBrowserSizeName);
    this.subscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });
  }

}
