<section class="card">
	<div class="widget-container" [ngClass]="extraClass">
		<h3>{{ title }}</h3>

		<div class="loading-spinner" *ngIf="loading">
			<mat-spinner [diameter]="50"></mat-spinner>
		</div>

		<span *ngIf="!loading && list.length == 0" class="empty-msg">
			{{ emptyMessage }}
		</span>

		<ul  class="list-container" *ngIf="!loading">
			<li *ngFor="let item of list;" (click)="goLink(item.link);" [ngClass]="{ 'link': item.link != null }">
				<div class="service-icon" *ngIf="item.icon != null">
					<mat-icon [inline]="true">{{ item.icon }}</mat-icon>
				</div>
				<div class="info">
					<span class="val">{{ item.value }}</span>
					<span class="label">{{ item.title }}</span>
				</div>
				<div *ngIf="showLinks" class="arrow">
					<mat-icon [inline]="true">chevron_right</mat-icon>
				</div>
			</li>
		</ul>

	</div>
</section>
