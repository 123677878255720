/* eslint-disable no-shadow */
import { createFeatureSelector } from '@ngrx/store';
import { Contact } from 'src/app/models/contact.model';
import * as fromActions from '../actions';
import * as fromRoot from '.';

// State for this feature (Contacts)
export interface ContactState {
  entities: { [id: string]: Contact };
  loading: boolean;
  loaded: boolean;
  error: string;
}

const initialState: ContactState = {
  entities: {},
  loading: true,
  loaded: false,
  error: ''
};

export interface State extends fromRoot.State {
  contacts: ContactState;
}

export const getContactsFeatureState = createFeatureSelector<ContactState>('contacts');

export function reducer(state = initialState, action: fromActions.ContactsAction): ContactState {
  switch (action.type) {
    case fromActions.LOAD_CONTACTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromActions.LOAD_CONTACTS_SUCCESS: {
      const contacts = action.payload;
      let entities = {};

      if (contacts !== null) {
        entities = contacts.reduce(
          (entities: { [id: string]: Contact }, contact) => {
            return {
              ...entities,
              [contact.Id]: contact
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    case fromActions.LOAD_CONTACTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getContactsEntities = (state: ContactState) => state.entities;
export const getContactsLoading = (state: ContactState) => state.loading;
export const getContactsLoaded = (state: ContactState) => state.loaded;
export const getContactsError = (state: ContactState) => state.error;
