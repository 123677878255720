import { Component, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OrdersParserService } from '../services/orders-parser.service';
import { StateService } from '../services/state.service';
import { Store } from '@ngrx/store';
import * as Orders from './models';
import { OrderState } from 'src/app/store/reducers/orders.reducer';
import { Order, OrderDetailViews} from 'src/app/models/order.model';
import * as fromStore from 'src/app/store/';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  public orders: Orders.Order[] = [];
  public selectedOrder: Orders.Order = null;
  public otherSiteServices: Orders.Order[] = [];
  public loading: boolean = true;
  public noOrders: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderStore: Store<OrderState>,
    private ref: ChangeDetectorRef,
    private service: OrdersParserService,
    private state: StateService
  ) {}

  ngOnInit() {
    this.loadOrders();
  }
  ngOnDestroy() {
    this.loading = true;
  }

  private checkOrderId(): void {
    let orderId = this.route.snapshot.paramMap.get('orderId');
    if (!orderId) return;
    let filteredOrder = this.orders.filter((order) => order.id == orderId);
    if (filteredOrder.length > 0) {
      this.selectedOrder = filteredOrder[0];
      this.otherSiteServices = this.getOtherSiteServices(this.selectedOrder);
    } else {
      console.error('could not load order with id ' + orderId);
    }
  }

  private loadOrders(): void {
    //False below is to denote that we are not calling getOrders from the orders-table page
    this.service.getOrders(false)?.subscribe((orders) => {
      if (!orders) {
        this.noOrders = true;
      } else if (orders.length == 0) {
        this.noOrders = true;
      }
      this.orders = orders;
      this.checkOrderId();
      this.loading = false;
      this.ref.detectChanges();
    });
  }


  public open(order): void {
    this.loading = true;
    window.setTimeout(() => {
      this.selectedOrder = order;
      this.otherSiteServices = this.getOtherSiteServices(order);
      this.loading = false;
      this.ref.detectChanges();
    }, 100);
  }

  public getOtherSiteServices(order: Orders.Order) {
    return this.orders.filter((o: Orders.Order) => {
      return o.address == order.address;
    });
  }

  public goBack() {
    this.loading = true;
    window.setTimeout(() => {
      this.selectedOrder = null;
      this.otherSiteServices = [];
      this.loading = false;
      this.ref.detectChanges();
    }, 100);
  }
}
