import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from 'src/app/store/';
import { Contact } from 'src/app/models/contact.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-primary-reporter',
  templateUrl: './primary-reporter.component.html',
  styleUrls: ['../../../tickets/containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryReporterComponent implements OnInit {
  @Input() formRef: UntypedFormGroup;
  @Input() inlineErrors: any = {};
  @Input() reporters: Contact[];
  @Input() altContact: boolean = true;
  @Output() usersLoaded = new EventEmitter(false);

  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  error$: Observable<string>;

  primaryReporter: Contact;
  userId = this.authService.userSFID;
  isLoadingReporters = true;

  constructor(private authService: AuthService, private store: Store<fromStore.State>) {}

  ngOnInit() {
    if (this.reporters) {
      // set currect user as main reporter
      this.primaryReporter = this.reporters.find((x) => x.Id === this.userId);
      this.formRef.controls.reporter.setValue(this.primaryReporter.Id);
    }

    this.usersLoaded.emit(true);
    this.isLoadingReporters = false;

    this.isLoading$ = this.store.select(fromStore.getContactsLoading);
    this.isLoaded$ = this.store.select(fromStore.getContactsLoaded);
  }

  canSubmit() {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.formRef.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.formRef.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.formRef.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
    return this.formRef.valid;
  }

  primaryReporterChanged(reporterId) {
    this.primaryReporter = this.reporters.find((rep) => {
      return rep.Id === reporterId.value;
    });
  }
}
