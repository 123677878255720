import { createSelector } from '@ngrx/store';

import { Order } from 'src/app/models/order.model';
import { LineItem } from 'src/app/models/line-item.model';
import * as fromFeature from '../reducers';
import * as fromRouters from '../reducers/router.reducer';
import * as fromOrders from '../reducers/orders.reducer';

export const getOrderState = createSelector(
  fromFeature.getFeatureOrdersState,
  (state: fromFeature.OrdersState) => state.orders
);

export const getOrdersEntities = createSelector(getOrderState, fromOrders.getOrdersEntities);
export const getTotalOpenOrders = createSelector(getOrderState, fromOrders.getTotalOpenOrders);

export const getAllOrders = createSelector(getOrdersEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getOrdersLoaded = createSelector(getOrderState, fromOrders.getOrdersLoaded);

export const getOrdersLoading = createSelector(getOrderState, fromOrders.getOrdersLoading);

// ROUTER SELECTORS
export const getCurrentRouterOrder = createSelector(
  getAllOrders,
  fromRouters.getRouterFeatureState,
  (orders, router): Order => {
    if (router.state) {
      const order = orders.find((x) => x.Id === router.state.params.orderId);
      return order;
    }
  }
);

export const getCurrentRouterLineItem = createSelector(
  getAllOrders,
  fromRouters.getRouterFeatureState,
  (orders, router): any => {
    if (router.state) {
      const order = orders.find((x) => x.Id === router.state.params.orderId);
      if (order) {
        if (order.Customer_Order_OLI__r) {
          const lineItem = order.Customer_Order_OLI__r.records.find((x) => x.Id === router.state.params.lineItemId);
          return lineItem;
        }
      }
    }
  }
);

// ORDER DETAIL DISPLAY FROM LIST SELECTORS
export const getOrderDetailView = createSelector(getOrderState, fromOrders.getOrderDetailView);

export const getSelectedOrderId = createSelector(getOrderState, fromOrders.getOrderSelected);

export const getSelectedOrder = createSelector(
  getOrdersEntities,
  getSelectedOrderId,
  (entities, orderId): Order => {
    return entities[orderId];
  }
);

// GET SELECTED
export const getSelectedLineItemId = createSelector(getOrderState, fromOrders.getOrderLineItemSelected);

export const getSelectedLineItem = createSelector(
  getOrdersEntities,
  getSelectedOrderId,
  getSelectedLineItemId,
  (entities, orderId, lineItemId): LineItem => {
    const selectedLineItem = entities[orderId].Customer_Order_OLI__r.records.find((x) => x.Id === lineItemId);
    return selectedLineItem;
  }
);

function getActiveOlis(orders) {
  return orders
    .filter((order) => order.Customer_Order_OLI__r)
    .map((o) => o.Customer_Order_OLI__r.records)
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter(
      (oli) =>
        oli.Service_Order2__r &&
        (oli.Service_Order2__r.Status__c === 'Ready' ||
          oli.Service_Order2__r.Status__c === 'Pending' ||
          oli.Service_Order2__r.Status__c === 'In Progress')
          && oli.Service__r.Status__c !== 'canceled'
    );
}
function getOlisOrderList(orders) {
  return orders
    .filter((order) => order.Customer_Order_OLI__r)
    .map((o) => {
      let newoliList = []
      const oliList = o.Customer_Order_OLI__r.records;
      oliList.forEach(oli => {
        newoliList.push({...oli, deal: o.Name, embeddedOrder: o})
      })
      
      return newoliList;
    })
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter(oli => oli.Service_Order2__r?.Status__c?.trim().length > 0);
}

export const getActiveOrders = createSelector(getAllOrders, (orders): Order[] => {
  const tempOrders =  getActiveOlis(orders).map((oli) => oli.Service_Order2__r);
  return tempOrders;
});

//TODO: Temp function for changing orders table
export const getActiveOrdersOrderTable = createSelector(getAllOrders, (orders): Order[] => {
  return getOlisOrderList(orders);
});

export const getTotalActiveLocations = createSelector(getAllOrders, (orders): number => {
  const locations = getActiveOlis(orders).map((oli) => oli.Location__c);
  const uniqueLocations = new Set(locations);

  return uniqueLocations.size;
});
