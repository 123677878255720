import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
} from "@angular/forms";
const ticketMap = {
  service: {
    ticketNumber: "Name",
    ticketName: "RecordType",
    ticketNameSecondary: "Name",
    vendor: "Vendor__r",
    alias: "Display_Alias__c",
  },
  disconnect: { ticketNumber: "nit", ticketName: "product" },
  tob: { ticketNumber: "nit", ticketName: "product" },
};

@Component({
  selector: "app-autocomplete-location",
  templateUrl: "./autocompleteLocation.component.html",
  styleUrls: ["./autocompleteLocation.component.scss"],
})
export class AutocompleteLocationComponent implements OnInit {
  @Input() formRef: UntypedFormGroup = new UntypedFormGroup({});
  @Input() allLocations: any = [];
  @Input() ticketType: any = [];
  @Input() identifyIssueBy: string;
  @Input() locationIterator: any;
  @Output() locationSelected: EventEmitter<any> = new EventEmitter();

  filteredOptions: Observable<any[]>;
  selectedServiceLocation: Observable<any>;
  selectedServiceID: Observable<any>;
  ticketNumberValue: String;
  ticketNameValue: String;
  ticketNameSecondary: String;
  ticketVendor: String;
  ticketAlias: String;
  allTempLocations = [];
  constructor() {}
  autocompleteLocationControl = new FormControl("");

  ngOnInit(): void {
    this.filteredOptions = this.autocompleteLocationControl.valueChanges.pipe(
      startWith(""),
      map((name) => (name ? this._filter(name) : this.allLocations.slice()))
    );
  }
  
  onLocationSelected(serviceId: any) {
    if (serviceId !== null) {
      const selectedLocation = this.allLocations.find(
        (service) => service.Z_Location__r.Name === serviceId.option.value
      );

      this.locationSelected.emit({
        locationAddress: selectedLocation.locationName,
        Id: selectedLocation.Z_Location__c,
        serviceName: serviceId.option.value,
        locationId: selectedLocation.locationId,
        locationIterator: this.locationIterator,
        value: serviceId.option.value,
      });
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toUpperCase();
    const ticketMapValue = ticketMap[this.ticketType];
    this.allTempLocations = this.allLocations.filter((option) =>
      option.Z_Location__r.Name.includes(filterValue)
    );
    return this.allTempLocations;
  }
}
