import { Injectable } from "@angular/core";
import * as Orders from "../orders-table/models";
import { types_dictionary } from "../orders-table/models/_order-types.dictionary";
import { transport_types } from "../orders-table/models/_transport-types.dictionary";

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import * as fromStore from "src/app/store/";

@Injectable({
  providedIn: "root",
})
export class OrdersParserService {
  private cachedOrders: Orders.Order[] = null;
  private debug: boolean = false;

  constructor(private store: Store<fromStore.State>) {}

  public clear(): void {
    this.cachedOrders = null;
  }

  public getEnumTypes(): any[] {
    return Object.values(Orders.OrderType)
      .filter((item) => !isNaN(Number(item)))
      .map((type: Orders.OrderType) => {
        return {
          value: type,
          label: Orders.getEnumOrderTypeName(type),
        };
      });
  }
  public getEnumStatus(): any[] {
    return Object.values(Orders.Status)
      .filter((item) => !isNaN(Number(item)))
      .map((st: Orders.Status) => {
        return {
          value: st,
          label: Orders.getEnumStatusName(st),
          child:
            st == Orders.Status.FOC_PENDING_WAITING_VENDOR ||
            st == Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION ||
            st == Orders.Status.FOC_PENDING_CARRIER_BUILD ||
            st == Orders.Status.FOC_PENDING_CUST_REQ
              ? true
              : false,
        };
      });
  }

  private validOrders(jsonData): any {
    const records = jsonData.map((row) => {
      const deal_status = row.Service__r?.Status__c;
      let record = Object.assign({}, row);
      record.deal = row.deal;
      record.id;
      record.deal_status = deal_status;
      record.contact_sales = this.parseContact(
        row.embeddedOrder.Sales_Rep__r,
        "Sales Engineer"
      );
      record.contact_manager = this.parseContact(
        row.embeddedOrder.Account_Manager__r
      );
      if (row.embeddedOrder.Default_Task_Assignments__r?.records) {
        const assignments =
          row.embeddedOrder.Default_Task_Assignments__r?.records;
        record.other_contacts = assignments.map((rec) => {
          return this.parseContact(rec.User__r);
        });
      }
      record.order_date = row.embeddedOrder.Order_Date__c;
      record.id = row.embeddedOrder.Id;
      return record;
    });
    return records.filter((el) => el != null);
  }
  private calculateStatus(record: any, order: Orders.Order): Orders.Status {
    const Status = Orders.Status;
    const sord = record.Service_Order2__r;
    let tasks = sord?.tasks;
    if (tasks) tasks = tasks[0];

    switch (record.Service_Order2__r?.Status__c) {
      case "In Progress":
        return Status.IN_PROGRESS;
      case "Ready":
      case "Pending":
        return Status.PENDING;
      case "Complete":
        return Status.COMPLETE;
      case "Canceled":
        return Status.CANCELED;
      default:
        return;
    }
  }

  private calculateStatusOrderTable(record: any, order: Orders.Order): Orders.Status {
    const type: Orders.OrderType = order.type;
    const service = record.Service__r;
    const line_type = record.Line_Item_Type__c;
    const Status = Orders.Status;
    const sord = record.Service_Order2__r;
    let tasks = sord?.tasks;
    if (tasks) tasks = tasks[0];
    if (sord?.Status__c == 'Canceled') return Status.CANCELED;
    if (line_type == 'Disconnect') {
      if (sord?.Status__c == 'Complete') return Status.DISCONNECTED;
      else return Status.DISCONNECTED_IN_PROGRESS;
    }
    if (line_type == 'Move/New') {
      if (sord?.Status__c == 'Complete') return Status.MOVE_REQUESTED;
      else return Status.MOVE_REQUESTED_IN_PROGRESS;
    }
    if (sord?.Status__c == 'Canceled') return Status.CANCELED;
    if (line_type === 'Disconnect') {
      if (sord?.Status__c === 'Complete') return Status.COMPLETE;
      else return Status.IN_PROGRESS;
    }
    if (line_type == 'Move/New') {
      if (sord?.Status__c == 'Complete') return Status.COMPLETE;
      else return Status.IN_PROGRESS;
    }

    if (type == Orders.OrderType.SDWAN || type == Orders.OrderType.VOICE) {
      if (sord?.Status__c == 'Complete') return Status.COMPLETE;
      else return Status.IN_PROGRESS;
    }

    // let's check the status of the circuit:
    let circuitStatus: Orders.Status = Status.IN_PROGRESS;
    if (tasks) {
      // check Submit Vendor Task:
      const taskSubmitVendor = tasks.filter((t) => t.Name == 'Submit Vendor Order');
      if (taskSubmitVendor.length > 0) {
        if (taskSubmitVendor[0].Status__c == 'Pending') circuitStatus = Status.PREPARING;
        if (taskSubmitVendor[0].Status__c == 'Complete') circuitStatus = Status.FOC_PENDING;
      }
    }

    if (sord?.Vendor_FOC_Date__c != null) circuitStatus = Status.FOC_ASSIGNED;

    // checking elements
    if (order.vendor_start_service != null) {
      circuitStatus = Status.VENDOR_COMPLETION;
    }

    if (sord?.Equipment_Shipment_Tracking_Number__c != null) {
      circuitStatus = Status.EQUIPMENT_SHIPPED;
    }

    if (tasks) {
      const taskScheduleActivation = tasks.filter((t) => t.Name == 'Schedule Activation');
      const taskActivateService = tasks.filter((t) => t.Name == 'Activate Service');

      if (taskActivateService.length > 0) {
        if (taskActivateService[0].Status__c == 'Complete') return Status.ACTIVATION_COMPLETE;
      }
      if (taskScheduleActivation.length > 0) {
        let scheduleStatus = taskScheduleActivation[0].Status__c;
        if (scheduleStatus == 'Ready' || scheduleStatus == 'In Progress') {
          return Status.ACTIVATION_PENDING;
        }
        if (scheduleStatus == 'Complete') {
          return Status.ACTIVATION_SCHEDULED;
        }
        }
      }

      return circuitStatus;
  }

  private calculatePendingReasons(record): Orders.Status[] {
    let tasks = record.Service_Order2__r?.tasks;
    if (!tasks) return [];
    tasks = tasks[0];

    let reasons: Orders.Status[] = [];
    const construction = tasks.filter(
      (t) =>
        t.Name?.toLowerCase().includes("special construction") &&
        t.Status__c == "In Progress"
    );
    if (construction.length > 0)
      reasons.push(Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION);
    const reqs = tasks.filter(
      (t) =>
        t.Name?.toLowerCase().includes("customer site requirements") &&
        t.Status__c == "In Progress"
    );
    if (reqs.length > 0) reasons.push(Orders.Status.FOC_PENDING_CUST_REQ);
    const vendor = tasks.filter(
      (t) =>
        t.Name?.toLowerCase().includes("vendor construction") &&
        t.Status__c == "In Progress"
    );
    if (vendor.length > 0)
      reasons.push(Orders.Status.FOC_PENDING_CARRIER_BUILD);

    if (reasons.length == 0)
      reasons.push(Orders.Status.FOC_PENDING_WAITING_VENDOR);

    return reasons;
  }

  private parseAddress(record): any {
    if (!record.Location__r)
      return { state: "-", city: "-", street: "-", zip: "-" };
    let addr = record.Location__r;
    return {
      site: addr.Name,
      state: addr.State__c,
      city: addr.City__c,
      street: addr.Street_1__c,
      zip: addr.Zip_Code__c,
    };
  }

  private parseContact(contact, position?): any {
    if (!contact || contact == undefined) {
      return null;
    }
    if (!position) position = contact.Title || "Another contact";
    return {
      position,
      name: contact.Name,
      email: contact.Email,
      phone1: contact.Phone,
    };
  }

  private getStandardInterval(transportType: string) {
    let interval = transport_types.filter(
      (t) => t.product == transportType.toLowerCase()
    )[0];
    if (!interval) return "-";
    return interval.interval;
  }

  private mapBasicService(order, record): Orders.Order {
    const serviceOrder = record.Service_Order2__r;
    order.ready_date = serviceOrder?.CRDD_c;

    const service = record.Service__r;
    if (!service) return order;
    order.transport_type = service.Transport_Type__r?.Name;
    order.customer_handoff = service.Connector_Type_Z_Location__c;
    if (order.transport_type) {
      order.standard_interval = this.getStandardInterval(order.transport_type);
    }
    return order;
  }

  private mapCircuitInfo(order, record): Orders.Order {
    this.mapBasicService(order, record);

    const service = record.Service__r;
    if (!service) return order;

    order.bandwith_down = service.Bandwidth_Downstream__c;
    order.bandwith_up = service.Bandwidth_Upstream__c;
    order.circuit_bandwith = order.bandwith_down + "/" + order.bandwith_up;
    order.booked_mrm = service.Booked_MRM__c;

    let elements = service.Elements__r;
    if (!elements) {
      return order;
    }

    const elementPort = elements.filter(
      (el) =>
        el.RecordType?.Name?.includes("Loop") ||
        el.RecordType?.Name?.includes("Port")
    )[0];
    const elementAccess = elements.filter(
      (el) =>
        el.RecordType?.Name?.includes("Loop") ||
        el.RecordType?.Name?.includes("Access")
    )[0];
    if (order.type == Orders.OrderType.ETHERNET) {
      order.vendor_name = elementAccess?.Vendor_Name__c;
      order.vendor_circuit_id = elementAccess?.Vendor_Circuit_Id__c;
    } else {
      order.vendor_name = elementPort?.Vendor_Name__c;
      order.vendor_circuit_id =
        elementPort?.Vendor_Circuit_Id__c ||
        elementAccess?.Vendor_Circuit_Id__c;
    }
    order.last_mile_circuit_id =
      elementAccess?.Last_Mile_Provider_Circuit_Id_Z_Location__c;
    order.circuit_demarc =
      elementPort?.Demarc_Z_Loc__c || elementAccess?.Demarc_Z_Loc__c;
    order.circuit_handoff = elementAccess?.Interface_Type_Z_Loc__c;
    order.enni =
      elementPort?.ENNI_UNI_Circuit_ID__c ||
      elementAccess?.ENNI_UNI_Circuit_ID__c;
    order.vendor_start_service = elementAccess?.Vendor_Start_Of_Service__c;

    if (order.type == Orders.OrderType.MPLS) {
      const privateElement = elements.filter(
        (el) => el.RecordType?.Name == "Private Line"
      )[0];
      order.vendor_name = privateElement?.Vendor_Name__c;
    }

    const elementWan = elements.filter((el) => {
      if (el.IP_Block_Address_Type__c != "WAN") return false;
      return (
        el.RecordType?.Name == "IPv4 Block" ||
        el.RecordType?.Name == "IPv6 Block"
      );
    })[0];
    if (elementWan) {
      order.wan_network_address = elementWan.Network_Address_Text__c;
      order.wan_network_mask =
        elementWan.IPv4_Network_Mask_Text__c ||
        elementWan.IPv6_Network_Mask_Text__c;
      order.wan_gateway_ip = elementWan.IP_Block_Carrier_Address_Text__c;
    }
    const elementLan = elements.filter((el) => {
      if (el.IP_Block_Address_Type__c != "LAN") return false;
      return (
        el.RecordType?.Name == "IPv4 Block" ||
        el.RecordType?.Name == "IPv6 Block"
      );
    })[0];
    if (elementLan) {
      order.lan_network_address = elementLan.Network_Address_Text__c;
      order.lan_network_mask =
        elementLan.IPv4_Network_Mask_Text__c ||
        elementLan.IPv6_Network_Mask_Text__c;
    }
    const equipment = elements.filter(
      (el) => el.RecordType?.Name == "Equipment"
    )[0];
    if (equipment) {
      order.equipment = equipment.Equipment_Supplemental_Product__r?.Name;
    }

    return order;
  }

  private mapP2P(order, record): Orders.OrderP2P {
    this.mapCircuitInfo(order, record);
    const service = record.Service__r;
    let element = null;
    if (!service) return order;

    let elements = service.Elements__r;
    if (elements && elements.length > 0) {
      element = elements[0];
    }
    if (element === null) {
      return order;
    }

    order.location_a_address = element.A_Location__r?.One_Line_Address__c;
    order.location_a_vendor_provider = element.Last_Mile_Provider_A_Loc__c;
    order.location_a_circuit_id = element.Vendor_Circuit_Id__c;
    order.location_a_foc_date = element.Vendor_FOC_Date__c;
    order.location_z_address = element.Service_Z_Location_Address__c;
    order.location_z_vendor_provider = element.Last_Mile_Provider_Z_Loc__c;
    order.location_z_circuit_id =
      element.Last_Mile_Provider_Circuit_Id_Z_Location__c;
    order.location_z_foc_date = element.Vendor_FOC_Date__c;
    order.location_a_last_mile_id =
      element.A_Location_Last_Mile_Provider_Circuit_Id__c;
    order.location_z_last_mile_id =
      element.Last_Mile_Provider_Circuit_Id_Z_Location__c;
    order.location_a_demarc = element.Demarc_A_Loc__c;
    order.location_z_demarc = element.Demarc_Z_Loc__c;

    return order;
  }

  private mapEquipmentSDWan(order, record): Orders.OrderSdWan {
    this.mapBasicService(order, record);
    let service = record.Service__r;
    let elements = service.Elements__r;

    if (!elements) {
      return order;
    }

    const equipmentRecord = elements.filter(
      (el) => el.RecordType?.Name === "Equipment"
    )[0];
    if (equipmentRecord) {
      order.equipment = equipmentRecord.Equipment_Supplemental_Product__r?.Name;
    }

    return order;
  }
  private mapType(record): Orders.Order {
    let type = record.Service_Type__c;
    if (types_dictionary.internet.includes(type)) {
      return this.mapCircuitInfo(new Orders.OrderInternet(), record);
    }
    if (types_dictionary.ethernet.includes(type)) {
      return this.mapCircuitInfo(new Orders.OrderEthernet(), record);
    }
    if (types_dictionary.mpls.includes(type)) {
      return this.mapCircuitInfo(new Orders.OrderMpls(), record);
    }
    if (types_dictionary.sdwan.includes(type)) {
      return this.mapEquipmentSDWan(new Orders.OrderSdWan(), record);
    }
    if (types_dictionary.voice.includes(type)) {
      return new Orders.OrderVoice();
    }
    if (types_dictionary.p2p.includes(type)) {
      return this.mapP2P(new Orders.OrderP2P(), record);
    }
    return null;
  }

  private mapOrder(record, isFromOrdersTable): Orders.Order {
    /*
		// debug tools:
		this.debug = false;
		if (record.Id == "a1N6S00000E32V3UAJ") {
			this.debug = true;
			console.info('record: ', record);
		}
*/

    let o: Orders.Order = this.mapType(record);
    if (!o) return null;

    const address = this.parseAddress(record);
    o.baseData({
      id: record.Id,
      nit: record.Service__r?.Name,
      site: address.site,
      state: address.state,
      city: address.city,
      address: address.street,
      zip: address.zip,
    });

    o.service_name = record.Service_Type__c;
    o.oli = record.Name;
    o.deal = record.deal;
    o.line_type = record.Line_Item_Type__c;
    o.product = record.Service_Type__c;
    o.local_contact = record.LocalContact;

    o.start_service = record.Service__r?.Start_Of_Service__c;
    o.end_service = record.Service__r?.End_Of_Service__c;
    o.order_date = record.order_date;
    o.activation_date = record.Service__r?.Activation_Date__c;

    o.contact_sales = record.contact_sales;
    o.contact_manager = record.contact_manager;
    if (record.other_contacts) {
      o.other_contacts = record.other_contacts;
    }

    if(isFromOrdersTable){
      o.milestone = this.calculateStatusOrderTable(record, o);
    }else{
      o.milestone = this.calculateStatus(record, o);
    }
    if (o.milestone == Orders.Status.FOC_PENDING) {
      o.foc_pending_reasons = this.calculatePendingReasons(record);
    }

    const serviceOrder = record.Service_Order2__r;
    if (serviceOrder != null) {
      // check if cancelled:
      o.foc_date = serviceOrder.Vendor_FOC_Date__c;
      o.tracking_number = serviceOrder.Equipment_Shipment_Tracking_Number__c;
      o.shipping_address = serviceOrder.Shipping_Address__c;
      if (serviceOrder.Status__c == "Canceled") {
        o.canceled = true;
        o.cancel_date = serviceOrder.Completed_Date__c;
      }
    }

    return o;
  }

  public getOrders(isFromOrdersTable: boolean): Observable<Orders.Order[] | null> {
    if (this.cachedOrders) {
      return new Observable((observer) => observer.next(this.cachedOrders));
    }

    this.store.dispatch(new fromStore.LoadOrdersV2());
    return this.store.select(fromStore.getActiveOrdersOrderTable).pipe(
      map((data: any) => {
        this.cachedOrders = this.validOrders(data).map((record) => {
          return this.mapOrder(record, isFromOrdersTable);
        }).filter((o) => o != null);
        // .filter((o) => o != null);
        return this.cachedOrders;
      })
    );
  }
}
