<section class="inventory-page parent-container">
  <app-service-top-header
    [activeServices]="activeServices$ | async"
    [services]="services$ | async"
  ></app-service-top-header>

  <div class="inventory-view">
    <app-inventory-list
      #inventoryList
      [services]="services$ | async"
      [isLoading]="isLoading$ | async"
      [isLoaded]="isLoaded$ | async"
      [selectedService]="selectedService$ | async"
      [activeServices]="activeServices$ | async"
      [groupedServices]="groupFilteredServices$ | async"
      [browserSize]="browserSize$ | async"
      [isServicesInProgress]="isServicesInProgress$ | async"
      [locationsFilterOptions]="locationsFilterOptions$ | async"
      [serviceFilterOptions]="serviceFilterOptions$ | async"
      [statusFilterOptions]="statusFilterOptions$ | async"
      [totalSelectedFilters]="totalSelectedFilters$ | async"
      (onSetSelectedService)="onSetSelectedService($event)"
      (onLoadingIndicator)="onLoadingIndicator()"
      [locationsFilterFormControl]="locationsFilterFormControl"
      [serviceFilterFormControl]="serviceFilterFormControl"
      [statusFilterFormControl]="statusFilterFormControl"
      [groupByFormControl]="groupByFormControl"
      [isAscFormControl]="isAscFormControl"
    ></app-inventory-list>

    <ng-container *ngIf="browserSize$ | async as browserSize">
      <app-inventory-detail
        *ngIf="browserSize !== 'small'"
        [browserSize]="browserSize$ | async"
        [services]="services$ | async"
        [selectedService]="selectedService$ | async"
        [activeServices]="activeServices$ | async"
      ></app-inventory-detail>
    </ng-container>
  </div>
</section>
