import { formatDate as formatDatePipe } from '@angular/common';

import OrderInfoDetail from '../order-info.interface';
import OrderContactInfo from '../order-contacts-box/order-contact-info.interface';
import OrderRow from '../table/order-row.interface';

export class OrderCsvDataRow {
  'Deal': string;
  'OLI': string;
  'Line Type': string;
  'NIT': string;
  'Site': string;
  'Address': string;
  'City': string;
  'State': string;
  'Service': string;
  'Status': string;
  'FOC Date': string;

  constructor(row: OrderRow) {
    this.Deal = row.deal;
    this.OLI = row.oli;
    this['Line Type'] = row.line_type;
    this.NIT = row.nit;
    this.Site = row.site;
    this.Address = row.address;
    this.City = row.city;
    this.State = row.state;
    this.Service = row.service;
    this.Status = row.status;
    this['FOC Date'] = row.foc_date;
  }
}

export enum OrderType {
  INTERNET = 1,
  SDWAN = 2,
  MPLS = 3,
  VOICE = 4,
  ETHERNET = 5,
  P2P = 6
}

export enum Status {
  PREPARING,
  FOC_PENDING,
  FOC_PENDING_WAITING_VENDOR,
  FOC_PENDING_SPECIAL_CONSTRUCTION,
  FOC_PENDING_CARRIER_BUILD,
  FOC_PENDING_CUST_REQ,
  FOC_ASSIGNED,
  VENDOR_COMPLETION,
  EQUIPMENT_SHIPPED,
  ACTIVATION_PENDING,
  ACTIVATION_SCHEDULED,
  ACTIVATION_COMPLETE,

  IN_PROGRESS,
  COMPLETE,
  PENDING,

  CANCELED,
  DISCONNECTED,
  DISCONNECTED_IN_PROGRESS,
  MOVE_REQUESTED,
  MOVE_REQUESTED_IN_PROGRESS
}

export function getEnumOrderTypeName(t: OrderType): string {
  switch (t) {
    case OrderType.INTERNET:
      return 'Internet';
    case OrderType.SDWAN:
      return 'SD-WAN';
    case OrderType.MPLS:
      return 'MPLS';
    case OrderType.VOICE:
      return 'Voice';
    case OrderType.ETHERNET:
      return 'Layer 2 ETHERNET';
    case OrderType.P2P:
      return 'Point-to-Point';
    default:
      return '-';
  }
}

export function getEnumStatusName(s: Status, short?: boolean): string {
  switch (s) {
    case Status.PREPARING:
      return 'Preparing Order';
    case Status.FOC_PENDING:
      return 'Pending FOC Date';
    case Status.FOC_PENDING_WAITING_VENDOR:
      return short ? 'Pending FOC Date' : 'Waiting on Vendor';
    case Status.FOC_PENDING_SPECIAL_CONSTRUCTION:
      return short ? 'Pending FOC Date' : 'Special Construction';
    case Status.FOC_PENDING_CARRIER_BUILD:
      return short ? 'Pending FOC Date' : 'Carrier Build';
    case Status.FOC_PENDING_CUST_REQ:
      return short ? 'Pending FOC Date' : 'Customer Requirements';
    case Status.FOC_ASSIGNED:
      return 'FOC Date Assigned';
    case Status.VENDOR_COMPLETION:
      return 'Vendor Completion';
    case Status.EQUIPMENT_SHIPPED:
      return 'Equipment Shipped';
    case Status.ACTIVATION_PENDING:
      return 'Pending Activation';
    case Status.ACTIVATION_SCHEDULED:
      return 'Scheduled Activation';

    case Status.IN_PROGRESS:
      return 'In Progress';
    case Status.ACTIVATION_COMPLETE:
    case Status.COMPLETE:
      return 'Complete';

    case Status.CANCELED:
      return 'Canceled';
    case Status.DISCONNECTED:
      return 'Disconnected';
    case Status.PENDING:
      return 'Pending';
    case Status.MOVE_REQUESTED:
      return 'Move Requested';
    case Status.DISCONNECTED_IN_PROGRESS:
      return short ? 'In Progress' : 'Disconnected In Progress';
    case Status.MOVE_REQUESTED_IN_PROGRESS:
      return short ? 'In Progress' : 'Move Requested In Progress';
    default:
      return '-';
  }
}

export class Order {
  public id: string;
  public service_name: string;
  public type: OrderType;
  public milestone: Status;
  public nit: string;
  public product: string;

  public deal: string;
  public oli: string;
  public line_type: string;

  public canceled: boolean = false;
  public cancel_date: string;

  public foc_pending_reasons: Status[] = [];

  // General Info:
  public site: string;
  public city: string;
  public zip: string;
  public state: string;
  public address: string;
  public local_contact: string;

  public ready_date: string;
  public start_service: string;
  public vendor_start_service: string;
  public end_service: string;
  public order_date: string;
  public foc_date: string;
  public billing_date: string;
  public activation_date: string;

  public bandwith_down: string;
  public bandwith_up: string;
  public booked_mrm: string;

  // basic service
  public transport_type: string;
  public circuit_handoff: string;
  public customer_handoff: string;
  public circuit_bandwith: string;
  public equipment: string;

  // circuit info
  public vendor_name: string;
  public vendor_circuit_id: string;
  public last_mile_circuit_id: string;
  public circuit_demarc: string;
  public wan_network_address: string;
  public wan_network_mask: string;
  public wan_gateway_ip: string;
  public lan_network_address: string;
  public lan_network_mask: string;
  public shipping_address: string;
  public tracking_number: string;
  public standard_interval: string;
  public enni: string;

  // p2p
  public location_a_address: string;
  public location_a_vendor_provider: string;
  public location_a_circuit_id: string;
  public location_a_foc_date: string;
  public location_z_address: string;
  public location_z_vendor_provider: string;
  public location_z_circuit_id: string;
  public location_z_foc_date: string;
  public location_a_last_mile_id: string;
  public location_z_last_mile_id: string;
  public location_a_demarc: string;
  public location_z_demarc: string;

  // contacts:
  public contact_sales: OrderContactInfo = null;
  public contact_manager: OrderContactInfo = null;
  public other_contacts: OrderContactInfo[] = null;

  public formatDate(date: any): string {
    if (date == null || date == undefined) return '-';
    return formatDatePipe(date, 'MM/dd/yyyy', 'en-US');
  }

  public baseData(data): Order {
    this.id = data.id;
    this.nit = data.nit;
    this.site = data.site;
    this.city = data.city;
    this.zip = data.zip;
    this.state = data.state;
    this.address = data.address;
    return this;
  }

  public getService(): string {
    //		let type: string = getEnumOrderTypeName(this.type);
    //		if(this.canceled) return "Canceled: " + type;
    return this.service_name;
  }
  public getStatus(short?: boolean): string {
    if (this.milestone == Status.FOC_PENDING) {
      if (this.foc_pending_reasons.length == 0) {
        return 'Waiting for vendor';
      } else {
        let statusStr: string[] = this.foc_pending_reasons.map((st) => getEnumStatusName(st));
        let pendingStatus = statusStr.join(', ');
        //				return short ? pendingStatus : "Pending FOC Date: " + pendingStatus;
        return pendingStatus;
      }
    }
    return getEnumStatusName(this.milestone, short);
  }

  public getRowInfo(): OrderInfoDetail[] {
    return [];
  }

  // INFO DETAILS:
  public getGeneralInformation(): OrderInfoDetail[] {
    let data: OrderInfoDetail[] = [
      {
        title: 'Location Address',
        value: this.address + ',  ' + this.city + ',  ' + this.state + ',  ' + this.zip
      },
      {
        title: 'Site Name',
        value: this.site
      },
      {
        title: 'Local contact',
        value: this.local_contact
      },
      {
        title: 'Nitel Circuit ID',
        value: this.nit
      },
      {
        title: 'Product',
        value: this.product
      },
      {
        title: 'Deal #',
        value: this.deal
      },
      {
        title: 'Status',
        value: this.getStatus()
      }
    ];
    if (this.canceled) {
      data.push({ title: 'Canceled Date', value: this.cancel_date, type: 'date' });
    }
    return data;
  }

  public getBasicServiceInfo(): OrderInfoDetail[] {
    if (this.type == OrderType.SDWAN) return [];
    if (this.type == OrderType.VOICE) return [];
    return [
      {
        title: 'Requested service ready date',
        value: this.ready_date
      },
      {
        title: 'Transport Type',
        value: this.transport_type
      },
      {
        title: 'Circuit Hand-Off (Vendor)',
        value: this.circuit_handoff
      },
      {
        title: 'Customer Hand-Off',
        value: this.customer_handoff
      },
      {
        title: 'Circuit Bandwidth',
        value: this.circuit_bandwith
      },
      {
        title: 'Bandwidth Down',
        value: this.bandwith_down
      },
      {
        title: 'Bandwidth Up',
        value: this.bandwith_up
      },
      {
        title: 'Equipment',
        value: this.equipment
      }
    ];
  }

  public getCircuitOrderInfo(): OrderInfoDetail[] {
    switch (this.type) {
      case OrderType.ETHERNET:
        let info = this.getBasicServiceInfoCircuit();
        info.push({
          title: 'Built-to ENNI',
          value: this.enni
        });
        return info;
      case OrderType.INTERNET:
      case OrderType.MPLS:
        return this.getInternetServiceInfoCircuit();
      case OrderType.SDWAN:
        return this.getSDWANServiceInfoCircuit();
      case OrderType.VOICE:
        return [];
      case OrderType.P2P:
        return this.getP2PServiceInfoCircuit();
      default:
        return this.getBasicServiceInfoCircuit();
        // return [];
        break;
    }
  }

  // for Internet, MPLS, Layer 2 Ethernet, Point-to-Point
  public getBasicServiceInfoCircuit(): OrderInfoDetail[] {
    return [
      {
        title: 'Vendor Delivering Circuit',
        value: this.vendor_name
      },
      {
        title: 'Vendor Firm Order Confirmation date',
        value: this.foc_date ? this.formatDate(this.foc_date) : '-'
      },
      {
        title: 'Vendor Circuit ID',
        value: this.vendor_circuit_id
      },
      {
        title: 'Last Mile Provider Circuit ID',
        value: this.last_mile_circuit_id
      },
      {
        title: 'Circuit Demarc',
        value: this.circuit_demarc
      },
      {
        title: 'Equipment',
        value: this.equipment
      },
      {
        title: 'Shipping Address',
        value: this.shipping_address
      },
      {
        title: 'Tracking Number',
        value: this.tracking_number
      },
      {
        title: 'Standard Interval',
        value: this.standard_interval
      }
    ];
  }

  // for Internet, MPLS
  public getInternetServiceInfoCircuit(): OrderInfoDetail[] {
    return [
      {
        title: 'Vendor Delivering Circuit',
        value: this.vendor_name
      },
      {
        title: 'Vendor Firm Order Confirmation date',
        value: this.foc_date
      },
      {
        title: 'Vendor Circuit ID',
        value: this.vendor_circuit_id
      },
      {
        title: 'Last Mile Provider Circuit ID',
        value: this.last_mile_circuit_id
      },
      {
        title: 'Circuit Demarc',
        value: this.circuit_demarc
      },
      {
        title: 'WAN Network Address',
        value: this.wan_network_address
      },
      {
        title: 'WAN Network Mask',
        value: this.wan_network_mask
      },
      {
        title: 'WAN Gateway IP',
        value: this.wan_gateway_ip
      },
      {
        title: 'LAN Network Address',
        value: this.lan_network_address
      },
      {
        title: 'LAN Network Mask',
        value: this.lan_network_mask
      },
      {
        title: 'Equipment',
        value: this.equipment
      },
      {
        title: 'Shipping Address',
        value: this.shipping_address
      },
      {
        title: 'Tracking Number',
        value: this.tracking_number
      },
      {
        title: 'Standard Interval',
        value: this.standard_interval
      }
    ];
  }

  public getSDWANServiceInfoCircuit(): OrderInfoDetail[] {
    return [
      {
        title: 'Equipment',
        value: this.equipment
      },
      {
        title: 'Shipping Address',
        value: this.shipping_address
      },
      {
        title: 'Tracking Number',
        value: this.tracking_number
      }
    ];
  }

  public getP2PServiceInfoCircuit(): OrderInfoDetail[] {
    return [
      {
        title: 'Vendor Firm Order Confirmation date',
        value: this.foc_date ? this.formatDate(this.foc_date) : '-'
      },
      {
        title: 'Location A - Address',
        value: this.location_a_address
      },
      {
        title: 'Location A - Vendor/Access Provider',
        value: this.location_a_vendor_provider
      },
      {
        title: 'Location A - Vendor Circuit ID',
        value: this.location_a_circuit_id
      },
      {
        title: 'Location Z - Address',
        value: this.location_z_address
      },
      {
        title: 'Location Z - Vendor/Access Provider',
        value: this.location_z_vendor_provider
      },
      {
        title: 'Location Z - Vendor Circuit ID',
        value: this.location_z_circuit_id
      },
      {
        title: 'Location A - Last Mile Provider Circuit ID',
        value: this.location_a_last_mile_id
      },
      {
        title: 'Location Z - Last Mile Provider Circuit ID',
        value: this.location_z_last_mile_id
      },
      {
        title: 'Location A - Circut Demarc',
        value: this.location_a_demarc
      },
      {
        title: 'Location Z - Circut Demarc',
        value: this.location_z_demarc
      },
      {
        title: 'Equipment',
        value: this.equipment
      },
      {
        title: 'Shipping Address',
        value: this.shipping_address
      },
      {
        title: 'Tracking Number',
        value: this.tracking_number
      },
      {
        title: 'Standard Interval',
        value: this.standard_interval
      }
    ];
  }

  public getDates(): OrderInfoDetail[] {
    return [
      {
        title: 'Activation',
        value: this.activation_date
      },
      {
        title: 'Firm Order Confirmation',
        value: this.foc_date
      },
      {
        title: 'Order Date',
        value: this.order_date
      },
      {
        title: 'Start of Service',
        value: this.start_service
      }
    ];
  }

  public getContactsInfo(): OrderContactInfo[] {
    let contacts = [];
    if (this.other_contacts) {
      contacts = this.other_contacts;
    }
    if (this.contact_sales) {
      contacts.push(this.contact_sales);
    }
    if (this.contact_manager) {
      contacts.push(this.contact_manager);
    }

    contacts.push({
      position: 'Local Contact',
      name: this.local_contact || '--'
    });

    contacts.push({
      position: 'NOC Info',
      email: 'noc@nitelusa.com',
      phone2: '866.892.0915'
    });

    return contacts;
  }
}

/**
 * Gets the order row data and converts it to an array of objects ready for the CSV export
 * @param data the Order rows we are exporting to a CSV
 * @returns Array of data in a format ready to be exported to a CSV
 */
export function getOrderCSVData(data: OrderRow[]): OrderCsvDataRow[] {
  return data.map((row) => new OrderCsvDataRow(row));
}
