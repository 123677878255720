<div class="file-upload">
  <button
    mat-mini-fab
    color="primary"
    class="upload-btn"
    (click)="fileUploadClick()"
    [disabled]="ticket.IsClosed || allowUserInput"
  >
    <mat-icon class="attach-icon">attach_file</mat-icon>
  </button>
  <span class="file-label">
    {{ fileName || 'Select a file to upload' }}
  </span>
  <input
    type="file"
    #fileUploads
    id="fileUploads"
    name="fileUploads"
    class="file-input"
    accept="image/bmp, image/gif, image/jpeg, image/png, application/pdf"
  />

  <div class="messages">
    <div class="server-error" *ngIf="hasError$ | async">
      <div class="custom-icon inline_error"></div>
      <div class="error-message">{{ fileUploadError$ | async }}</div>
    </div>

    <div class="loading-indicator" *ngIf="isLoading$ | async">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    
  </div>
</div>
