<div class="order-detail">
	<mat-grid-list
		class="top-grid"
		rowHeight="50px"
		[cols]="breakpoint"
		(window:resize)="onResize($event)"
	>
		<mat-grid-tile [colspan]="(breakpoint - 1)" >
			<h3>{{ title }}</h3>
		</mat-grid-tile>
		<mat-grid-tile>
			<button (click)="viewMore();" class="button-no-outline">
				<span>View More</span>
				<mat-icon aria-label="View more">arrow_right_alt</mat-icon>
			</button>
		</mat-grid-tile>
	</mat-grid-list>
	<mat-grid-list
		class="data-grid"
		rowHeight="60px"
		[cols]="breakpoint"
	>
		<mat-grid-tile 
			*ngFor="let i of info;"
			class="tile-info"
		>
			<span class="info-title">
				{{ i.title }}
			</span>
			<span class="info-value">{{ i.value || '-' }}</span>
		</mat-grid-tile>

		<mat-grid-tile
			*ngIf="cancelColspan > 0"
			class="tile-info"
			[colspan]="cancelColspan"
		></mat-grid-tile>
		<mat-grid-tile
			*ngIf="order.canceled"
			class="tile-info">
			<span class="info-title">
				Cancelation Date:
			</span>
			<span class="info-value">{{ order.cancel_date | date: 'yyyy-MM-dd' }}</span>
		</mat-grid-tile>
	</mat-grid-list>

</div>
