import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import Holidays from 'date-holidays';
import { Service } from '../../../models/service.model';
import { Contact } from '../../../models/contact.model';
import { CalendarHeaderComponent } from '../../../shared/components/calendar-header/calendar-header.component';
// import { phoneNumberValidator } from 'src/app/shared/validators/phoneNumberValidator';

@Component({
  selector: 'app-ticket-disconnect',
  templateUrl: './ticket-disconnect.component.html',
  styleUrls: ['../../containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketDisconnectComponent implements OnInit {
  public disconnectTxGroup: UntypedFormGroup = this.fb.group({
    reason: this.fb.control('', Validators.required),
    otherReasonDetails: this.fb.control(''),
    comments: this.fb.control(''),
    reporter: this.fb.control('', Validators.required),
    preferredContactMethod: this.fb.control('phone', Validators.required),
    countryCode: this.fb.control(''),

    services: this.fb.array([
      this.fb.group({
        identifyIssueBy: this.fb.control('location'),
        serviceId: this.fb.control(null, Validators.required),
        locationId: this.fb.control(null, Validators.required),
        locationAddress: this.fb.control(null),
        desiredDate: this.fb.control(null, [Validators.required, this.validateDisconnectDate.bind(this)]),
        servicesByLocation: this.fb.array([
          this.fb.group({
            id: this.fb.control(''),
            disabled: this.fb.control(''),
            nit: this.fb.control(''),
            product: this.fb.control(''),
            locationId: this.fb.control(''),
            locationName: this.fb.control('')
          })
        ])
      })
    ])
  });

  @Input() services: Service[];
  @Input() selectedServiceId: string;
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Input() submissionError: boolean;
  @Input() isSubmitting: boolean;
  @Input() primaryReporters: Contact[];
  @Input() errorMessage: string;
  @Input() disconnectReasons: string[];
  @Output() submitForm = new EventEmitter();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

  formSubmitAttempted = false;
  inlineErrors: any = {};

  uniqueLocations: any[] = [];
  allServices: any[] = [];
  isLoadingServices = true;
  loadingUsers = true;

  customCalHeader = CalendarHeaderComponent;
  startCalendarAt = new Date();
  holidays = [`New Year's Day`, `Memorial Day`, `Independence Day`, `Labor Day`, `Thanksgiving Day`, `Christmas Day`];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.services) {
      this.isLoadingServices = false;
      this.allServices = this.services;

      const serviceObject = {};
      const m = new Map();

      // eslint-disable-next-line no-restricted-syntax
      for (const service of this.services) {
        serviceObject[service.Id] = service;
        // If service has location, create array.
        if (service.Z_Location__r && !m.has(service.Z_Location__c)) {
          m.set(service.Z_Location__c, true);
          this.uniqueLocations.push(service);
        }
      }
    }

    this.startCalendarAt = this.getNextValidDate();
    this.matIconRegistry.addSvgIcon(
      `nitelCalendar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/icons/calendar.svg`)
    );

    this.canSubmit();
  }

  // initialize the calendar to start at the first valid date
  getNextValidDate(): Date {
    const dateToCheck = moment(new Date()).add(30, 'days');
    while (!this.filterDates(dateToCheck.toDate())) {
      dateToCheck.add(1, 'days');
    }
    return dateToCheck.toDate();
  }

  filterDates = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    const weekday = day !== 0 && day !== 6;
    // A user cannot disconnect on holidays
    const holiday = this.isHoliday(d);
    // A user cannot disconnect until 30 days from current date.
    const thirtyDays = moment(new Date()).add(30, 'days');
    const afterThirtyDays = d > thirtyDays.toDate();

    return weekday && !holiday && afterThirtyDays;
  };

  isHoliday(calendarDate: Date): boolean {
    const allHolidays = new Holidays('US');
    const holiday = allHolidays.isHoliday(calendarDate);
    return holiday && this.holidays.indexOf(holiday[0].name) >= 0;
  }

  canSubmit(): boolean {
    const defaultMessage = 'This is a required field';

    // eslint-disable-next-line no-restricted-syntax
    for (const prop in this.disconnectTxGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.disconnectTxGroup.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.disconnectTxGroup.controls[prop] as UntypedFormControl;
        // this.inlineErrors[prop] = control.valid ? null : defaultMessage;
        if (!control.valid) {
          this.inlineErrors[prop] = defaultMessage;
        } else {
          delete this.inlineErrors[prop];
        }
        control.updateValueAndValidity();
        if (control.errors && control.errors.pattern) {
          this.inlineErrors[prop] = 'pattern';
        } else if (control.errors && prop === 'desiredDate') {
          this.inlineErrors[prop] = control.errors.required ? defaultMessage : 'date';
        }
      }
    }
    return this.disconnectTxGroup.valid;
  }

  submitTicket(): void {
    this.canSubmit();
    if (this.canSubmit()) {
      // submit the ticket
      const request = {
        order_type: 'Disconnect_Order',
        disconnectReason: this.disconnectTxGroup.value.reason,
        disconnectReasonDetails: this.disconnectTxGroup.value.otherReasonDetails,
        contactId: this.disconnectTxGroup.value.reporter,
        additionalComment: this.disconnectTxGroup.value.comments,
        preferredContactMethod: this.disconnectTxGroup.value.preferredContactMethod, // eslint-disable-line
        lineItems: this.disconnectTxGroup.value.services
      };
      this.submitForm.emit(request);
    } else {
      this.formSubmitAttempted = true;
    }
  }

  validateDisconnectDate(c: UntypedFormControl): {
    validateDate: {
      valid: boolean;
    };
  } {
    const formDate = c.value ? new Date(c.value) : null;
    const isValid = formDate ? this.filterDates(formDate) : false;
    const errorOutput = {
      validateDate: {
        valid: false
      }
    };
    return isValid ? null : errorOutput;
  }

  usersLoaded(): void {
    this.loadingUsers = false;
  }

  servicesLoaded(): void {
    this.isLoadingServices = false;
  }
}
