import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-analytics-widget',
  templateUrl: './analytics-widget.component.html',
  styleUrls: ['./analytics-widget.component.scss'],
})
export class AnalyticsWidgetComponent {
  @Input() isNoContent: boolean = false;
  @Input() loading: boolean = false;
}