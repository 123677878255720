import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss', './reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  username = '';
  urlToken = '';
  passwordsMatch = false;
  pwMatchErrorMessage = '';
  pwMatchSuccessMessage = '';
  criteriaMet = false;
  criteria = ['lower', 'upper', 'special', 'minimum'];
  resetPasswordFormGroup: UntypedFormGroup;
  displayForm = true;
  isLoading = false;
  errorMessage: string;

  values = {
    lower: {
      label: 'One lowercase character',
      valid: false
    },
    upper: {
      label: 'One uppercase character',
      valid: false
    },
    special: {
      label: 'One special character',
      valid: false
    },
    minimum: {
      label: '8 character minimum',
      valid: false
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.resetPasswordFormGroup = new UntypedFormGroup({
      pw1: new UntypedFormControl(),
      pw2: new UntypedFormControl()
    });
  }

  ngOnInit() {
    const jwt = new JwtHelperService();
    this.urlToken = this.route.snapshot.paramMap.get('token');

    try {
      const decoded = jwt.decodeToken(this.urlToken);

      if (Date.now() >= decoded.exp * 1000) {
        this.displayForm = false;
      } else {
        this.username = decoded.uid;

        this.authService.validateToken(this.urlToken).subscribe((isValid) => {
          if (!isValid) {
            this.displayForm = false;
            this.changeDetectorRef.detectChanges();
          }
        });

        if (!this.username) {
          throw new Error('invalid username');
        }
      }
    } catch (error) {
      this.displayForm = false;
    }
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#password').focus();
  }

  verifyPasswords() {
    if (!this.resetPasswordFormGroup.controls.pw2.value) {
      this.pwMatchErrorMessage = 'You must reenter your password.';
      this.pwMatchSuccessMessage = '';
      return;
    }
    if (!this.passwordsMatch) {
      this.pwMatchErrorMessage = 'Passwords do not match. Make sure Caps Lock is off.';
      this.pwMatchSuccessMessage = '';
      return;
    }
    this.isLoading = true;
    this.authService
      .resetPassword(
        this.urlToken,
        this.resetPasswordFormGroup.controls.pw1.value,
        this.resetPasswordFormGroup.controls.pw2.value
      )
      .subscribe(
        (attempt) => {
          this.isLoading = false;
          if (attempt === true) {
            // When password changed, redirect to login page
            this.router.navigate(['login'], { queryParams: { passwordChanged: true } });
          } else {
            this.displayForm = false;
          }
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          this.isLoading = false;
          // this.displayForm = false;
          this.errorMessage = err.statusText;
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  validatePassword(event /* : Event */) {
    const pw = event.target.value;

    this.values.lower.valid = !!pw.match(/([a-z])/g);
    this.values.upper.valid = !!pw.match(/([A-Z])/g);
    this.values.special.valid = !!pw.match(/\W|_/g);
    this.values.minimum.valid = !!pw.match(/.{8,}/g);
    this.criteriaMet =
      this.values.lower.valid && this.values.upper.valid && this.values.special.valid && this.values.minimum.valid;
    this.doPasswordsMatch();
  }

  doPasswordsMatch() {
    this.passwordsMatch =
      this.resetPasswordFormGroup.controls.pw1.value === this.resetPasswordFormGroup.controls.pw2.value;
    if (this.passwordsMatch && this.criteriaMet) {
      this.pwMatchSuccessMessage = "Passwords match. You're all set!";
      this.pwMatchErrorMessage = '';
    } else {
      this.pwMatchSuccessMessage = '';
      this.pwMatchErrorMessage = '';
    }
  }
}
