import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { api } from '../models/api-url.model';
import { BanLookup } from '../models/ban-lookup.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BanLookupService {
  constructor(private apiService: ApiService) {}

  updateBanLookup(banLookup: BanLookup): Observable<any> {
    return this.apiService.put(`${api.banLookupsURL}/${banLookup.Id}`, banLookup);
  }
}
