import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AttachmentService } from 'src/app/services/attachment.service';
import * as invoiceAttachmentsActions from '../actions/invoice-attachments.action';

@Injectable()
export class InvoiceAttachmentEffects {
  constructor(private actions$: Actions, private attachmentService: AttachmentService) {}

  loadInvoiceAttachments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invoiceAttachmentsActions.LOAD_INVOICE_ATTACHMENTS),
      switchMap(() => {
        return this.attachmentService.getInvoices().pipe(
          map((files) => new invoiceAttachmentsActions.LoadInvoiceAttachmentsSuccess(files)),
          catchError((error) => of(new invoiceAttachmentsActions.LoadInvoiceAttachmentsFail(error)))
        );
      })
    );
  });
}
