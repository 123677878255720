<section class="dialog-content-container">
  <div class="custom-icon close" (click)="closeDialog()"></div>
  <section class="form-type-container">
    <h1>Contact {{ title }}</h1>

    <div class="contact-details">
      <div class="contact-wrapper">
        <div class="custom-icon profile" *ngIf="contact && browserSize !== 'small'"></div>
        <div class="details-wrapper" *ngIf="contact">
          <div class="contact-name" *ngIf="contact.Name">{{ contact.Name }}</div>
          <div class="contact-title" *ngIf="contact.Title">{{ contact.Title }}</div>
          <div class="contact-email" *ngIf="contact.Email">
            <div class="custom-icon email_dark">email icon</div>
            <a href="mailto:{{contact.Email}}">{{ contact.Email }}</a></div>
          <div class="contact-phone" *ngIf="contact.Phone">
            <div class="custom-icon phone_dark">phone icon</div>
            <a href="tel:{{contact.Phone}}">{{ contact.Phone }}</a></div>
        </div>
        <div class="details-wrapper" *ngIf="errorMessage">Contact information is not available</div>
      </div>
    </div>
  </section>
</section>
