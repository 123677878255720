import { Injectable } from '@angular/core';
import { Element } from '../models/element.model';
import { Service } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class CSVFormatterService {
  getElementInfo = (service: Service) => {
    const elementInfo = {
      vendorCircuitId: '',
      dmarcZLoc: '',
      equipmentName: '',
      wanIpBlock: '',
      wanMask: '',
      lanIpBlock: '',
      lanMask: '',
      serviceContact: ''
    };

    const vendorRecordTypes = new Set([
      'Internet Loop',
      'MPLS Loop',
      'Ethernet Access',
      'Private Line',
      'Wireless Internet',
      'Dark Fiber'
    ]);

    if (service.Elements__r) {
      const circuitIdSet = new Set();
      const demarcSet = new Set();
      const vendorSet = new Set();

      service.Elements__r.forEach((element) => {
        if (element !== null) {
          if (element.Vendor_Circuit_Id__c && element.Vendor_Circuit_Id__c.length > 0) {
            circuitIdSet.add(element.Vendor_Circuit_Id__c);
          }

          if (element.Demarc_Z_Loc__c && element.Demarc_Z_Loc__c.length > 0) {
            if (element.Demarc_Z_Loc__c !== 'Nitel') {
              demarcSet.add(element.Demarc_Z_Loc__c);
            }
          }

          if (element.Vendor_Name__c && element.Vendor_Name__c.length > 0) {
            if (element.Vendor_Name__c !== 'Nitel' && vendorRecordTypes.has(element.RecordType.Name)) {
              vendorSet.add(element.Vendor_Name__c);
            }
          }

          if (
            element.RecordType &&
            element.RecordType.Name === 'Equipment' &&
            element.Equipment_Supplemental_Product__r
          ) {
            elementInfo.equipmentName = element.Equipment_Supplemental_Product__r.Name;
          } else if (element.RecordType && element.RecordType.Name === 'IPv4 Block') {
            this.getIpBlockInfo(element, elementInfo);
          }
        }

        elementInfo.vendorCircuitId = Array.from(circuitIdSet).join(',');
        elementInfo.dmarcZLoc = Array.from(demarcSet).join(',');
      });
    }
    return elementInfo;
  };

  getIpBlockInfo = (
    element: Element,
    elementInfo: {
      vendorCircuitId: string;
      dmarcZLoc: string;
      equipmentName: string;
      wanIpBlock: string;
      wanMask: string;
      lanIpBlock: string;
      lanMask: string;
      serviceContact: string;
    }
  ) => {
    const ipBlockText = this.getIpBlockText(element);
    const ipv4Mask = element.IPv4_Network_Mask_Text__c;
    if (element.IP_Block_Address_Type__c === 'LAN') {
      elementInfo.lanIpBlock = elementInfo.lanIpBlock ? `${elementInfo.lanIpBlock}, ${ipBlockText}` : ipBlockText;
      elementInfo.lanMask = elementInfo.lanMask
        ? `${elementInfo.lanMask}, ${element.IPv4_Network_Mask_Text__c}`
        : element.IPv4_Network_Mask_Text__c;
    } else if (element.IP_Block_Address_Type__c === 'WAN') {
      elementInfo.wanIpBlock = elementInfo.wanIpBlock ? `${elementInfo.wanIpBlock}, ${ipBlockText}` : ipBlockText;
      elementInfo.wanMask = elementInfo.wanMask ? `${elementInfo.wanMask}, ${ipv4Mask}` : ipv4Mask;
    }
  };

  getIpBlockText = (element: Element) => {
    let ipBlock = '';
    if (element.Network_Address_Text__c && element.Network_Address_Text__c.length > 0) {
      ipBlock = element.Network_Address_Text__c;
      if (element.Prefix_Bits_Formula__c && element.Prefix_Bits_Formula__c.length > 0) {
        ipBlock += `/${element.Prefix_Bits_Formula__c}`;
      }
    }

    return ipBlock;
  };
}
